import merge from 'deepmerge';

const combineMerge = (target: any[], source: any[], options: merge.ArrayMergeOptions) => {
    const destination = target.slice();

    source.forEach((item, index) => {
        const destIndex = destination.findIndex((destItem) => {
            if (destItem.FieldName) return destItem.FieldName === item.FieldName;
            if (destItem.Code) return destItem.Code === item.Code;
            return destItem.Id === item.Id;
        });
        if (destIndex !== -1) {
            // if (!wasChildIndexTuning && item.ChildIndex !== undefined) {
            //     wasChildIndexTuning = true;
            // }
            destination[destIndex] = merge(destination[destIndex], item, options);
        } else {
            destination.push(item);
        }
    });

    if (destination.length === 0 && source.length !== 0) return source;

    return destination;
};

export const makeDeepMerge = <T = unknown>(target: T, source: T) => {
    return merge<T>(target, source, {
        arrayMerge: combineMerge
    });
};
