import dayjs from 'dayjs';
import { IObjectWithId } from 'utils/store/MetaStore';
import { v4 } from 'uuid';

import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { REGEX_REPLACE_GMT } from 'utils/helpers/dates';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IMetaWithChildren extends IObjectWithId {
    children?: IObjectWithId[];
}

type NodesByIdType = IObjectWithId & { children: NodesByIdType[]; isHardGroup?: boolean };

// Удаляем пустые массивы children
export function cleanEmptyChildren(items: IMetaWithChildren[]) {
    const cleanedItems = [...items];

    cleanedItems.forEach((item) => {
        if (item.children && item.children.length === 0) {
            delete item.children;
        } else if (item.children) {
            cleanEmptyChildren(item.children);
        }
    });

    return cleanedItems;
}

// Вспомогательная функция для группировки по одному ключу
function groupBy(
    items: NodesByIdType[],
    key: string,
    nextKey: string,
    prevKey: string,
    viewFieldName: string
) {
    const groups: { [groupKey: string]: NodesByIdType } = {};

    const isDateGroupField = key.endsWith('At');

    items.forEach((item) => {
        const groupValue = item[key];
        // const groupKey = JSON.stringify(groupValue);
        // const groupKey = groupValue?.Id ?? JSON.stringify(groupValue);
        const groupKey = groupValue?.Id ?? groupValue;
        // console.log(groupKey);

        if (groupKey) {
            if (!groups[groupKey]) {
                // Создаем новый объект группы
                // groupData[viewFieldName] =
                //     item[viewFieldName] ??
                //     item.ShortTitle ??
                //     item.PluralName ??
                //     item.Name ??
                //     item.Key ??
                //     item.Code;

                // const isGroupTypeEqItemsType =
                //     groupValue?.Type && item?.Type && groupValue?.Type?.Id === item?.Type?.Id;

                const isGroupValueObject = typeof groupValue === 'object';

                const objectViewName =
                    groupValue[viewFieldName] ??
                    groupValue.DisplayName ??
                    groupValue.PluralName ??
                    groupValue.Name ??
                    groupValue.Key ??
                    groupValue.Code;

                const primitiveViewName = groupValue;

                const viewFieldValue = isGroupValueObject
                    ? objectViewName
                    : isDateGroupField
                      ? primitiveViewName.endsWith('Z')
                          ? dayjs(primitiveViewName).utc().format('DD.MM.YYYY HH:mm')
                          : dayjs(primitiveViewName).format('DD.MM.YYYY HH:mm')
                      : primitiveViewName;

                const valueForParse = isGroupValueObject
                    ? groupValue
                    : { Id: v4() /* groupValue */ };

                groups[groupKey] = {
                    ...valueForParse,
                    children: [],
                    [viewFieldName]: viewFieldValue,
                    [key]: groupValue,
                    isHardGroup: true
                } as NodesByIdType;

                // if (nextKey) {
                //     groups[groupKey][nextKey] = item[nextKey];
                // }

                // if (prevKey) {
                //     groups[groupKey][prevKey] = item[prevKey];
                // }

                // console.log(groups[groupKey]);
            }

            // console.log(groups[groupKey]);

            groups[groupKey].children.push(item);
        } else {
            groups[item.Id] = item;
        }
    });

    // Преобразуем группы в массив
    return Object.values(groups);
}

function groupChildren(
    node: NodesByIdType,
    depth: number,
    parentField: string,
    groupKeys: string[],
    viewFieldName: string
) {
    // console.log(depth, node.children);
    if (!node.children || node.children.length === 0) {
        return;
    }

    console.log(groupKeys);

    // if (groupKeys && depth < (parentField ? 1 : groupKeys.length)) {
    if (groupKeys && depth < groupKeys.length) {
        // const key = parentField ? groupKeys[0] : groupKeys[depth];
        // const key = groupKeys[depth];
        for (let i = depth; i < groupKeys.length; i++) {
            const groupKey = groupKeys[i];
            const nextGroupKey = groupKeys[i + 1];
            const prevGroupKey = groupKeys[i - 1];
            node.children = groupBy(
                node.children,
                groupKey,
                nextGroupKey,
                prevGroupKey,
                viewFieldName
            );
        }
        // console.log(groupBy(node.children, key));
        // node.children = groupBy(node.children, key);
    }

    node.children.forEach((child) => {
        groupChildren(child, depth + 1, parentField, groupKeys, viewFieldName);
    });

    // console.log(node.children);

    return node.children;
}

// TEST
export function checkAndMakeTreeData({
    data,
    groupKeys,
    parentField,
    viewFieldName
}: {
    data: IObjectWithId[];
    groupKeys: string[];
    parentField: string;
    viewFieldName: string;
}) {
    const nodesById: {
        [id: string]: NodesByIdType;
    } = {};
    let roots: NodesByIdType[] = [];

    if (!data) {
        return [];
    }

    // Шаг 1: Создаем словарь узлов по их Id и инициализируем поле children
    data.forEach((item) => {
        nodesById[item.Id] = { ...item, children: [] };
    });

    // Шаг 2: Строим иерархию на основе поля родителя
    data.forEach((item) => {
        const node = nodesById[item.Id];
        const parentInfo = item[parentField];

        if (parentInfo && parentInfo.Id) {
            const parentNode = nodesById[parentInfo.Id];
            if (parentNode) {
                parentNode.children.push(node);
            } else {
                // Родитель не найден, считаем узел корневым
                roots.push(node);
            }
        } else {
            // Нет родителя, узел корневой
            roots.push(node);
        }
    });

    // Шаг 3: Рекурсивно группируем детей по заданным ключам
    roots = groupChildren({ children: roots }, 0, parentField, groupKeys, viewFieldName);

    return cleanEmptyChildren(roots ?? []);
}

export function buildTreeWithGrouping(
    data: IObjectWithId[],
    parentField: string,
    groupingFields: string[],
    viewFieldName: string
) {
    // Step 1: Build tree based on parent-child relationships
    const buildParentTree = (data: IObjectWithId[], parentField: string) => {
        const idMap = new Map();
        const rootNodes = [];

        data?.forEach((item) => idMap.set(item.Id, { ...item, children: [] }));

        data?.forEach((item) => {
            const parentId = item[parentField]?.Id;
            if (parentId && idMap.has(parentId)) {
                idMap.get(parentId).children.push(idMap.get(item.Id));
            } else {
                rootNodes.push(idMap.get(item.Id));
            }
        });

        return rootNodes;
    };

    // Step 2: Group nodes recursively based on specified fields
    const groupNodes = (nodes: IObjectWithId[], fields: string[]) => {
        // console.log(nodes, fields);
        if (fields.length === 0) return nodes;

        const [currentField, ...remainingFields] = fields;

        const grouped = nodes.reduce((acc, node) => {
            const nodeCurrentField = node[currentField];

            const key = JSON.stringify(
                nodeCurrentField && typeof nodeCurrentField === 'object'
                    ? {
                          Id: nodeCurrentField.Id,
                          Code: nodeCurrentField.Code,
                          Key: nodeCurrentField.Key
                      }
                    : nodeCurrentField
            );
            // console.log(key);

            if (key === 'null' || key === 'undefined' || key === undefined) {
                acc.noGroup = acc.noGroup || [];
                acc.noGroup.push(node);
            } else {
                if (!acc[key]) {
                    const groupValue = node[currentField];

                    const isGroupValueObject = typeof groupValue === 'object';
                    // const isDateGroupField = key?.endsWith('At');
                    const isDateGroupField = currentField.endsWith('At');

                    const objectViewName =
                        // groupValue[viewFieldName] ??
                        // groupValue[viewFieldName] ??
                        groupValue?.DisplayName ??
                        groupValue?.PluralName ??
                        groupValue?.Name ??
                        groupValue?.Key ??
                        groupValue?.Code;

                    // console.log('# ', key, groupValue, objectViewName);

                    const primitiveViewName = groupValue;

                    // console.log(primitiveViewName);

                    const viewFieldValue = isGroupValueObject
                        ? objectViewName
                        : isDateGroupField
                          ? primitiveViewName.endsWith('Z')
                              ? dayjs(primitiveViewName).utc().format('DD.MM.YYYY HH:mm')
                              : dayjs(primitiveViewName.split('+')[0]).format('DD.MM.YYYY HH:mm')
                          : primitiveViewName;

                    const valueForParse = isGroupValueObject
                        ? groupValue
                        : { Id: v4() /* groupValue */ };

                    acc[key] = {
                        ...valueForParse,
                        [currentField]: groupValue,
                        [viewFieldName]: viewFieldValue,
                        // Id: groupValue?.Id ?? v4(),
                        // Id: groupValue?.Id ?? Math.random() * Math.random() * Math.random() * 10000,
                        isHardGroup: true,
                        children: []
                    };
                }
                acc[key].children.push(node);
            }

            // if (!acc[key]) {
            //     const groupValue = node[currentField];

            //     const isGroupValueObject = typeof groupValue === 'object';
            //     const isDateGroupField = key?.endsWith('At');

            //     const objectViewName =
            //         // groupValue[viewFieldName] ??
            //         // groupValue[viewFieldName] ??
            //         groupValue?.DisplayName ??
            //         groupValue?.PluralName ??
            //         groupValue?.Name ??
            //         groupValue?.Key ??
            //         groupValue?.Code;

            //     // console.log('# ', key, groupValue, objectViewName);

            //     const primitiveViewName = groupValue;

            //     const viewFieldValue = isGroupValueObject
            //         ? objectViewName
            //         : isDateGroupField
            //         ? primitiveViewName.endsWith('Z')
            //             ? dayjs(primitiveViewName).utc().format('DD.MM.YYYY HH:mm')
            //             : dayjs(primitiveViewName).format('DD.MM.YYYY HH:mm')
            //         : primitiveViewName;

            //     const valueForParse = isGroupValueObject
            //         ? groupValue
            //         : { Id: v4() /* groupValue */ };

            //     acc[key] = {
            //         ...valueForParse,
            //         [currentField]: groupValue,
            //         [viewFieldName]: viewFieldValue,
            //         // Id: groupValue?.Id ?? v4(),
            //         // Id: groupValue?.Id ?? Math.random() * Math.random() * Math.random() * 10000,
            //         isHardGroup: true,
            //         children: []
            //     };
            // }
            // acc[key].children.push(node);
            return acc;
        }, {});

        // console.log(grouped, fields);

        const groupedWithoutNoGroup = { ...grouped, noGroup: undefined };
        delete groupedWithoutNoGroup.noGroup;
        // const groupedValues = Object.values(grouped);
        const groupedValues = Object.values(groupedWithoutNoGroup);

        const isDoubleArray = Array.isArray(groupedValues[0]);

        // const result = groupedValues.map((group) => ({
        //     ...group,
        //     children: groupNodes(group.children, remainingFields)
        // }));
        const result = isDoubleArray
            ? []
            : groupedValues.map((group) => ({
                  ...group,
                  children: groupNodes(group.children, remainingFields)
              }));

        // console.log(result);

        if (grouped.noGroup) {
            result.push(...grouped.noGroup);
        }

        return result;
    };

    // Build the parent tree first
    const tree = buildParentTree(data, parentField);

    // console.log(tree, viewFieldName);

    // Apply grouping on the resulting tree
    return groupNodes(cleanEmptyChildren(tree), groupingFields);
}
