import React, { forwardRef, useEffect, useState } from 'react';
import ExcelJS from 'exceljs';
// import Handsontable from 'handsontable';
// import Handsontable from 'handsontable/base';
import { registerAllModules } from 'handsontable/registry';
import { HotTable } from '@handsontable/react-wrapper';

import 'handsontable/styles/handsontable.min.css';
// import 'handsontable/styles/ht-theme-main.min.css';

import 'handsontable/dist/handsontable.full.min.css';
import Handsontable from 'handsontable';
import { textRenderer } from 'handsontable/renderers';
import { Button, Input, Upload, UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

registerAllModules();

const indexedColors = [
    '#000000',
    '#FFFFFF',
    '#FF0000',
    '#00FF00',
    '#0000FF',
    '#FFFF00',
    '#FF00FF',
    '#00FFFF'
    // Продолжите таблицу индексированных цветов ...
];

const themeColors = [
    '#FFFFFF',
    '#000000',
    '#EEECE1',
    '#1F497D',
    '#4F81BD',
    '#C0504D',
    '#9BBB59',
    '#8064A2'
    // Добавьте цвета темы Excel (обычно 10 основных цветов)
];

// type CellStyle = {
//     backgroundColor?: string;
//     color?: string;
//     fontWeight?: 'bold';
//     fontStyle?: 'italic';
//     fontFamily?: string;
// };

const getCellColor = (fill: ExcelJS.Fill | undefined): string | null => {
    if (!fill || !fill.fgColor) return null;

    if ('argb' in fill.fgColor) {
        return `#${fill.fgColor.argb.slice(2)}`;
    }

    if ('indexed' in fill.fgColor && fill.fgColor.indexed !== undefined) {
        return indexedColors[fill.fgColor.indexed] || null;
    }

    if ('theme' in fill.fgColor && fill.fgColor.theme !== undefined) {
        return themeColors[fill.fgColor.theme] || null;
    }

    return null;
};

// function cssObjectToString(styles: React.CSSProperties): string {
//     return Object.entries(styles)
//         .map(([key, value]) => {
//             const cssKey = key.replace(/([A-Z])/g, '-$1').toLowerCase(); // Преобразование camelCase в kebab-case
//             return `${cssKey}: ${value};`;
//         })
//         .join(' ');
// }

export const ExcelEditor = forwardRef<
    HTMLDivElement,
    {
        file?: File | ArrayBuffer;
        className?: string;
        disableHeader?: boolean;
        data?: (string | number | null)[][];
        height?: string;
        colWidths?: string | number;
    }
>(
    (
        {
            file,
            className,
            data: propData,
            colWidths,
            height = 'calc(100vh - 12rem)',
            disableHeader = false
        },
        ref
    ) => {
        const [data, setData] = useState<(string | number | null)[][]>([]);
        const [cellStyles, setCellStyles] = useState<React.CSSProperties[][]>([]);

        // const handleFileChange: UploadProps['onChange'] = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const handleFileChange: UploadProps['onChange'] = async (info) => {
            // const file = event.target.files?.[0];
            const file = (info.file.originFileObj ?? info.file) as File | ArrayBuffer;
            if (!file) return;

            const workbook = new ExcelJS.Workbook();
            // console.log('workbook', workbook);
            await workbook.xlsx.load(
                (file as any).arrayBuffer
                    ? await (file as File).arrayBuffer()
                    : (file as ArrayBuffer)
            );
            const worksheet = workbook.getWorksheet(1); // Первый лист

            // const maxRows = worksheet?.rowCount;
            const maxCols = Math.max(...worksheet?.columns?.map((col) => col.number));

            const data: (string | number | null)[][] = [];
            const cellStyles: React.CSSProperties[][] = [];

            worksheet?.eachRow((row, rowIndex) => {
                if (rowIndex > 100) return;

                const rowData: (string | number | null)[] = [];
                const rowStyles: React.CSSProperties[] = [];

                for (let colIndex = 1; colIndex <= maxCols; colIndex++) {
                    const cell = worksheet?.getRow(rowIndex).getCell(colIndex);

                    rowData[colIndex - 1] = cell.value || null;

                    // console.log(cell);

                    const style: React.CSSProperties = {};
                    if (cell.fill) {
                        const fgColor = getCellColor(cell.fill);
                        if (fgColor) {
                            style.backgroundColor = fgColor;
                        }
                    }
                    if (cell.font) {
                        // if (cell.font.family) style.fontFamily = cell.font.family;
                        if (cell.font.name) style.fontFamily = cell.font.name;
                        // if (cell.border.bottom) {
                        //     const b = cell.border.bottom;
                        //     const color = b.color?.argb
                        //         ? `#${b.color.argb.slice(2)}${b.color.argb.slice(0, 2)}`
                        //         : b.color?.theme
                        //         ? themeColors[b.color.theme]
                        //         : '#000000';
                        //     const width = b.style?.includes('medium') ? 2 : 1;
                        //     if (b.style === 'thin' || b.style === 'thick')
                        //         style.borderBottom = `${width}px ${color} solid`;
                        //     else if (b.style === 'medium')
                        //         style.borderBottom = `${width}px ${color} solid`;
                        //     else if (b.style?.includes('ash'))
                        //         style.borderBottom = `${width}px ${color} dashed`;
                        //     else {
                        //         style.borderBottom = `${width}px ${color} ${b.style}`;
                        //     }
                        // }
                        // if (cell.border.top) {
                        //     const b = cell.border.top;
                        //     const color = b.color?.argb
                        //         ? `#${b.color.argb.slice(2)}${b.color.argb.slice(0, 2)}`
                        //         : b.color?.theme
                        //         ? themeColors[b.color.theme]
                        //         : '#000000';
                        //     const width = b.style?.includes('medium') ? 2 : 1;
                        //     if (b.style === 'thin' || b.style === 'thick')
                        //         style.borderTop = `${width}px ${color} solid`;
                        //     else if (b.style === 'medium')
                        //         style.borderTop = `${width}px ${color} solid`;
                        //     else if (b.style?.includes('ash'))
                        //         style.borderTop = `${width}px ${color} dashed`;
                        //     else {
                        //         style.borderTop = `${width}px ${color} ${b.style}`;
                        //     }
                        // }
                        // if (cell.border.left) {
                        //     const b = cell.border.left;
                        //     const color = b.color?.argb
                        //         ? `#${b.color.argb.slice(2)}${b.color.argb.slice(0, 2)}`
                        //         : b.color?.theme
                        //         ? themeColors[b.color.theme]
                        //         : '#000000';
                        //     const width = b.style?.includes('medium') ? 2 : 1;
                        //     if (b.style === 'thin' || b.style === 'thick')
                        //         style.borderLeft = `${width}px ${color} solid`;
                        //     else if (b.style === 'medium')
                        //         style.borderLeft = `${width}px ${color} solid`;
                        //     else if (b.style?.includes('ash'))
                        //         style.borderLeft = `${width}px ${color} dashed`;
                        //     else {
                        //         style.borderLeft = `${width}px ${color} ${b.style}`;
                        //     }
                        // }
                        // if (cell.border.right) {
                        //     const b = cell.border.right;
                        //     const color = b.color?.argb
                        //         ? `#${b.color.argb.slice(2)}${b.color.argb.slice(0, 2)}`
                        //         : b.color?.theme
                        //         ? themeColors[b.color.theme]
                        //         : '#000000';
                        //     const width = b.style?.includes('medium') ? 2 : 1;
                        //     if (b.style === 'thin' || b.style === 'thick')
                        //         style.borderRight = `${width}px ${color} solid`;
                        //     else if (b.style === 'medium')
                        //         style.borderRight = `${width}px ${color} solid`;
                        //     else if (b.style?.includes('ash'))
                        //         style.borderRight = `${width}px ${color} dashed`;
                        //     else {
                        //         style.borderRight = `${width}px ${color} ${b.style}`;
                        //     }
                        // }
                        if (cell.alignment) style.textAlign = cell.alignment.horizontal;
                        if (cell.font.size) style.fontSize = `${cell.font.size + 3}px`;
                        if (cell.font.bold) style.fontWeight = 'bold';
                        if (cell.font.italic) style.fontStyle = 'italic';
                        style.color = cell.font.color?.argb
                            ? `#${cell.font.color.argb.slice(2)}${cell.font.color.argb.slice(0, 2)}`
                            : cell.font.color?.theme
                            ? themeColors[cell.font.color.theme]
                            : '#000000';
                    }
                    rowStyles[colIndex - 1] = style;
                }

                data[rowIndex - 1] = rowData;
                cellStyles[rowIndex - 1] = rowStyles;
            });
            //     const rowData: (string | number | null)[] = [];
            //     const rowStyles: React.CSSProperties[] = [];

            //     for (let colIndex = 1; colIndex <= maxCols; colIndex++) {
            //         const cell = worksheet?.getRow(rowIndex).getCell(colIndex);

            //         rowData[colIndex - 1] = cell.value || null;

            //         // console.log(cell);

            //         const style: React.CSSProperties = {};
            //         if (cell.fill) {
            //             const fgColor = getCellColor(cell.fill);
            //             if (fgColor) {
            //                 style.backgroundColor = fgColor;
            //             }
            //         }
            //         if (cell.font) {
            //             // if (cell.font.family) style.fontFamily = cell.font.family;
            //             if (cell.font.name) style.fontFamily = cell.font.name;
            //             if (cell.border.bottom) {
            //                 const b = cell.border.bottom;
            //                 const color = b.color?.argb
            //                     ? `#${b.color.argb.slice(2)}${b.color.argb.slice(0, 2)}`
            //                     : b.color?.theme
            //                     ? themeColors[b.color.theme]
            //                     : '#000000';
            //                 const width = b.style?.includes('medium') ? 2 : 1;
            //                 if (b.style === 'thin' || b.style === 'thick')
            //                     style.borderBottom = `${width}px ${color} solid`;
            //                 else if (b.style === 'medium')
            //                     style.borderBottom = `${width}px ${color} solid`;
            //                 else if (b.style?.includes('ash'))
            //                     style.borderBottom = `${width}px ${color} dashed`;
            //                 else {
            //                     style.borderBottom = `${width}px ${color} ${b.style}`;
            //                 }
            //             }
            //             if (cell.border.top) {
            //                 const b = cell.border.top;
            //                 const color = b.color?.argb
            //                     ? `#${b.color.argb.slice(2)}${b.color.argb.slice(0, 2)}`
            //                     : b.color?.theme
            //                     ? themeColors[b.color.theme]
            //                     : '#000000';
            //                 const width = b.style?.includes('medium') ? 2 : 1;
            //                 if (b.style === 'thin' || b.style === 'thick')
            //                     style.borderTop = `${width}px ${color} solid`;
            //                 else if (b.style === 'medium')
            //                     style.borderTop = `${width}px ${color} solid`;
            //                 else if (b.style?.includes('ash'))
            //                     style.borderTop = `${width}px ${color} dashed`;
            //                 else {
            //                     style.borderTop = `${width}px ${color} ${b.style}`;
            //                 }
            //             }
            //             if (cell.border.left) {
            //                 const b = cell.border.left;
            //                 const color = b.color?.argb
            //                     ? `#${b.color.argb.slice(2)}${b.color.argb.slice(0, 2)}`
            //                     : b.color?.theme
            //                     ? themeColors[b.color.theme]
            //                     : '#000000';
            //                 const width = b.style?.includes('medium') ? 2 : 1;
            //                 if (b.style === 'thin' || b.style === 'thick')
            //                     style.borderLeft = `${width}px ${color} solid`;
            //                 else if (b.style === 'medium')
            //                     style.borderLeft = `${width}px ${color} solid`;
            //                 else if (b.style?.includes('ash'))
            //                     style.borderLeft = `${width}px ${color} dashed`;
            //                 else {
            //                     style.borderLeft = `${width}px ${color} ${b.style}`;
            //                 }
            //             }
            //             if (cell.border.right) {
            //                 const b = cell.border.right;
            //                 const color = b.color?.argb
            //                     ? `#${b.color.argb.slice(2)}${b.color.argb.slice(0, 2)}`
            //                     : b.color?.theme
            //                     ? themeColors[b.color.theme]
            //                     : '#000000';
            //                 const width = b.style?.includes('medium') ? 2 : 1;
            //                 if (b.style === 'thin' || b.style === 'thick')
            //                     style.borderRight = `${width}px ${color} solid`;
            //                 else if (b.style === 'medium')
            //                     style.borderRight = `${width}px ${color} solid`;
            //                 else if (b.style?.includes('ash'))
            //                     style.borderRight = `${width}px ${color} dashed`;
            //                 else {
            //                     style.borderRight = `${width}px ${color} ${b.style}`;
            //                 }
            //             }
            //             if (cell.font.size) style.fontSize = `${cell.font.size + 3}px`;
            //             if (cell.font.bold) style.fontWeight = 'bold';
            //             if (cell.font.italic) style.fontStyle = 'italic';
            //             style.color = cell.font.color?.argb
            //                 ? `#${cell.font.color.argb.slice(2)}${cell.font.color.argb.slice(0, 2)}`
            //                 : cell.font.color?.theme
            //                 ? themeColors[cell.font.color.theme]
            //                 : '#000000';
            //         }
            //         rowStyles[colIndex - 1] = style;
            //     }

            //     data[rowIndex - 1] = rowData;
            //     cellStyles[rowIndex - 1] = rowStyles;
            // }

            // console.log('cellStyles', cellStyles);
            // console.log('data', data);

            setCellStyles(cellStyles);
            setData(data);
        };

        useEffect(() => {
            if (file && !propData) {
                handleFileChange({ file: { originFileObj: file } });
            }
        }, [file, propData]);

        return (
            <div ref={ref} className={`excel_field ${className}`}>
                {/* <input type="file" onChange={handleFileChange} /> */}
                {(!file || propData) && (
                    <Upload showUploadList={false} onChange={handleFileChange}>
                        <Button style={{ marginBottom: 10 }} icon={<UploadOutlined />}>
                            Click to Upload
                        </Button>
                    </Upload>
                )}

                {!!data.length && (
                    <HotTable
                        data={propData ?? data}
                        rowHeaders={!disableHeader}
                        colHeaders={!disableHeader}
                        // rowHeights={(index) => (20 * index) / index}
                        // height="auto"
                        style={{ '--ht-cell-vertical-padding': '1px' }}
                        width="100%"
                        colWidths={colWidths}
                        height={height}
                        autoWrapRow={true}
                        autoWrapCol={true}
                        wordWrap={false}
                        manualColumnResize={true}
                        licenseKey="non-commercial-and-evaluation" // for non-commercial use only
                        // columns={data?.[0]?.map((_) => ({
                        //     renderer(instance, td, row, col, prop, value, cellProperties) {
                        //         td.setAttribute('style', cssObjectToString(cellStyles[row]?.[col]));
                        //         return td;
                        //     }
                        // }))}
                        cells={(row, col) => {
                            const cellProperties: Partial<Handsontable.CellProperties> = {};
                            const style = cellStyles[row]?.[col];
                            if (style) {
                                // console.log(style);
                                // eslint-disable-next-line func-names
                                cellProperties.renderer = function (instance, td, ...rest) {
                                    textRenderer.apply(this, [instance, td, ...rest]);

                                    if (style.backgroundColor)
                                        td.style.backgroundColor = style.backgroundColor;
                                    if (style.color) td.style.color = style.color;
                                    if (style.fontWeight) td.style.fontWeight = style.fontWeight;
                                    if (style.fontStyle) td.style.fontStyle = style.fontStyle;
                                    if (style.fontFamily) td.style.fontFamily = style.fontFamily;
                                    if (style.fontSize) td.style.fontSize = style.fontSize;
                                    if (style.textAlign) td.style.textAlign = style.textAlign;
                                    // if (style.borderBottom)
                                    // td.style.borderBottom = style.borderBottom;
                                    // if (style.borderTop) td.style.borderTop = style.borderTop;
                                    // if (style.borderLeft) td.style.borderLeft = style.borderLeft;
                                    // if (style.borderRight) td.style.borderRight = style.borderRight;
                                };
                            }
                            return cellProperties;
                        }}
                    />
                )}
            </div>
        );
    }
);
