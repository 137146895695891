import { DocCommitment } from 'modules/services/backend-api/generated_models';
import { Meta, MetaField } from 'modules/services/backend-api/generated_info';
import { ISettingsModel } from './types';

export const onOpen_DocOrders = (model: ISettingsModel) => {
    console.log('onOpen', model);
};

export const onOpen_DocCommitments = (model: ISettingsModel<DocCommitment>) => {
    const type = model.data.Type;
    const typeCode = type?.Code;
    console.log('onOpen', model, type, model.meta);

    const commitmentAllocationsMeta = model.meta.ChildNodes?.find(
        (child) => child.Code === 'DocCommitmentAllocations'
    );
    const commitmentExecutionsMeta = model.meta.ChildNodes?.find(
        (child) => child.Code === 'RegCommitmentExecutions'
    );

    const getNewModelMeta = (affixesToExclude: string[]) => {
        if (commitmentAllocationsMeta && commitmentExecutionsMeta) {
            return {
                ...model.meta,
                ChildNodes: [
                    ...model.meta.ChildNodes.filter(
                        (child) =>
                            child.Code !== 'DocCommitmentAllocations' &&
                            child.Code !== 'RegCommitmentExecutions'
                    ),
                    {
                        ...commitmentAllocationsMeta,
                        Fields: commitmentAllocationsMeta.Fields.filter(
                            (field: MetaField) =>
                                !affixesToExclude.some((affix) => field.FieldName.includes(affix))
                        )
                    },
                    {
                        ...commitmentExecutionsMeta,
                        Fields: commitmentExecutionsMeta.Fields.filter(
                            (field: MetaField) =>
                                !affixesToExclude.some((affix) => field.FieldName.includes(affix))
                        )
                    }
                ]
            };
        }

        return model.meta;
    };

    const modelCopy = JSON.parse(JSON.stringify(model));

    if (typeCode === 'VEHICLE_COUNT') {
        // remove all without count
        modelCopy.meta = getNewModelMeta(['Percent', 'Weight', 'Volume', 'Distance', 'Turnover']);
    } else if (typeCode === 'SERVPROV_PERCENT') {
        // remove all without percent
        modelCopy.meta = getNewModelMeta(['Count', 'Weight', 'Volume', 'Distance', 'Turnover']);
    } else if (typeCode === 'TRIP_WEIGHT') {
        // remove all without weight
        modelCopy.meta = getNewModelMeta(['Percent', 'Count', 'Volume', 'Distance', 'Turnover']);
    } else if (typeCode === 'TRIP_DISTANCE') {
        // remove all without weight
        modelCopy.meta = getNewModelMeta(['Percent', 'Weight', 'Volume', 'Count', 'Turnover']);
    } else if (typeCode === 'TRIP_VOLUME') {
        // remove all without weight
        modelCopy.meta = getNewModelMeta(['Percent', 'Weight', 'Count', 'Distance', 'Turnover']);
    } else if (typeCode === 'TRIP_CARGO_TURNOVER') {
        // remove all without weight
        modelCopy.meta = getNewModelMeta(['Percent', 'Weight', 'Volume', 'Distance', 'Count']);
    } else {
        // don't remove nothing
    }

    return modelCopy;
};

export const onClose_DocOrders = (model: ISettingsModel) => {
    console.log('onClose', model);
};

export const onSave_DocOrders = (model: ISettingsModel) => {
    console.log('onSave', model);
};
