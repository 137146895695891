import { Flex } from 'antd';
import { useMedia } from 'react-use';

import { FieldsBox, FieldsBoxItemType } from '../../ui';

export const TopFields = ({
    fields,
    size = 'small'
}: {
    fields: FieldsBoxItemType[];
    size: 'small' | 'middle' | 'default';
}) => {
    const isBigMobile = useMedia('(max-width: 480px)');

    if (isBigMobile)
        return (
            <Flex gap={10} wrap={'wrap'} style={{ paddingLeft: 7 }}>
                {fields.map((field) => {
                    return (
                        <Flex gap={5} style={{ maxWidth: '75%' }} key={field.key}>
                            <span style={{ color: 'gray' }}>{field.label}:</span>
                            {field.children}
                        </Flex>
                    );
                })}
            </Flex>
        );

    return <FieldsBox items={fields} size={size} />;
};
