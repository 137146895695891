import { memo } from 'react';
import KeepAlive from 'react-activation';
import { useLocation, useParams } from 'react-router-dom';
import { NotesPage } from 'smart/modules';

export const KeepAlivedNotesPage = memo<{ rootMeta: string }>(({ rootMeta }) => {
    const { pathname, state } = useLocation();
    const { meta } = useParams();

    return (
        <KeepAlive
            // name={`notesPage_${pathname}`}
            name={`${pathname.includes('notes') ? 'notes' : 'changelog'}Page`}
            // key={`notesPage_${pathname}`}
            key={`${pathname.includes('notes') ? 'notes' : 'changelog'}Page`}
            // cahceKey={`notesPage_${pathname}`}
            cahceKey={`${pathname.includes('notes') ? 'notes' : 'changelog'}Page`}
        >
            <NotesPage rootMeta={rootMeta} meta={meta ?? state?.meta} />
        </KeepAlive>
    );
});
