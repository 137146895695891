import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Layout, Menu, Typography, Button, Space, Flex, Switch, Dropdown } from 'antd';
import { ReloadOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useAsync, useAsyncRetry, useList } from 'react-use';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { buildTreeWithGrouping } from 'smart/utils';
import { MenuItemType } from 'antd/es/menu/interface';
import { LazyIcon } from 'smart/ui';
import { useTranslation } from 'react-i18next';
import { MarkdownEditor } from 'smart/components';
import { useUserData } from 'modules/client/useAuthUser';
import { Loader } from 'ui/Loader/Loader';

import './NotesPage.scss';
import { isDefined } from 'is-lite/exports';
import { useNotifications } from 'utils/hooks';
import { InfoReleaseNote } from 'modules/services/backend-api/generated_models';
import { v4 } from 'uuid';
import { useHandlerRun } from 'smart/utils/hooks';
import dayjs from 'dayjs';

const { Sider, Content } = Layout;
const { Title } = Typography;

const removeEmptyFolders = (tree: any[]) => {
    return tree
        .map((node) => ({
            ...node,
            children: node.children?.length ? removeEmptyFolders(node.children) : undefined
        }))
        .filter((node) => !node.data.IsFolder || (node.data.IsFolder && node.children?.length > 0));
};

function findParentCodes(tree: TreeNode[], targetCode: string, parents: string[] = []): string[] {
    for (const node of tree) {
        // Если текущий узел содержит искомый `Code`, возвращаем массив родителей
        if (node.key === targetCode) {
            return parents;
        }

        // Если есть дочерние узлы, спускаемся вглубь дерева
        if (node.children && node.children.length > 0) {
            const result = findParentCodes(node.children, targetCode, [...parents, node.key]);
            if (result.length > 0) {
                return result;
            }
        }
    }

    // Если элемент не найден в данном поддереве, возвращаем пустой массив
    return [];
}

function incrementVersion(version: string) {
    const parts = version.split('.');

    if (parts.length === 3) {
        // Увеличиваем последний уровень (третий)
        parts[2] = (parseInt(parts[2], 10) + 1).toString().padStart(2, '0');
    } else if (parts.length === 2) {
        // Увеличиваем второй уровень (второй)
        parts[1] = (parseInt(parts[1], 10) + 1).toString();
    }

    return parts.join('.');
}

function findParentVersion(version: string, notes: IObjectWithId[]) {
    // Разбиваем текущую версию на уровни
    const parts = version.split('.');

    // Удаляем младшие уровни по очереди, начиная с конца
    while (parts.length > 0) {
        parts.pop(); // Убираем последний уровень
        const parentVersion = parts.join('.'); // Формируем родительскую версию

        const parent = notes.find((note) => note.Code === parentVersion);

        if (parent) return parent;
    }

    return null; // Если ничего не нашли, возвращаем null
}

export const NotesPage = memo<{ meta?: string; rootMeta: string }>(({ rootMeta, meta }) => {
    const isNotes = rootMeta === 'InfoMetaNotes';
    const isChangeLogs = rootMeta === 'InfoReleaseNotes';

    const {
        t,
        i18n: { language }
    } = useTranslation();
    const { isPowerUser } = useUserData();
    const { notification } = useNotifications();
    const { run } = useHandlerRun();

    const selectedNoteStartState = useRef<IObjectWithId | null>(null);
    const [selectedNote, setSelectedNote] = useState<IObjectWithId | null>(null);
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [activeKey, setActiveKey] = useState<string | undefined>(meta);
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const [mode, setMode] = useState<'view' | 'edit'>('view');
    const [isShowAllMenus, setIsShowAllMenus] = useState(false);
    const [notes, notesMethods] = useList<IObjectWithId>();
    const [newItemCode, setNewItemCode] = useState<string | null>(null);

    const notesFetch = useAsyncRetry(async () => {
        const notes = await metaStore.makeSelect({
            meta: rootMeta,
            filters: isShowAllMenus ? undefined : 'or(IsContentExists=eq.true,IsFolder=eq.true)'
        });
        // console.log(notes);

        notesMethods.set(notes?.objects);

        // return notes?.objects;
    }, [rootMeta, isShowAllMenus]);

    const metaID = useAsync(async () => {
        if (isNotes && selectedNote?.Code) {
            const meta = await metaStore.makeSelect({
                meta: 'InfoMeta',
                filters: `Code=eq.${selectedNote.Code}`,
                page: 1,
                page_size: 1
            });

            return meta?.objects?.[0]?.Id;
        }

        return null;
    }, [isNotes, selectedNote?.Code]);

    const handleClickMenu = useCallback(
        async (note: IObjectWithId) => {
            // console.log('click', note.Code);
            if (note.IsContentExists || (isShowAllMenus && !note.IsFolder)) {
                setLoading(true);
                const res = await metaStore.makeGet({ meta: rootMeta, id: note.Id });
                const notesDetail = res.object;

                // console.log(notesDetail);

                setSelectedNote(notesDetail);
                selectedNoteStartState.current = notesDetail;
                setActiveKey(note.Code);
                setLoading(false);
            } else {
                selectedNoteStartState.current = null;
                setSelectedNote(null);
            }
        },
        [rootMeta, isShowAllMenus]
    );

    useEffect(() => {
        // if (meta && !notesFetch.loading && notes) {
        if (meta && !newItemCode && !notesFetch.loading && notes) {
            const note = notes.find((note) => note.Code === meta);
            if (note) {
                handleClickMenu(note);
            }
        }
    }, [handleClickMenu, meta, notesFetch.loading, notes, newItemCode]);

    // console.log(selectedNote, selectedNote?.Content?.[language]);

    const notesMenuItems = useMemo<MenuItemType[]>(() => {
        const result: MenuItemType[] = (notes ?? []).map((note) => ({
            key: note.Code,
            icon: note.Icon ? <LazyIcon icon={note.Icon} /> : null,
            theme: 'light',
            label: note.DisplayName?.[language] ?? note.Name?.[language] ?? note.Code,
            Id: note.Id,
            parent: note.Parent,
            data: note,
            onClick: (info) => {
                // info.domEvent.preventDefault();
                // info.domEvent.stopPropagation();
                // console.log(info);
                if (note.Code === info.key) handleClickMenu(note);
            }
        }));

        // if (isShowAllMenus) {
        //     return buildTreeWithGrouping(result, 'parent', [], 'label');
        // }

        return removeEmptyFolders(buildTreeWithGrouping(result, 'parent', [], 'label'));
    }, [handleClickMenu, language, notes]);

    useEffect(() => {
        const m = newItemCode ?? meta;

        if (m) {
            setOpenKeys(findParentCodes(notesMenuItems, m) ?? []);
        }
    }, [meta, notesMenuItems, newItemCode]);

    // useEffect(() => {
    //     if (newItemCode) {
    //         setOpenKeys(findParentCodes(notesMenuItems, newItemCode) ?? []);
    //     }
    // }, [newItemCode, notesMenuItems]);

    const resetState = () => {
        setNewItemCode(null);
        setOpenKeys([]);
        setActiveKey(undefined);
        setSelectedNote(null);
        selectedNoteStartState.current = null;
    };

    // console.log(notesMenuItems);

    // console.log(isPowerUser);

    const isShowToolbar =
        isPowerUser && (selectedNote?.IsContentExists || (isShowAllMenus && selectedNote));

    // console.log(selectedNote, activeKey, isShowAllMenus, openKeys);

    // console.log(notes);

    // console.log('metaID:', metaID);

    return (
        <Loader status={notesFetch.loading}>
            <Layout style={{ height: '83vh' }}>
                <Dropdown
                    trigger={['contextMenu']}
                    menu={{
                        items:
                            notes && isChangeLogs
                                ? [
                                      {
                                          key: 'create',
                                          label: t('create'),
                                          icon: <PlusOutlined />,
                                          onClick: () => {
                                              //   console.log('context');
                                              const newCode = incrementVersion(notes[0].Code);
                                              const newNote = {
                                                  Id: v4(),
                                                  Code: newCode,
                                                  Content: { ru: '', en: '' },
                                                  IsContentExists: true,
                                                  IsFolder: false,
                                                  Parent: findParentVersion(newCode, notes),
                                                  ReleaseDate: dayjs().format('YYYY-MM-DD'),
                                                  Name: { ru: newCode, en: newCode }
                                              } as InfoReleaseNote;

                                              notesMethods.push(newNote);
                                              selectedNoteStartState.current = newNote;

                                              setNewItemCode(newCode);
                                              setActiveKey(newCode);
                                              setSelectedNote(newNote);
                                              setMode('edit');
                                          }
                                      }
                                  ]
                                : []
                    }}
                >
                    <Sider
                        width={300}
                        style={{ background: '#fff', borderRight: '1px solid #f0f0f0' }}
                    >
                        <Menu
                            items={notesMenuItems}
                            mode="inline"
                            style={{
                                height: '100%',
                                borderRight: 0,
                                overflowY: 'auto',
                                overflowX: 'hidden'
                            }}
                            openKeys={openKeys}
                            onOpenChange={setOpenKeys}
                            // defaultOpenKeys={
                            //     meta ? findParentCodes(notesMenuItems, meta) : undefined
                            // }
                            // defaultSelectedKeys={meta ? [meta] : undefined}
                            selectedKeys={activeKey ? [activeKey] : undefined}
                            activeKey={activeKey}
                        />
                    </Sider>
                </Dropdown>
                {/* <Layout style={{ padding: '24px' }}> */}
                <Layout style={{ paddingLeft: '10px' }}>
                    <Content
                        className={!isShowToolbar ? 'hide_editor_toolbar' : 'show_editor_toolbar'}
                        style={{
                            background: '#fff',
                            padding: 10,
                            margin: 0,
                            overflow: 'hidden',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Loader status={loading}>
                            <Flex justify="space-between">
                                <Title
                                    level={3}
                                    style={{
                                        marginTop: 0,
                                        marginLeft: 10,
                                        marginBottom: !isShowToolbar ? 0 : 15
                                    }}
                                    children={
                                        selectedNote?.DisplayName?.[language] ??
                                        selectedNote?.Name?.[language] ??
                                        selectedNote?.Code
                                    }
                                />
                                <Space>
                                    {isPowerUser && isNotes ? (
                                        <Space>
                                            <Switch
                                                // checkedChildren={t('show_all')}
                                                // unCheckedChildren={t('hide_all')}
                                                value={isShowAllMenus}
                                                onChange={(checked) => {
                                                    const prev = isShowAllMenus;
                                                    setIsShowAllMenus(checked);
                                                    if (prev === true) {
                                                        if (meta) {
                                                            const note = notes.value?.find(
                                                                (note) => note.Code === meta
                                                            );
                                                            if (note) {
                                                                handleClickMenu(note);
                                                            }
                                                            setOpenKeys(
                                                                findParentCodes(
                                                                    notesMenuItems,
                                                                    meta
                                                                ) ?? []
                                                            );
                                                        } else {
                                                            resetState();
                                                        }
                                                    }
                                                }}
                                            />
                                            <Typography.Text children={t('show_empty')} />
                                        </Space>
                                    ) : null}
                                    <Button onClick={notesFetch.retry} icon={<ReloadOutlined />} />

                                    {isShowToolbar &&
                                    selectedNote &&
                                    isChangeLogs &&
                                    mode === 'view' ? (
                                        <Button
                                            // type="primary"
                                            onClick={async () => {
                                                await run({
                                                    Action_Id: '',
                                                    meta: rootMeta,
                                                    ids: [selectedNote.Id],
                                                    handler: 'Delete',
                                                    args: {}
                                                });

                                                resetState();

                                                notesFetch.retry();
                                            }}
                                            loading={loading || btnLoading || metaID.loading}
                                            icon={<DeleteOutlined />}
                                        >
                                            {/* {t('delete')} */}
                                        </Button>
                                    ) : null}

                                    {isShowToolbar ? (
                                        mode === 'edit' ? (
                                            <Space.Compact>
                                                <Button
                                                    type="primary"
                                                    onClick={async () => {
                                                        try {
                                                            setBtnLoading(true);
                                                            if (isNotes) {
                                                                await metaStore.makeSave({
                                                                    meta: 'InfoMeta',
                                                                    objects: [
                                                                        {
                                                                            Id: metaID.value,
                                                                            Code: selectedNote?.Code,
                                                                            Description: {
                                                                                [language]:
                                                                                    selectedNote
                                                                                        ?.Content?.[
                                                                                        language
                                                                                    ]
                                                                            }
                                                                        }
                                                                    ]
                                                                });
                                                                selectedNoteStartState.current =
                                                                    selectedNote;
                                                            } else if (isChangeLogs) {
                                                                const createdObject =
                                                                    await metaStore.makeSave({
                                                                        meta: rootMeta,
                                                                        objects: [
                                                                            selectedNote
                                                                            // {
                                                                            //     Id: selectedNote?.Id,
                                                                            //     Code: selectedNote?.Code,
                                                                            //     Description: {
                                                                            //         [language]:
                                                                            //             selectedNote?.Content?.[language]
                                                                            //     }
                                                                            // }
                                                                        ]
                                                                    });

                                                                const note =
                                                                    createdObject.objects?.[0];

                                                                if (note) {
                                                                    selectedNoteStartState.current =
                                                                        note;

                                                                    notes.shift();
                                                                    notes.push(note);

                                                                    setSelectedNote(note);
                                                                } else {
                                                                    selectedNoteStartState.current =
                                                                        selectedNote;
                                                                }
                                                            }

                                                            setMode('view');
                                                        } catch (error) {
                                                            console.error(error);
                                                            notification.error({
                                                                // key,
                                                                message: t('error'),
                                                                description: (error as Error)
                                                                    .message
                                                            });
                                                        } finally {
                                                            notesFetch.retry();
                                                            setBtnLoading(false);
                                                        }
                                                    }}
                                                    disabled={
                                                        selectedNoteStartState.current?.Content?.[
                                                            language
                                                        ] === selectedNote?.Content?.[language]
                                                    }
                                                    loading={
                                                        loading || btnLoading || metaID.loading
                                                    }
                                                >
                                                    {t('save')}
                                                </Button>
                                                <Button
                                                    type="default"
                                                    color="danger"
                                                    variant="solid"
                                                    onClick={() => {
                                                        if (newItemCode) {
                                                            notesMethods.filter(
                                                                (note) => note.Code !== newItemCode
                                                            );
                                                            resetState();
                                                        } else {
                                                            setSelectedNote(
                                                                selectedNoteStartState.current
                                                            );
                                                            setMode('view');
                                                        }
                                                    }}
                                                    // loading={loading || btnLoading || metaID.loading}
                                                >
                                                    {t('cancel')}
                                                </Button>
                                            </Space.Compact>
                                        ) : (
                                            <Button
                                                type="primary"
                                                onClick={() => setMode('edit')}
                                                loading={loading || btnLoading || metaID.loading}
                                            >
                                                {t('edit')}
                                            </Button>
                                        )
                                    ) : null}
                                </Space>
                            </Flex>

                            <MarkdownEditor
                                readOnly={!isPowerUser || (isPowerUser && mode === 'view')}
                                value={selectedNote?.Content?.[language]}
                                meta={selectedNote?.Code}
                                mode={mode}
                                onChange={(newContent) => {
                                    // console.log(newContent);
                                    setSelectedNote((prev) => ({
                                        ...prev,
                                        IsContentExists: isDefined(newContent),
                                        Content: { ...prev.Content, [language]: newContent }
                                    }));
                                }}
                            />
                        </Loader>

                        {/* <Card title={helpContent[selectedKey].title} bordered={false}>
                        {helpContent[selectedKey].content}
                    </Card> */}
                    </Content>
                </Layout>
            </Layout>
        </Loader>
    );
});
