import { Flex, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import { useAsync, useAsyncRetry, useDebounce, useUpdateEffect } from 'react-use';
import React, { useState } from 'react';
import { metaStore } from 'utils/store/MetaStore';
import { FieldTimeOutlined } from '@ant-design/icons';
import { MetaField } from 'modules/services/backend-api/generated_info';
import { useTranslation } from 'react-i18next';
import { useBaseLanguage } from 'smart/utils/hooks';
import { SmartTable } from '../SmartTable/SmartTable';

import './AuditInfo.scss';

interface AuditModalProps extends React.PropsWithChildren {
    objectId: string;
    field: MetaField;
    meta: string;
    placement?: 'start' | 'end';
    style?: React.CSSProperties;
    isReload?: boolean;
}

export const AuditInfo = observer<AuditModalProps>(
    ({ objectId, field, meta, children, style, isReload, placement = 'end' }) => {
        const {
            i18n: { language }
        } = useTranslation();
        const baseLanguage = useBaseLanguage();
        const [isDebounceReload, setIsDebounceReload] = useState(false);

        const [open, setOpen] = useState(false);

        const {
            value: data,
            loading: dataLoading,
            retry
        } = useAsyncRetry(async () => {
            // console.log('FETCH');
            const res = await metaStore.makeSelect({
                meta: 'InfoObjectAudits',
                filters: `FieldName=eq.${field.FieldName}&Meta=eq.${meta}&Object_Id=eq.${objectId}`
            });

            // console.log(res.objects);
            return res.objects ?? [];
        }, [field.FieldName, meta, objectId]);

        useUpdateEffect(() => {
            // console.log(isReload);
            if (isDebounceReload) retry();
        }, [isDebounceReload]);

        useDebounce(
            () => {
                setIsDebounceReload(true);
            },
            500,
            [isReload]
        );

        useUpdateEffect(() => {
            if (isDebounceReload) setIsDebounceReload(false);
        }, [isDebounceReload]);

        // console.log(data);

        const { value: metaInfo, loading: metaInfoLoading } = useAsync(async () => {
            const res = await metaStore.getInfo('InfoObjectAudits');
            return res;
        }, []);

        const title = `${field.Name?.[language === 'tech' ? baseLanguage : language] ?? field.Name?.[baseLanguage] ?? field.FieldName} (${metaInfo?.SingularName?.[language === 'tech' ? baseLanguage : language] ?? metaInfo?.SingularName?.[baseLanguage] ?? metaInfo?.Code})`;

        return (
            <Flex
                style={{
                    width: '100%',
                    alignItems: !children ? 'center' : undefined,
                    justifyContent: !children ? 'center' : undefined,
                    ...style
                }}
                gap={3}
            >
                {placement === 'end' ? children : null}

                {/* {placement === 'start' ? (
                    <div
                        style={{
                            width: 14,
                            height: 14,
                            // marginLeft: placement === 'end' ? 5 : undefined,
                            marginRight: placement === 'start' ? 5 : undefined
                        }}
                    />
                ) : ( */}
                <FieldTimeOutlined
                    style={{
                        justifySelf: 'flex-end',
                        color: 'rgba(0, 0, 0, 0.2)',
                        transition: 'all 0.3s ease',
                        marginLeft: placement === 'end' ? 5 : undefined,
                        marginRight: placement === 'end' ? undefined : 5
                        // marginTop: 'auto',
                        // marginBottom: 'auto',
                        // ...iconStyle
                    }}
                    className="audit-icon"
                    onClick={() => setOpen(true)}
                />
                {/* )} */}

                {placement === 'start' ? (
                    <div style={{ width: 'calc(100% - 22px)' }}>{children}</div>
                ) : null}

                <Modal
                    open={open}
                    centered
                    title={title}
                    width={'80%'}
                    style={{
                        maxHeight: 'calc(100vh - 300px)',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}
                    footer={[]}
                    onCancel={() => setOpen(false)}
                    // okButtonProps={{ style: { display: 'none' } }}
                >
                    <SmartTable
                        meta={'InfoObjectAudits'}
                        loading={metaInfoLoading || dataLoading}
                        fields={metaInfo?.Fields}
                        data={data}
                    />
                </Modal>
            </Flex>
        );
    }
);
