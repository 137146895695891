import React, { PropsWithChildren, memo } from 'react';

import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

interface LoaderProps extends PropsWithChildren {
    status?: boolean;
    style?: React.CSSProperties;
    wrapperClassName?: string;
    forceDisableTip?: boolean;
    customTip?: string;
    customIndicator?: React.ReactElement<HTMLElement>;
    size?: 'small' | 'default' | 'large';
}

const styles: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
};

export const Loader = memo(
    ({
        children,
        customTip,
        customIndicator,
        size = 'default',
        forceDisableTip = false,
        style = {},
        wrapperClassName,
        status = true
    }: LoaderProps) => {
        const { t } = useTranslation();

        const tip = children
            ? !forceDisableTip
                ? customTip || t('loading')
                : undefined
            : undefined;

        return (
            <Spin
                wrapperClassName={wrapperClassName}
                spinning={status}
                tip={tip}
                size={size}
                indicator={customIndicator}
                // indicator={<LoadingOutlined style={{ color: '#3185D2' }} />}
                style={!children ? { ...styles, ...style } : style}
            >
                {children}
            </Spin>
        );
    }
);
