import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { LANGUAGES } from 'utils/i18n/i18n';

import { Flex, Input, Modal } from 'antd';
import { useUpdateEffect } from 'react-use';
import { languagesStore } from 'utils/store/languages/languagesStore';
import { toSnakeCase } from 'utils';
import { useBaseLanguage } from 'smart/utils/hooks';
import { MultilanguageValueType } from '../../types';

interface LanguageTranslateDialogProps {
    value: MultilanguageValueType;
    setValue: (newValue: MultilanguageValueType) => void;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    fieldName?: string;
}

export const LanguageTranslateDialog = ({
    value,
    setValue,
    open,
    setOpen,
    fieldName = ''
}: LanguageTranslateDialogProps) => {
    const {
        t,
        i18n: { language: currentLanguage }
    } = useTranslation();
    const baseLanguage = useBaseLanguage();

    const [multilanguageValue, setMultilanguageValue] = useState<MultilanguageValueType>(value);

    useUpdateEffect(() => {
        setMultilanguageValue(() => value);
    }, [value]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleApply = useCallback(() => {
        const withoutEmptyValues: Record<string, string> = {};

        for (const [lang, value] of Object.entries(multilanguageValue ?? {})) {
            if (value) {
                withoutEmptyValues[lang] = value;
            }
        }

        setValue(withoutEmptyValues);
        setOpen(false);
    }, [setValue, setOpen, multilanguageValue]);

    const handleValueChange = useCallback(
        (newValue: string, language: LANGUAGES) => {
            const copyVal: MultilanguageValueType = { ...multilanguageValue };

            copyVal[language] = newValue || undefined;

            setMultilanguageValue(copyVal);
        },
        [setMultilanguageValue, multilanguageValue]
    );

    // console.log(toJS(languagesStore.languages));

    return (
        <Modal
            open={open}
            onCancel={handleClose}
            onOk={handleApply}
            title={fieldName}
            cancelText={t('cancel')}
            okText={t('apply')}
            cancelButtonProps={{ type: 'text', className: 'btn btn-red' }}
            okButtonProps={{ className: 'btn btn-blue' }}
            centered
            destroyOnClose
        >
            <Flex gap={5} vertical>
                {multilanguageValue &&
                    // Object.keys(LANGUAGES)
                    languagesStore.languages
                        .sort((language) =>
                            toSnakeCase(language.Code) === currentLanguage ? -1 : 1
                        )
                        // .sort((language) => (language === currentLanguage ? -1 : 1))
                        .map(({ Code, IsActive, IsDisabled, Name }) => {
                            // .map((language) =>
                            const language = toSnakeCase(Code);

                            return language !== 'tech' && IsActive && !IsDisabled ? (
                                <Flex gap={5} align={'center'}>
                                    <span style={{ color: 'rgba(0, 0, 0, 0.45)', width: '120px' }}>
                                        {Name?.[currentLanguage] ??
                                            Name?.[baseLanguage] ??
                                            t(language)}
                                        :
                                    </span>
                                    <Input
                                        placeholder={t('no_value') as string}
                                        key={language}
                                        value={
                                            multilanguageValue
                                                ? multilanguageValue[language as LANGUAGES] || ''
                                                : ''
                                        }
                                        onChange={(e) => {
                                            handleValueChange(
                                                e.target.value,
                                                language as LANGUAGES
                                            );
                                        }}
                                    />
                                </Flex>
                            ) : null;
                        })}
            </Flex>
        </Modal>
    );
};
