import { PlainObject } from '@gilbarbara/types';
import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

import { Filter } from 'modules/supabase/utils/supabaseClient';

interface SpringboardStoreStateType {
    filters?: Filter[];
    filterString?: string;
    pageTitle?: string;
    data?: any;
    cacheKey?: string | number;
    mode: 'edit' | 'view';
}

// export interface SpringboardStoreItemType {
//     pathname: string;
//     search?: string | null;
//     state?: SpringboardStoreStateType | null;
//     [key: string]: any;
// }

const initialStore: PlainObject[] = [];

export class SpringboardStore {
    favorites: PlainObject[] = initialStore;

    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: 'SpringboardStore',
            properties: ['favorites'],
            storage: window.localStorage
        });
    }

    reset = () => {
        runInAction(() => {
            this.favorites = [];
        });
    };

    // private remove = (favoriteItem: PlainObject) => {
    //     const setIds = new Set(this.favorites.map((f) => f.Id));
    //
    //     if (setIds.has(favoriteItem.id) || setIds.has(favoriteItem.Id)) {
    //         this.favorites = this.favorites.filter(
    //             (f) => f.Id !== favoriteItem.id && f.Id !== favoriteItem.Id
    //         );
    //     }
    // };

    set = (newArray: PlainObject[]) => {
        runInAction(() => {
            this.favorites = newArray;
        });
    };

    pushOrRemove = (favoriteItem: PlainObject) => {
        runInAction(() => {
            const setIds = new Set(this.favorites.map((f) => f.Id));

            if (setIds.has(favoriteItem.id) || setIds.has(favoriteItem.Id)) {
                this.favorites = this.favorites.filter(
                    (f) => f.Id !== favoriteItem.id && f.Id !== favoriteItem.Id
                );
            } else {
                this.favorites = [
                    ...this.favorites,
                    { ...favoriteItem, Id: favoriteItem.id ?? favoriteItem.Id }
                ];
            }
        });
    };

    push = (favoriteItem: PlainObject) => {
        runInAction(() => {
            const setIds = new Set(this.favorites.map((f) => f.Id));

            if (!setIds.has(favoriteItem.id) && !setIds.has(favoriteItem.Id)) {
                this.favorites = [
                    ...this.favorites,
                    { ...favoriteItem, Id: favoriteItem.id ?? favoriteItem.Id }
                ];
            }
        });
    };
}

export const springboardStore = new SpringboardStore();
