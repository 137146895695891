import { MinioStorage } from 'modules/client/types';
import { metaStore } from 'utils/store/MetaStore';

export const createMinioStorage = (
    baseUrl: string,
    getAuthToken: () => Promise<string>
): MinioStorage => {
    // baseUrl = 'http://localhost:9001/'; // для тестов
    const upload: MinioStorage['upload'] = async (bucket, filePath, file) => {
        const authToken = await getAuthToken();
        try {
            // Формируем полный URL для загрузки файла
            const uploadUrl = `${baseUrl}${bucket}/${filePath}`;
            // const testUrl = '/api/v1/storage/<bucket>/filename';
            // Загружаем файл через PUT-запрос
            const response = await fetch(uploadUrl, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${authToken}`, // Временный токен
                    'Content-Type': file.type,
                    AuthProvider: metaStore?.api?.client?.authProvider || '' // MIME-тип файла
                },
                body: file
            });

            if (!response.ok) {
                console.error('Upload failed:', response.status, response.statusText);
                return {
                    error: response.statusText
                };
            }

            console.log('File uploaded successfully:', file.name);

            return {
                data: {
                    path: filePath
                }
            };
        } catch (error) {
            console.error('Error uploading file:', error);
            return {
                error
            };
        }
    };

    const download: MinioStorage['download'] = async (bucket, filePath) => {
        const authToken = bucket !== 'public' ? await getAuthToken() : null;
        try {
            // Формируем URL для скачивания файла
            // debugger;
            let token = '';
            if (authToken) {
                token = `Bearer ${authToken}`;
            }
            const downloadUrl = `${baseUrl}${bucket}/${filePath}`;
            const response = await fetch(downloadUrl, {
                method: 'GET',
                headers: {
                    Authorization: token,
                    AuthProvider: metaStore?.api?.client?.authProvider || ''
                }
            });
            // debugger;
            if (!response.ok) {
                throw new Error(`Download failed: ${response.status} ${response.statusText}`);
                // console.warn('Download failed:', response.status, response.statusText);
                // return;
            }

            // Читаем тело ответа как Blob
            const blob = await response.blob();

            // Создаем временный URL для файла
            // const downloadUrlBlob = URL.createObjectURL(blob);

            // // Создаем ссылку для скачивания
            // const link = document.createElement('a');
            // link.href = downloadUrlBlob;

            // // Устанавливаем имя файла (можно взять из серверного ответа, если он передает имя)
            // link.download = filePath.split('/').pop() || 'downloaded-file';

            // // Автоматически кликаем по ссылке для скачивания
            // document.body.appendChild(link);
            // link.click();

            // // Удаляем ссылку из DOM
            // document.body.removeChild(link);

            // // Освобождаем память, удаляя временный URL
            // URL.revokeObjectURL(downloadUrlBlob);

            // console.log('File downloaded successfully');
            // Возвращаем файл в виде Blob
            return blob;
        } catch (error) {
            console.error('Error downloading file:', error);
            throw error;
        }
    };

    const deleteFile: MinioStorage['delete'] = async (bucket, filePath) => {
        const authToken = await getAuthToken();
        try {
            // Формируем URL для удаления файла
            const deleteUrl = `${baseUrl}${bucket}/${filePath}`;

            const response = await fetch(deleteUrl, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    AuthProvider: metaStore?.api?.client?.authProvider || '',
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`Delete failed: ${response.status} ${response.statusText}`);
            }

            // Возвращаем подтверждение об удалении (например, JSON)

            return;
        } catch (error) {
            const errorMsg = `Error deleting file:', ${error}`;
            console.error(errorMsg);
            return errorMsg;
        }
    };

    return {
        upload,
        download,
        delete: deleteFile
    };
};
