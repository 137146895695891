import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ViewName } from 'modules/supabase/types/Dataset';
import { emitter } from 'utils/emitter';
import { useGenerateEvents } from './useGenerateEvents';

import './GraphicsTab.scss';

interface IGraphicsTabProps {
    slots_view_name?: ViewName;
    items_view_name?: ViewName;
    activeTab: string;
    activityFromAt: string;
    activityToAt: string;
    rootId: string;
}

export const GraphicsTab = ({
    activeTab,
    activityFromAt,
    activityToAt,
    rootId
}: IGraphicsTabProps) => {
    const {
        i18n: { language }
    } = useTranslation();

    const mode = 'view';

    const calendarRef = useRef<any>(null);

    const { events, retry } = useGenerateEvents(rootId);
    const [calendarLocale, setCalendarLocale] = useState<Record<string, any>>({});

    useEffect(() => {
        const cb = async () => {
            retry();
        };

        emitter.on('graphic_tab_refresh', cb);

        return () => emitter.off('graphic_tab_refresh', cb);
    }, [retry]);

    useEffect(() => {
        switch (language) {
            case 'en':
                import('@fullcalendar/core/locales/en-au').then((locale) => {
                    setCalendarLocale(locale);
                });
                break;

            case 'ru':
                import('@fullcalendar/core/locales/ru').then((locale) => {
                    setCalendarLocale(locale);
                });
                break;

            case 'kz':
                import('@fullcalendar/core/locales/ru').then((locale) => {
                    setCalendarLocale(locale);
                });
                break;
            default:
                import('@fullcalendar/core/locales/en-au').then((locale) => {
                    setCalendarLocale(locale);
                });
                break;
        }
    }, [language]);

    return (
        <>
            {/* <Row> */}
            {/* TODO: пока решили не внедрять редактирование */}
            {/* <Col flex="3 3 0" hidden={mode === 'view'}>
                    <ScheduleEdit
                        items_view_name={items_view_name}
                        slots_view_name={slots_view_name}
                    />
                </Col> */}

            {/* <Col flex={7}> */}

            <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, interactionPlugin]}
                locale={calendarLocale.default}
                timeZone="utc"
                aspectRatio={mode === 'edit' ? 3 : 2}
                headerToolbar={{
                    end: 'today prev,next'
                }}
                eventTimeFormat={{
                    // like '14:30:00'
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                }}
                displayEventEnd
                dayHeaderFormat={{ weekday: 'long' }}
                initialView="dayGridMonth"
                eventSources={events}
                validRange={{ start: activityFromAt, end: activityToAt }}
            />

            {/* </Col> */}
            {/* </Row> */}
        </>
    );
};
