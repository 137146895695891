import { useAsync } from 'react-use';
import { toSnakeCase } from 'utils';
import { metaStore } from 'utils/store/MetaStore';

export const useBaseLanguage = () => {
    const { value: baseLanguage } = useAsync(
        async () =>
            metaStore.meta.get('all')?.params?.BASE_LANGUAGE?.param_value ??
            metaStore.meta.get('all')?.params?.BASE_LANGUAGE ??
            metaStore.getParam({ param_name: 'BASE_LANGUAGE', default_value: 'ru' }),
        []
    );

    return toSnakeCase(baseLanguage?.Code) ?? 'ru';
};
