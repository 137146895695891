import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { Stepper } from 'smart/components';

export const StepperPage = memo(() => {
    const { state } = useLocation();

    // const data = state?.responseData?.ids;

    return <Stepper ids={state?.responseData?.ids} direction="vertical" />;
});
