export function cssStringToObject(cssString: string): React.CSSProperties {
    const styles: React.CSSProperties = {};

    if (!cssString) return styles;

    cssString
        .split(';') // Разделяем строку по разделителю свойств
        .map((rule) => rule.trim()) // Убираем лишние пробелы
        .filter(Boolean) // Убираем пустые строки
        .forEach((rule) => {
            const [key, value] = rule.split(':').map((part) => part.trim()); // Разделяем по ":"
            if (key && value) {
                const camelCaseKey = key.replace(/-([a-z])/g, (_, char) => char.toUpperCase()); // Преобразуем kebab-case в camelCase
                styles[camelCaseKey] = value;
            }
        });

    return styles;
}
