import { Badge, Button, Divider, Flex, Popover, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useMap } from 'react-use';
import { PlainObject } from '@gilbarbara/types';
import { useTranslation } from 'react-i18next';

import './Legend.scss';

export interface LegendProps {
    legend: PlainObject;
    size?: 'small' | 'middle' | 'large';
}

export const Legend: React.FC<LegendProps> = ({ legend, size }) => {
    const { t } = useTranslation();

    const [defaultLegend] = useMap<PlainObject>({
        red: t('filled'),
        '#FFB6C1': t('partial_filled'),
        skyblue: t('free'),
        lightgray: t('closed')
    });

    return (
        <Popover
            overlayInnerStyle={{ minWidth: 300 }}
            trigger="click"
            arrow
            content={
                <Flex gap={5} align={'center'}>
                    <Space direction="vertical" className="legend_column">
                        <strong className="legend_column__title">{t('default')}</strong>
                        <Flex style={{ marginLeft: 5 }} vertical>
                            {Object.keys(defaultLegend).map((dl) => (
                                <Space>
                                    <Space>
                                        <Badge color={dl} />
                                    </Space>
                                    {defaultLegend[dl]}
                                </Space>
                            ))}
                        </Flex>
                    </Space>
                    <Divider type="vertical" className="legend_column__divider" />
                    <Space direction="vertical" className="legend_column">
                        <strong className="legend_column__title">{t('used')}</strong>
                        <Flex style={{ marginLeft: 5 }} vertical>
                            {Object.keys(legend).map((l) => (
                                <Space>
                                    <Space>
                                        <Badge color={l} />
                                    </Space>
                                    {legend[l]}
                                </Space>
                            ))}
                        </Flex>
                    </Space>
                </Flex>
            }
        >
            <Button size={size} icon={<InfoCircleOutlined />} />
        </Popover>
    );
};
