import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
    MDXEditor,
    MDXEditorMethods,
    // ** Plugins **
    headingsPlugin,
    imagePlugin,
    quotePlugin,
    listsPlugin,
    thematicBreakPlugin,
    toolbarPlugin,
    linkDialogPlugin,
    linkPlugin,
    markdownShortcutPlugin,
    tablePlugin,
    diffSourcePlugin,
    // ** Tollbar Components **
    UndoRedo,
    BoldItalicUnderlineToggles,
    CodeToggle,
    CreateLink,
    InsertImage,
    InsertTable,
    DiffSourceToggleWrapper,
    InsertFrontmatter,
    frontmatterPlugin,
    InsertThematicBreak,
    ListsToggle,
    Separator,
    BlockTypeSelect,
    directivesPlugin,
    AdmonitionDirectiveDescriptor,
    codeBlockPlugin,
    codeMirrorPlugin,
    SandpackConfig,
    StrikeThroughSupSubToggles,
    InsertCodeBlock,
    InsertAdmonition
} from '@mdxeditor/editor';
import { v4 } from 'uuid';
import { storageApiUrl } from 'modules/client/itvecturaClient';
import { metaStore } from 'utils/store/MetaStore';
import { useNotifications } from 'utils/hooks';
import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
// import { getFileUrlForDownload } from 'smart/utils';

import '@mdxeditor/editor/style.css';
import './MarkdownEditor.scss';
import { useStoreNavigate } from 'utils/store';
import { useLocation, useNavigate } from 'react-router-dom';

interface MarkdownEditorProps {
    readOnly?: boolean;
    disableToolbar?: boolean;
    meta?: string;
    mode?: string;
    // readOnlyDiff?: boolean;
    value: string | undefined;
    fileUploadPath?: string;
    fileUploadBucket?: string;
    onChange?: (newValue: string | undefined) => void;
}

function getFileExtension(filename: string): string {
    const parts = filename.split('.');

    return parts.length > 1 ? parts.pop() : '';
}

export const MarkdownEditor: React.FC<MarkdownEditorProps> = ({
    readOnly,
    disableToolbar,
    // readOnlyDiff,
    fileUploadBucket = 'public',
    fileUploadPath,
    value = '',
    meta,
    mode,
    onChange
}) => {
    const editor = useRef<MDXEditorMethods>(null);
    const initialValue = useRef(value);
    const location = useLocation();

    const { t } = useTranslation();
    const { notification } = useNotifications();
    const navigate = useStoreNavigate();
    const oldNavigate = useNavigate();

    // console.log(value, editor.current, readOnly, disableToolbar);
    // console.log(meta, value);

    useEffect(() => {
        // console.log(value, meta);
        // if (meta) editor.current?.setMarkdown?.(value);
        editor.current?.setMarkdown?.(value);
    }, [meta, mode]);

    useEffect(() => {
        const element = document.querySelector('.markdown_editor__content');
        const handleClick = (event: MouseEvent) => {
            const target = event.target as HTMLElement;

            // console.log('1', target);

            const isAnchorA = target.tagName === 'A';
            const isAnchorSpanWithAParent =
                target.tagName === 'SPAN' && target.parentElement?.tagName === 'A';

            // Проверяем, что клик произошел по элементу `a`
            if (isAnchorA || isAnchorSpanWithAParent) {
                const anchor = isAnchorSpanWithAParent
                    ? (target.parentElement as HTMLAnchorElement)
                    : (target as HTMLAnchorElement);

                // console.log('2');
                // Проверяем, что родительский элемент имеет класс `markdown_editor__content`
                if (anchor.closest('.markdown_editor__content')) {
                    const href = anchor.href;

                    // console.log('3', href);
                    // Проверяем, что ссылка соответствует текущему `origin`
                    if (href.startsWith(window.location.origin)) {
                        // console.log('4');
                        event.preventDefault(); // Отключаем дефолтное поведение
                        const relativePath = href.replace(window.location.origin, '');
                        if (location.pathname.includes('notes') && relativePath.includes('notes')) {
                            // const lastPathNameElem = relativePath.split('/').at(-1);
                            // const isClearNotesPath = lastPathNameElem === 'notes';
                            // console.log('dadadasd');
                            oldNavigate(
                                {
                                    // pathname: isClearNotesPath
                                    //     ? relativePath
                                    //     : relativePath.split('/').slice(0, -1).join('/')
                                    pathname: relativePath
                                },
                                {
                                    // state: isClearNotesPath
                                    state: { pageTitle: 'notes' }
                                    //     ? { pageTitle: t('notes') }
                                    //     : { pageTitle: t('notes'), meta: lastPathNameElem }
                                }
                            );
                        } else if (
                            location.pathname.includes('changelog') &&
                            relativePath.includes('changelog')
                        ) {
                            // const lastPathNameElem = relativePath.split('/').at(-1);
                            // const isClearNotesPath = lastPathNameElem === 'changelog';
                            // oldNavigate({ pathname: relativePath }, { state: t('changelog'), meta: location.state.meta });
                            oldNavigate(
                                {
                                    // pathname: isClearNotesPath
                                    //     ? relativePath
                                    //     : relativePath.split('/').slice(0, -1).join('/')
                                    pathname: relativePath
                                },
                                {
                                    // state: isClearNotesPath
                                    //     ? { pageTitle: t('changelog') }
                                    //     : { pageTitle: t('changelog'), meta: lastPathNameElem }
                                    state: { pageTitle: 'changelog' }
                                }
                            );
                        } else {
                            navigate(relativePath);
                        }
                    }
                }
            }
        };

        // Добавляем обработчик события
        element?.addEventListener('click', handleClick);

        // Убираем обработчик при размонтировании компонента
        return () => {
            element?.removeEventListener('click', handleClick);
        };
    }, [navigate]);

    const ALL_PLUGINS = [
        toolbarPlugin({
            toolbarClassName: 'markdown_editor__toolbar',
            // toolbarContents: () => <KitchenSinkToolbar />
            toolbarContents: () => (
                <>
                    <UndoRedo />
                    <Separator />
                    <BoldItalicUnderlineToggles />
                    <CodeToggle />
                    <Separator />
                    <StrikeThroughSupSubToggles />
                    <Separator />
                    <ListsToggle />
                    <Separator />

                    {/* <ChangeAdmonitionType /> */}
                    <BlockTypeSelect />

                    <Separator />

                    <CreateLink />
                    <InsertImage />

                    <Separator />

                    <InsertTable />
                    <InsertThematicBreak />

                    <Separator />
                    <InsertCodeBlock />
                    {/* <InsertSandpack /> */}

                    <Separator />
                    <InsertAdmonition />

                    <Separator />
                    <InsertFrontmatter />

                    <DiffSourceToggleWrapper
                        options={readOnly ? ['rich-text'] : ['rich-text', 'diff', 'source']}
                        children={null}
                    />
                </>
            )
        }),
        listsPlugin(),
        quotePlugin(),
        headingsPlugin(),
        linkPlugin(),
        linkDialogPlugin({
            linkAutocompleteSuggestions: ['https://itvectura.com', 'https://itvectura.ru']
        }),
        imagePlugin({
            imageUploadHandler: async (file) => {
                try {
                    if (typeof file === 'string')
                        throw new Error(t('file_uncorrect_type') as string);

                    // Проверяем, что файл выбран
                    if (!file) {
                        throw new Error(t('file_not_choosen') as string);
                    }

                    const initialName = `${file.name}`;
                    let techName = v4();

                    const ext = getFileExtension(initialName);
                    if (ext) {
                        techName += `.${ext}`;
                    }

                    Object.defineProperty(file, 'name', {
                        // value: translitRu(file.name)
                        value: techName
                    });

                    file.initial_name = initialName;

                    let filePath = '';
                    if (fileUploadPath) {
                        filePath = `${fileUploadPath}/`;
                    }
                    if (file.name) {
                        filePath += file.name;
                    }

                    const uploadFN = metaStore?.api?.client?.storage?.upload;

                    if (uploadFN) {
                        const { data, error } = await uploadFN(fileUploadBucket, filePath, file);

                        // Если ошибка, то бросаем исключение
                        if (error && !data?.path) throw error;
                        if (!data?.path) throw new Error(t('file_upload_error') as string);

                        // return getFileUrlForDownload(fileUploadBucket, data.path as string);
                        // return { storageApiUrl, bucket: fileUploadBucket, path: data?.path };
                        // return `${storageApiUrl}${
                        //     storageApiUrl.endsWith('/') ? '' : '/'
                        // }${fileUploadBucket}/${data.path}`;
                        const api = window.env.BACKEND_API_URL;
                        const endpoint = '/storage/';
                        const storageApiUrl = `${api}${endpoint}`;

                        return `${storageApiUrl}${fileUploadBucket}/${data.path}`;
                    }

                    // else
                    throw new Error(t('storage_is_undefiend') as string);
                } catch (error) {
                    notification.error({
                        message: t('file_upload_error') as string,
                        description: makeErrorReadable((error as Error).message),
                        duration: 0
                    });

                    // eslint-disable-next-line no-console
                    console.error((error as Error).message); // точно будет message, так как явно его передаем выше
                    throw error;
                }
                // return Promise.resolve('https://picsum.photos/200/300');
            }
        }),
        tablePlugin(),
        thematicBreakPlugin(),
        frontmatterPlugin(),
        codeBlockPlugin({ defaultCodeBlockLanguage: '' }),
        // sandpackPlugin({ sandpackConfig: virtuosoSampleSandpackConfig }),
        codeMirrorPlugin({
            codeBlockLanguages: {
                js: 'JavaScript',
                css: 'CSS',
                txt: 'Plain Text',
                tsx: 'TypeScript',
                '': 'Unspecified'
            }
        }),
        directivesPlugin({
            directiveDescriptors: [AdmonitionDirectiveDescriptor]
        }),
        diffSourcePlugin({
            diffMarkdown: initialValue?.current
        }),
        markdownShortcutPlugin()
    ];

    return (
        <div>
            <MDXEditor
                ref={editor}
                markdown={value}
                onChange={onChange}
                readOnly={readOnly}
                translation={t}
                contentEditableClassName="markdown_editor__content"
                plugins={ALL_PLUGINS}
                // plugins={[
                //     headingsPlugin(),
                //     quotePlugin(),
                //     listsPlugin(),
                //     thematicBreakPlugin(),
                //     linkPlugin(),
                //     markdownShortcutPlugin(),
                //     tablePlugin(),
                //     frontmatterPlugin(),
                //     directivesPlugin({ directiveDescriptors: [AdmonitionDirectiveDescriptor] }),
                //     linkDialogPlugin({
                //         linkAutocompleteSuggestions: [
                //             'https://itvectura.com',
                //             'https://itvectura.ru'
                //         ]
                //     }),
                //     diffSourcePlugin({
                //         diffMarkdown: initialValue?.current
                //     }),
                //     imagePlugin({
                //         imageUploadHandler: async (file) => {
                //             try {
                //                 if (typeof file === 'string')
                //                     throw new Error(t('file_uncorrect_type') as string);

                //                 // Проверяем, что файл выбран
                //                 if (!file) {
                //                     throw new Error(t('file_not_choosen') as string);
                //                 }

                //                 const initialName = `${file.name}`;
                //                 let techName = v4();

                //                 const ext = getFileExtension(initialName);
                //                 if (ext) {
                //                     techName += `.${ext}`;
                //                 }

                //                 Object.defineProperty(file, 'name', {
                //                     // value: translitRu(file.name)
                //                     value: techName
                //                 });

                //                 file.initial_name = initialName;

                //                 let filePath = '';
                //                 if (fileUploadPath) {
                //                     filePath = `${fileUploadPath}/`;
                //                 }
                //                 if (file.name) {
                //                     filePath += file.name;
                //                 }

                //                 const uploadFN = metaStore?.api?.client?.storage?.upload;

                //                 if (uploadFN) {
                //                     const { data, error } = await uploadFN(
                //                         fileUploadBucket,
                //                         filePath,
                //                         file
                //                     );

                //                     // Если ошибка, то бросаем исключение
                //                     if (error && !data?.path) throw error;
                //                     if (!data?.path)
                //                         throw new Error(t('file_upload_error') as string);

                //                     // return getFileUrlForDownload(fileUploadBucket, data.path as string);
                //                     // return { storageApiUrl, bucket: fileUploadBucket, path: data?.path };
                //                     return `${storageApiUrl}${
                //                         storageApiUrl.endsWith('/') ? '' : '/'
                //                     }${fileUploadBucket}/${data.path}`;
                //                 }

                //                 // else
                //                 throw new Error(t('storage_is_undefiend') as string);
                //             } catch (error) {
                //                 notification.error({
                //                     message: t('file_upload_error') as string,
                //                     description: makeErrorReadable((error as Error).message),
                //                     duration: 0
                //                 });

                //                 // eslint-disable-next-line no-console
                //                 console.error((error as Error).message); // точно будет message, так как явно его передаем выше
                //                 throw error;
                //             }
                //             // return Promise.resolve('https://picsum.photos/200/300');
                //         }
                //     }),
                //     toolbarPlugin({
                //         toolbarClassName: 'markdown_editor__toolbar',
                //         toolbarContents: () => (
                //             <>
                //                 <UndoRedo />

                //                 <Separator />

                //                 <BoldItalicUnderlineToggles />
                //                 <CodeToggle />

                //                 <Separator />

                //                 <BlockTypeSelect />

                //                 <Separator />

                //                 <ListsToggle />

                //                 <Separator />

                //                 <CreateLink />
                //                 <InsertImage />

                //                 <Separator />

                //                 <InsertTable />
                //                 <InsertThematicBreak />

                //                 <Separator />

                //                 <InsertFrontmatter />

                //                 <DiffSourceToggleWrapper
                //                     options={
                //                         readOnly ? ['rich-text'] : ['rich-text', 'diff', 'source']
                //                     }
                //                     children={null}
                //                 />
                //             </>
                //         )
                //     })
                // ]}
            />
        </div>
    );
};
