import { useContext, useEffect } from 'react';
import { Card, ConfigProvider, Flex, Typography } from 'antd';

// import ITVecturaFullIcon from 'assets/icons/logo_itvectura_full.png';
// import { ReactComponent as ITVecturaTextIcon } from 'assets/icons/logo-text.svg';
import { useTranslation } from 'react-i18next';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import { useLogos } from 'App';
import Logo from 'assets/icons/logo_itvectura_full.png';
import { useAsync, useAsyncRetry, useDebounce, useMedia } from 'react-use';
import { use100vh } from 'react-div-100vh';
import { useHandlerRun } from 'smart/utils/hooks';
import { LazyIcon } from 'smart/ui';
import { useUserData } from 'modules/client/useAuthUser';
import { InfoUser } from 'modules/services/backend-api/generated_models';
import { AppSearch } from '../../../modules/layouts/DefaultLayout/components/Header/components';
import { springboardStore, useStoreNavigate } from '../../../utils/store';
import { fnv1aHash } from '../../utils';

import './Springboard.scss';
import { metaStore } from '../../../utils/store/MetaStore';
import { makeDeepMerge } from 'utils/helpers/makeDeepMerge';
import { Loader } from 'ui/Loader/Loader';

export const Springboard = observer(() => {
    const {
        i18n: { language }
    } = useTranslation();
    // const [favorites] = useSpringboardFavorites();
    const [logos] = useLogos();
    const favorites = toJS(springboardStore.favorites);
    const storeNavigate = useStoreNavigate();
    const isBigMobile = useMedia('(max-width: 480px)');
    const config = useContext(ConfigProvider.ConfigContext);
    const { theme } = config;
    const colorPrimary = theme?.token?.colorPrimary;
    const logoLarge = theme?.token?.logoLarge;
    const { data: user } = useUserData();

    const height = use100vh();
    const hundredVh = height ? `${height}px` : '100vh';
    const { run } = useHandlerRun();

    // const logo = useAsync(async () => {
    //     if (!logoLarge) return undefined;

    //     return getFileUrlForDownload('public', logoLarge);
    // }, []);

    const menuItems = metaStore.meta.get('all')?.menu?.items;

    useEffect(() => {
        const initialItems = toJS(menuItems?.filter((m) => m.is_on_springboard)) ?? [];

        for (const initialItem of initialItems) {
            springboardStore.push(initialItem);
        }
    }, [menuItems]);

    const addParamActionId = useAsync(async () => {
        const select = await metaStore.makeSelect({
            meta: 'InfoHandlers',
            filters: 'Code=eq.AddParam',
            page_size: 1,
            page: 1
        });

        const {
            objects: [{ Code, Id }]
        } = select;

        const get = await metaStore.makeSelect({
            meta: 'InfoActions',
            filters: `Handler=eq.${Code}`
            // id: Id
        });

        // console.log(get);

        return get.objects?.[0]?.Id;
    }, []);

    // console.log(favorites);

    const { loading, retry } = useAsyncRetry(async () => {
        const items = await metaStore.getParam({
            param_name: 'SPRINGBOARD_ITEMS',
            default_value: []
        });

        // console.log('FROM', items);

        const final = [
            ...items,
            ...(toJS(menuItems?.filter((m) => m.is_on_springboard)) ?? [])
        ].reduce((acc, current) => {
            if (
                !acc.some(
                    (item) =>
                        (item.id && current.id && item.id === current.id) ||
                        (item.Id && current.Id && item.Id === current.Id)
                )
            ) {
                acc.push(current);
            }
            return acc;
        }, []);

        springboardStore.set(final ?? []);
    }, []);

    useDebounce(
        async () => {
            if (addParamActionId.value && user) {
                await run(
                    {
                        Action_Id: addParamActionId.value,
                        meta: 'InfoUsers',
                        ids: [(user as InfoUser).Id],
                        handler: 'AddParam',
                        args: {
                            parameter_code: { Code: 'SPRINGBOARD_ITEMS' },
                            parameter_value: favorites
                        }
                    },
                    true
                );
            }
        },
        2000,
        [favorites.length]
    );

    return (
        <Flex
            vertical
            className={'springboard'}
            style={{ height: `calc(${hundredVh} - 100px)` }}
            align={'center'}
            gap={20}
        >
            <Flex
                vertical
                align={'center'}
                justify={'flex-end'}
                gap={20}
                style={{ width: '100%', height: '40%' }}
            >
                <div className={'springboard__logo_container'}>
                    <img
                        className={'springboard__logo'}
                        src={logos.large ? logoLarge : Logo}
                        style={{
                            width: '100%',
                            height: '100%'
                        }}
                        alt={'logo_large'}
                    />
                </div>
                <AppSearch style={{ width: isBigMobile ? '100%' : '50%' }} isOnSpringboard />
            </Flex>
            <Loader status={loading}>
                <Flex gap={10} justify={'center'} wrap={'wrap'} style={{ width: '100%' }}>
                    {favorites.map((data) => {
                        const id = data.id ?? data.Id;
                        const label: string =
                            data.name?.[language] ?? data.PluralName?.[language] ?? data.Code;
                        const [pathname, filterString] = (
                            data.path ?? `/other/${data?.Code}`
                        ).split('?');
                        const cacheKey = fnv1aHash(`${id}_${label}`);

                        const state = filterString
                            ? {
                                  filterString,
                                  cacheKey,
                                  pageTitle: data.name ?? data.PluralName ?? data.Code
                              }
                            : {
                                  cacheKey,
                                  pageTitle: data.name ?? data.PluralName ?? data.Code
                              };

                        const metaIcon = data.Icon ? <LazyIcon icon={data.Icon as string} /> : null;
                        const menuIcon = data.icon ? <LazyIcon icon={data.icon as string} /> : null;

                        return (
                            <Card
                                key={data.Id}
                                hoverable
                                className={'springboard__card'}
                                onClick={() => storeNavigate({ pathname }, { state })}
                                classNames={{
                                    cover: 'springboard__card_cover',
                                    body: 'springboard__card_body'
                                }}
                                cover={
                                    <Flex
                                        align={'center'}
                                        justify={'center'}
                                        style={{ '--color-primary': colorPrimary }}
                                        className={'springboard__card_cover_inner'}
                                    >
                                        {menuIcon ||
                                            metaIcon ||
                                            label
                                                ?.trim()
                                                .split(' ')
                                                .slice(0, 2)
                                                .map((word) => word?.charAt(0).toUpperCase())
                                                .join('')}
                                    </Flex>
                                }
                            >
                                <Card.Meta
                                    description={
                                        <Typography.Text
                                            className={'springboard__card_description'}
                                            ellipsis
                                        >
                                            {data.name?.[language] ?? data?.PluralName?.[language]}
                                        </Typography.Text>
                                    }
                                />
                            </Card>
                        );
                    })}
                </Flex>
            </Loader>
        </Flex>
    );
});
