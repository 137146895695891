import { MenuDataItem, ProConfigProvider, ProLayout } from '@ant-design/pro-components';
import {
    BellOutlined,
    InfoCircleOutlined,
    SearchOutlined,
    TranslationOutlined,
    UserOutlined
} from '@ant-design/icons';
import { ConfigProvider, Flex } from 'antd';
// import ruRu from 'antd/locale/ru_RU';
import { useNavigate } from 'react-router-dom';
import Logo from 'assets/icons/logo-vectura.svg';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useBoolean } from 'react-use';
import { MenuItem } from '../../services/backend-api/generated_api';
import { metaStore } from '../../../utils/store/MetaStore';
import { LazyIcon } from '../../../smart/ui';
import { fnv1aHash } from '../../../smart/utils';
import { Footer } from '../DefaultLayout/components';
import { modalStores } from '../../../utils/store';
import { useAppVersion } from '../../../utils/hooks/useAppVersion';

function transformMenu(menuItems: MenuItem[], language: string): MenuDataItem[] {
    const itemsById = {};

    console.log(menuItems);

    for (const menuItem of menuItems) {
        menuItem.children = [];
        itemsById[menuItem.id] = menuItem;
    }
    const tree = [];

    for (const menuItem of menuItems) {
        const treeItem = {
            ...menuItem,
            name: menuItem.name[language],
            icon: <LazyIcon icon={menuItem.icon} />,
            key: menuItem.id
        };

        if (menuItem.parent_id) {
            const parent = itemsById[menuItem.parent_id];
            if (parent) {
                parent.children.push(treeItem);
            }
        } else {
            tree.push(treeItem);
        }
    }

    console.log(tree);

    return tree;
    // const menuMap: { [key: string]: MenuDataItem } = {};
    // const roots: MenuDataItem[] = [];
    //
    // console.log(menuItems);
    //
    // menuItems.forEach((item) => {
    //     const pathParts = item.path.split('/').filter(Boolean);
    //     let currentPath = '';
    //     let currentLevel = roots;
    //
    //     pathParts.forEach((part, index) => {
    //         currentPath += `/${part}`;
    //         let menuItem = menuMap[currentPath];
    //
    //         if (!menuItem) {
    //             menuItem = {
    //                 path: currentPath,
    //                 key: item.id + index,
    //                 children: []
    //             };
    //             menuMap[currentPath] = menuItem;
    //             currentLevel.push(menuItem);
    //         }
    //
    //         if (index === pathParts.length - 1) {
    //             menuItem.name = item.name.ru;
    //             menuItem.icon = <LazyIcon icon={item.icon} />;
    //         }
    //
    //         currentLevel = menuItem.children!;
    //     });
    // });
    //
    // return roots;
}

export const ProDefaultLayout = observer<React.PropsWithChildren>(({ children }) => {
    const navigate = useNavigate();
    // const {
    //     pathname,
    //     state: { filterString }
    // } = useLocation();
    const {
        i18n: { language }
    } = useTranslation();
    const appVersion = useAppVersion();

    const flatMenuItems = metaStore.meta.get('all')?.menu?.items;

    const [activeMenu, setActiveMenu] = useState<string>();
    const [collapsed, setCollapsed] = useBoolean(false);

    // const actionRef = useRef<{
    //     reload: () => void;
    // }>();

    // const [settings, setSetting] = useState<Partial<ProSettings> | undefined>({
    //     layout: 'mix',
    //     navTheme: 'light'
    // });

    // console.log(`${pathname}${filterString ? `?${filterString}` : ''}`);

    return (
        <ProConfigProvider hashed={false}>
            <ConfigProvider
                // locale={enUS}
                getTargetContainer={() => {
                    return document.getElementById('app') || document.body;
                }}
            >
                <ProLayout
                    title={'ITVectura'}
                    logo={Logo}
                    // pure={false}
                    // loading={false}
                    // location={window.location}
                    // menuHeaderRender={() => <></>}
                    // menuFooterRender={() => <span>v4.0.0alpha-1</span>}
                    menuFooterRender={() => (
                        <Flex
                            // className="aside__app-version"
                            onClick={() => {
                                modalStores.changelog.open();
                            }}
                            justify="flex-end"
                        >
                            {appVersion}
                        </Flex>
                    )}
                    onMenuHeaderClick={() => navigate('/')}
                    // menuExtraRender={() => <></>}
                    // onTopMixMenuHeaderClick={() => {}} // ???
                    // contentStyle={{ background: 'red' }}
                    layout={'mix'} // layout menu mode
                    contentWidth={'Fluid'}
                    fixedHeader={true}
                    // fixSiderbar={false}
                    breakpoint={'lg'} // triggers for resonsive layout
                    // actionRef={actionRef} // actions ref. for loading from server maybe
                    menu={{
                        // locale: true,
                        defaultOpenAll: false,
                        autoClose: true,
                        request: async () => {
                            const menu = (await metaStore.getMenu())?.items;
                            console.log(menu);
                            return transformMenu(menu, language);
                        } // fetch menu from server
                    }}
                    // iconfontUrl={} // config for iconfont (URL)
                    // iconPrefixes={'icon-'} // icon prefix of side menu, class name
                    // locale={'en-US'}
                    // locale={'ru-RU'}
                    // settings={settings} // настройки layout
                    siderWidth={300}
                    // suppressSiderWhenMenuEmpty={true} // hide side then menu is empty
                    defaultCollapsed={true}
                    collapsed={collapsed} // Controls the collapse and expansion of the menu
                    onCollapse={setCollapsed}
                    // onPageChange={(location) => {
                    //     console.log(location);
                    // }}
                    // headerRender={() => {
                    //     return <> </>;
                    // }} // Custom header render method
                    // headerTitleRender={() => {}} // Custom header title method, works in mix mode and top mode
                    // headerContentRender={() => {}} // Custom header content methods
                    // collapsedButtonRender={() => {}} // Custom method for collapsed button
                    footerRender={() => (
                        <Footer
                            style={{
                                position: 'fixed',
                                bottom: 0,
                                // marginLeft: calcMarginLeftByScreenSize(),
                                width: `calc(100% - ${collapsed ? 64 : 300}px)`,
                                border: '1px solid rgba(5, 5, 5, 0.06)',
                                boxShadow: 'none'
                            }}
                        />
                    )} // Custom render method for footer
                    // pageTitleRender={() => {}} // The render method for custom page titles
                    // menuRender={() => {}} // The render method for custom menus
                    // postMenuData={(menuData) => {
                    //     console.log(menuData);
                    //     return menuData ?? [];
                    // }} // View the menu data before displaying it, changes will not trigger a re-render
                    // menuItemRender={}
                    // subMenuItemRender={}
                    // menuDataRender={}
                    breadcrumbRender={(route) => route}
                    route={{
                        path: '/'
                        // routes: [
                        //     {
                        //         path: '/tasks',
                        //         name: 'Tasks',
                        //         icon: <CheckSquareOutlined />,
                        //         routes: []
                        //         // component: './Welcome',
                        //     },
                        //     {
                        //         path: '/trips',
                        //         name: 'Trips',
                        //         icon: <ScheduleOutlined />
                        //         // component: './Welcome',
                        //     }
                        // ]
                    }}
                    // location={{
                    //     computedMatch: {
                    //         pathname,
                    //         key: activeMenu
                    //     }
                    // }}
                    disableMobile={false}
                    // ErrorBoundary={true}
                    // links={[
                    //     <Typography.Link
                    //         href={'https://itvectura.ru'}
                    //         target={'_blank'}
                    //         children={
                    //             <>
                    //                 <LinkOutlined /> About company
                    //             </>
                    //         }
                    //     />
                    // ]}
                    menuProps={{
                        // activeKey: activeMenu,
                        // selectable: true,
                        onClick: ({ key }) => {
                            setActiveMenu(key);
                            if (flatMenuItems) {
                                const menuItem = flatMenuItems.find((item) => item.id === key);

                                if (menuItem) {
                                    const [pathname, filterString] = menuItem.path.split('?');
                                    // const name = menuItem.name?.[language];
                                    const name = menuItem.name;

                                    navigate(
                                        { pathname },
                                        {
                                            state: {
                                                filterString,
                                                pageTitle: name,
                                                cacheKey: fnv1aHash(
                                                    `${menuItem.id}_${name?.[language]}`
                                                )
                                            }
                                        }
                                    );
                                }
                            }
                        }
                    }}
                    // waterMarkProps={{ content: 'sdaasd' }}
                    actionsRender={(props) => {
                        if (props.isMobile) return [];
                        if (typeof window === 'undefined') return [];
                        return [
                            props.layout !== 'side' ? <SearchOutlined /> : undefined,
                            <UserOutlined key={'profile'} />,
                            <TranslationOutlined key={'language'} />,
                            <BellOutlined key="notifications" />,
                            <InfoCircleOutlined key="info" />
                            // <LogoutButton />
                            // <LogoutOutlined key='logout' />,
                        ];
                    }}
                    // {...settings}
                >
                    {children}

                    {/* <SettingDrawer */}
                    {/*    pathname={pathname} */}
                    {/*    enableDarkTheme */}
                    {/*    getContainer={(e: Element) => { */}
                    {/*        if (typeof window === 'undefined') return e; */}
                    {/*        return document.getElementById('app'); */}
                    {/*    }} */}
                    {/*    settings={settings} */}
                    {/*    onSettingChange={(changeSetting) => { */}
                    {/*        setSetting(changeSetting); */}
                    {/*    }} */}
                    {/*    disableUrlParams={true} */}
                    {/* /> */}
                </ProLayout>
            </ConfigProvider>
        </ProConfigProvider>
    );
});
