import { useCallback, useEffect, useState } from 'react';

interface UseTabsProps {
    meta: string;
    id: string;
}

export const useTabs = ({ meta, id }: UseTabsProps): [string, (key: string) => void] => {
    const [activeTabsKey, setActiveTabsKey] = useState({
        [meta]: { [id]: 'common_info' }
    });

    const setActiveTab = useCallback(
        (activeKey: string) => {
            setActiveTabsKey((prev) => ({
                ...prev,
                [meta]: { ...prev[meta], [id]: activeKey }
            }));
        },
        [id, meta]
    );

    const activeTab = activeTabsKey[meta]?.[id];

    useEffect(() => {
        if (!activeTabsKey[meta]?.[id]) {
            setActiveTabsKey((prev) => ({
                ...prev,
                [meta]: { ...prev[meta], [id]: 'common_info' }
            }));
        }
    }, [activeTabsKey, id, meta]);

    return [activeTab, setActiveTab];
};
