import { EditOutlined, EyeOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Flex, FormItemProps, MenuProps, Skeleton, TableProps } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import { ColumnType } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAsync, useMedia, useWindowSize } from 'react-use';
import { createCachedSelector } from 're-reselect';

import { MetaField } from 'modules/services/backend-api/generated_info';
import {
    // checkAndMakeTreeData,
    buildTreeWithGrouping,
    fnv1aHash,
    getDetailPageTitle,
    getExcludeFieldsPattern,
    parseValueType
} from 'smart/utils';
// import { EmptyMarker } from 'ui';
import { useBaseLanguage } from 'smart/utils/hooks';
import { Loader } from 'ui/Loader/Loader';
// import { toPascalCase } from 'utils/helpers/toPascalCase';
import { i18n, LANGUAGES } from 'utils/i18n/i18n';
import { useStoreNavigate } from 'utils/store';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';

import { EditableCell, EditableRow } from './components';
import { fieldEditRender, fieldRender } from './helpers';
import './SmartTable.scss';
// import { useVirtualComponents } from './helpers/hooks';

// import { Table as BaseTable } from './BaseTable';
import { getFieldProperties, getMetaAndRouteProperties } from './helpers/fieldRender';
// import { ViewCell } from './components/ViewCell/ViewCell';

// const Table = lazy(() => import('antd').then((module) => ({ default: module.Table })));
const BaseTable = lazy(() => import('./BaseTable').then((module) => ({ default: module.Table })));

const MobileView = lazy(() =>
    import('./components').then((module) => ({ default: module.MobileView }))
);
const RowModal = lazy(() =>
    import('./components').then((module) => ({ default: module.RowModal }))
);

type TColumnType = ColumnType<IObjectWithId>;

interface EditableOptionsType {
    onRow?: TableProps['onRow'];
    components?: TableProps['components'];
}

type FieldRenderFunction = (value: any, row: IObjectWithId, rowIndex: number) => React.Node;

export interface SmartTableProps {
    meta: string;
    fields?: MetaField[];
    data: IObjectWithId[];
    setData?: (
        updater: IObjectWithId[] | ((prevValue: IObjectWithId[]) => IObjectWithId[])
    ) => void;
    loading?: boolean;
    selectedRows?: IObjectWithId[];
    onRowSelectionChange?: (selectedRows: IObjectWithId[]) => void;
    titleRender?: () => React.ReactNode;
    footerRender?: () => React.ReactNode;
    mobileCardToolbarRender?: (row: IObjectWithId) => React.ReactNode;
    // fixedFields?: { left?: string[]; right?: string[] };
    leftFixedField?: string;
    virtual?: boolean;
    selectable?: boolean;
    selectionType?: 'checkbox' | 'radio';
    layout?: 'auto' | 'fixed';
    editable?: boolean;
    clickable?: boolean;
    doubleClickable?: boolean;
    onDoubleClick?: 'openRowModal' | ((row: any, index?: number) => void);
    validation?: { rules: FormItemProps['rules'] };
    defaultSortOrder?: {
        descend?: string[];
        ascend?: string[];
    };
    viewMode?: 'inline' | 'modal';
    rowContextMenuItems?: MenuProps['items'];
    cellContextMenuItems?: (Partial<ItemType> & {
        onClick: (...args: any[]) => () => void;
    })[];
    rootMeta?: string;
    rootData?: IObjectWithId;
    components?: TableProps['components'];
    scroll?: TableProps['scroll'];

    noDataRenderer?: () => React.ReactNode;
    fieldsRenderOverwrite?: Record<string, FieldRenderFunction>;
}

const rowsKeyColumnName = 'Id';

export const columnFilterRule = (column: TColumnType, index: number, columns: TColumnType[]) => {
    if (!column?.dataIndex || typeof column?.dataIndex !== 'string') return false;

    return getExcludeFieldsPattern(column, columns, { replaceFieldNameKey: 'dataIndex' });
};

const tableEditableComponents: TableProps['components'] = {
    body: {
        row: EditableRow,
        cell: EditableCell
    }
};

const getColumnSorterRule = (columnName: string, valueType?: string) => (a: any, b: any) => {
    let valA = a[columnName];
    let valB = b[columnName];

    // Чтобы обрабатывались пустые значения
    if (typeof valA === 'object' && typeof valB === 'undefined') {
        valB = {};
    }
    if (typeof valB === 'object' && typeof valA === 'undefined') {
        valA = {};
    }

    if (typeof valA === 'string' && typeof valB === 'undefined') {
        valB = '';
    }
    if (typeof valB === 'string' && typeof valA === 'undefined') {
        valA = '';
    }

    if (valA && valB && typeof valA === 'object' && typeof valB === 'object') {
        if (columnName.includes('Status')) {
            // обработка статусов
            valA = valA.Status?.Code ?? valA.Code ?? '';
            valB = valB.Status?.Code ?? valB.Code ?? '';
        } else if (['DisplayName', 'PluralName', 'Name', 'Description'].includes(columnName)) {
            // обработка тайтлов
            valA = valA[i18n.language] ?? '';
            valB = valB[i18n.language] ?? '';
        } else if (['Meta'].includes(columnName)) {
            // обработка меты
            valA = valA.Code ?? '';
            valB = valB.Code ?? '';
        } else {
            // обработка рефов
            valA =
                valA.DisplayName?.[i18n.language] ??
                valA.PluralName?.[i18n.language] ??
                valA.Name?.[i18n.language] ??
                valA.Key ??
                '';
            valB =
                valA.DisplayName?.[i18n.language] ??
                valB.PluralName?.[i18n.language] ??
                valB.Name?.[i18n.language] ??
                valB.Key ??
                '';
        }
    }

    if (typeof valA === 'string' && typeof valB === 'string') {
        if (valueType?.includes('key')) {
            const keyNumA = Number(valA.split('-')[1]);
            const keyNumB = Number(valB.split('-')[1]);
            return keyNumA - keyNumB;
        }

        // удивительно что работает без этого
        // видимо он понимает что идет сравнение по числам
        // и кажется если будут разные часовые пояса можно поймать не правильную сортировку
        // т.е. утверждаю что сравнение вот этих двух
        // 2024-06-27T12:54:23.682865+03:00
        // 2024-06-27T12:54:23.682865-05:00
        // даст не правильный результат

        // if (valueType?.includes('datetime')) {
        //     const valADate = new Date(valA);
        //     const valBDate = new Date(valB);

        //     if (date1 > date2) {
        //         return 1; // timestamp1 is later than timestamp2
        //       } else if (date1 < date2) {
        //         return -1; // timestamp1 is earlier than timestamp2
        //       } else {
        //         return 0; // timestamps are equal
        //       }
        // }
        return valA.localeCompare(valB);
    }

    if (typeof valA === 'number' && typeof valB === 'number') {
        return valA - valB;
    }

    if (typeof valA === 'boolean' && typeof valB === 'boolean') {
        return Number(valA) - Number(valB);
    }

    return 0;
};

// const tableViewComponents: TableProps['components'] = {
//     body: {
//         row: ViewRow
//         // cell: ViewCell
//     }
// };

function extractRefValue(input: string): string | null {
    const refPrefix = 'ref:';
    const refIndex = input.indexOf(refPrefix);
    if (refIndex === -1) {
        return null; // 'ref:' not found in the input string
    }
    const startIndex = refIndex + refPrefix.length;
    // Find the index of the next '.' after 'ref:'
    let endIndex = input.indexOf('.', startIndex);
    if (endIndex === -1) {
        // If no '.' is found, consider the rest of the string
        endIndex = input.length;
    }
    // Extract the substring between 'ref:' and the next '.'
    return input.substring(startIndex, endIndex);
}

const defaultFixedFields = { left: ['PluralName', 'Name', 'Key', 'Code'], right: [] };

export const SmartTable = observer<SmartTableProps>(
    ({
        meta,
        data: tableData,
        setData,
        fields, // FIXME: зачем передавать сюда fields если они доступны как meta.Fields
        titleRender, // TODO: это в каком-то производном компоненте должно быть наверное а базовая таблица наверное без этого? RE: прпос для отрисовки футера и тайтла находится в базовом компоненте
        footerRender, // TODO: это в каком-то производном компоненте должно быть наверное а базовая таблица наверное без этого? RE: прпос для отрисовки футера и тайтла находится в базовом компоненте
        selectedRows,
        onRowSelectionChange,
        // fixedFields = { left: ['PluralName', 'ShortTitle', 'Name', 'Key', 'Code'] },
        leftFixedField, // тут не понятно почему это строка а не boolean? как строка определяет фиксацию? RE: это название филда фиксации
        loading,
        validation, // TODO: это в каком-то производном компоненте - для detail page
        onDoubleClick,
        rowContextMenuItems,
        // cellContextMenuItems,
        // FIXME: надо навести порядок в пропсах - тут не нужны и meta+data и rootMeta+rootData? базовая таблица не лезет в root - она просто рендерит тексты/ссылки. мы и не лезем в root, ничего не запрашиваем. это нужно для работы контекстов
        // призводная таблица для detail page может иметь value и вот ей действительно нужно rootMeta+rootData
        rootMeta,
        rootData,
        components, // TODO: что здесь за компоненты? // RE: переопределение стандартных компонентов antd.
        scroll,
        mobileCardToolbarRender,
        defaultSortOrder = { descend: ['CreatedAt'] },
        selectable = true,
        clickable = true,
        doubleClickable = false,
        editable = false, // TODO: это только в производной таблице для detail page
        virtual = false,
        selectionType = 'checkbox',
        layout = 'fixed',
        fieldsRenderOverwrite,
        viewMode = 'inline', // TODO: а это не в производных компонентах нужно?,
        noDataRenderer
    }) => {
        const {
            t,
            i18n: { language }
        } = useTranslation();
        const location = useLocation();
        const storeNavigate = useStoreNavigate();
        const { height: windowHeight } = useWindowSize();
        const isBigMobile = useMedia('(max-width: 480px)');
        const baseLanguage = useBaseLanguage();

        const isMobileView = (metaStore.meta.get(meta)?.info?.IsMobileView ?? true) && isBigMobile;

        const fieldsSource = useMemo(() => {
            return fields ?? (metaStore.meta.get(meta)?.info?.Fields || []);
        }, [fields, meta]);

        const refMetasData = useAsync(async () => {
            if (fieldsSource.length) {
                const refMetas = Array(
                    ...fieldsSource
                        .filter((f) => !f.IsHiddenOnTable && f.ValueType?.includes('ref'))
                        .reduce((set, f) => {
                            set.add(extractRefValue(f.ValueType ?? ''));

                            return set;
                        }, new Set())
                        .values()
                ).filter((meta) => meta && !metaStore.meta.get(meta)?.info);

                return Promise.all(refMetas?.map((meta) => metaStore.getInfo(meta)));
            }

            return Promise.reject();

            // console.log(refMetas);
        }, [fieldsSource]);

        // console.log(refMetasData);

        const fieldsMap = useMemo(() => {
            return fieldsSource.reduce((map, item) => {
                map.set(item.FieldName, item);
                return map;
            }, new Map<string, MetaField>());
        }, [fieldsSource]);

        const groupFields = useMemo(
            () =>
                fieldsSource.filter(
                    (field) =>
                        (field.ValueType?.includes('is_group') || field.Options?.is_group) &&
                        !field?.ValueType?.includes(meta)
                ),
            // .reverse(),
            [fieldsSource, meta]
        );

        const parentFieldName = useMemo(() => {
            return (
                fieldsSource.find(
                    (gf) =>
                        gf?.ValueType?.includes(meta) &&
                        (gf.ValueType?.includes('is_group') || gf.Options?.is_group)
                )?.FieldName ?? 'Parent'
            );
        }, [fieldsSource, meta]);

        const fixedFirstColumnDataIndex = useMemo(() => {
            const fixedFields = leftFixedField
                ? { left: [...defaultFixedFields.left, leftFixedField] }
                : defaultFixedFields;

            return fixedFields.left?.find((fixed) =>
                fieldsSource.map((f) => f.FieldName).includes(fixed)
            );
        }, [fieldsSource, leftFixedField]);

        const treeData = useMemo(() => {
            // console.log(tableData);
            const result = buildTreeWithGrouping(
                tableData,
                parentFieldName,
                groupFields.map((f) => f.FieldName),
                fixedFirstColumnDataIndex ?? 'Name'
            );
            // console.log(result);
            return result;
            // return checkAndMakeTreeData({
            //     data: tableData,
            //     groupKeys: groupFields.map((gf) => gf.FieldName),
            //     parentField: parentFieldName,
            //     viewFieldName: fixedFirstColumnDataIndex ?? 'Name'
            // });
        }, [tableData, groupFields, fixedFirstColumnDataIndex, parentFieldName]);

        const [rowModalOpen, setRowModalOpen] = useState(false);
        const [rowModalData, setRowModalData] = useState<any>({});

        const handleDataSourceChange = useCallback(
            (row: IObjectWithId) => {
                console.log('[SmartTable] handleChange row:', row);

                if (setData) {
                    setData((prevData) => {
                        const newData = [...prevData];

                        const changedIndex = newData.findIndex((dataRow) => dataRow.Id === row.Id);

                        if (changedIndex >= 0) {
                            newData[changedIndex] = {
                                ...newData[changedIndex],
                                ...row
                            };
                        }
                        return newData;
                    });
                }
            },
            [setData]
        );

        const getInitialRowSorting = useCallback(() => {
            const res = {} as { [keys: string]: 'ascend' | 'descend' | undefined };

            defaultSortOrder?.descend?.forEach((fieldName) => {
                res[fieldName] = 'descend';
            });
            defaultSortOrder?.ascend?.forEach((fieldName) => {
                res[fieldName] = 'ascend';
            });

            return res;
        }, [defaultSortOrder?.ascend, defaultSortOrder?.descend]);

        const [rowSorting, setRowSorting] = useState<{
            [keys: string]: 'ascend' | 'descend' | undefined;
        }>();

        useEffect(() => {
            if (!rowSorting || !Object.keys(rowSorting).length) {
                setRowSorting(getInitialRowSorting());
            }
        }, [getInitialRowSorting, rowSorting]);

        const [selectedData, setSelectedData] = useState<IObjectWithId[]>([]);

        const handleSelectRow = useCallback(
            (selectedRows: IObjectWithId[]) => {
                if (onRowSelectionChange) onRowSelectionChange(selectedRows);
                else setSelectedData(selectedRows);
            },
            [onRowSelectionChange]
        );

        const getSelectedRowKeys = useCallback(() => {
            return (selectedRows || selectedData).map((row) => row[rowsKeyColumnName]);
        }, [selectedData, selectedRows]);

        // ### ВЫДЕЛЕННЫЕ СТРОКИ и их опции
        // const rowSelection: TableProps<IObjectWithId>['rowSelection'] = useMemo(
        //     () => ({
        //         type: selectionType,
        //         columnWidth: 50,
        //         fixed: 'left',
        //         selectedRowKeys: getSelectedRowKeys(),
        //         onChange: (selectedRowKeys: React.Key[], selectedRows: IObjectWithId[]) => {
        //             if (groupFields.length) {
        //                 handleSelectRow(selectedRows.filter((row) => !row.isHardGroup)); // строки собранные из полей для группировки
        //             } else {
        //                 handleSelectRow(selectedRows);
        //             }
        //         }
        //         // checkStrictly: !treeData?.some((row) => row.isHardGroup)
        //     }),
        //     [selectionType, getSelectedRowKeys, groupFields, handleSelectRow]
        // );

        const getHandleClickRow = useCallback(
            (row: IObjectWithId) => {
                if (!(selectable && clickable)) return undefined;

                return () => {
                    const selectedIndex = getSelectedRowKeys().findIndex(
                        (key) => key === row[rowsKeyColumnName]
                    );

                    if (selectedIndex === -1) handleSelectRow([row]);
                    else {
                        handleSelectRow([]);
                    }
                };
            },
            [selectable, clickable, getSelectedRowKeys, handleSelectRow]
        );

        const getHandleRightClickRow = useCallback(
            (row: IObjectWithId) => {
                if (!(rowContextMenuItems && clickable)) return undefined;

                return (open: boolean) => {
                    if (treeData.length) {
                        handleSelectRow([row]);
                        // не обнулять выбранные строки, поскольку при выборе контекстного меню спадают выбранные строки
                    }
                };
            },
            [clickable, rowContextMenuItems, handleSelectRow, treeData.length]
        );

        const getHandleCtrlClickRow = useCallback(
            (row: IObjectWithId) => {
                if (!(selectable && clickable)) return undefined;

                return () => {
                    const selectedIndex = getSelectedRowKeys().findIndex(
                        (key) => key === row[rowsKeyColumnName]
                    );

                    if (selectedIndex === -1)
                        handleSelectRow([...(selectedRows || selectedData), row]);
                    else {
                        handleSelectRow([
                            ...(selectedRows || selectedData).slice(0, selectedIndex),
                            ...(selectedRows || selectedData).slice(selectedIndex + 1)
                        ]);
                    }
                };
            },
            [selectable, clickable, getSelectedRowKeys, handleSelectRow, selectedData, selectedRows]
        );

        const getHandleShiftClick = useCallback(
            (row: IObjectWithId) => {
                if (!(selectable && clickable)) return undefined;

                return () => {
                    const lastSelectedKey = getSelectedRowKeys().at(-1);
                    const firstSelectedKey = getSelectedRowKeys().at(0);
                    const currentKey = row[rowsKeyColumnName];

                    const allRows = tableData;

                    const startReverse = allRows.findIndex((item) => {
                        return item[rowsKeyColumnName] === lastSelectedKey;
                    });

                    const start = allRows.findIndex((item) => {
                        return item[rowsKeyColumnName] === firstSelectedKey;
                    });

                    const end = allRows.findIndex((item) => item[rowsKeyColumnName] === currentKey);

                    if (start > -1 && end > -1 && startReverse > -1) {
                        if (end > startReverse) handleSelectRow([...allRows.slice(start, end + 1)]);
                        else if (end < start) {
                            handleSelectRow([...allRows.slice(end, startReverse + 1)]);
                        } else {
                            handleSelectRow([...allRows.slice(start, end + 1)]);
                        }
                    }
                };
            },
            [selectable, clickable, tableData, getSelectedRowKeys, handleSelectRow]
        );

        const getHandleOpenRowModal = useCallback(
            (row: IObjectWithId) => () => {
                setRowModalData(row);
                setRowModalOpen(true);
            },
            []
        );

        const getHandleDoubleClick = useCallback(
            (row: IObjectWithId, index: number) => {
                if (!doubleClickable || row.isHardGroup) return undefined;

                return onDoubleClick
                    ? onDoubleClick === 'openRowModal'
                        ? getHandleOpenRowModal(row)
                        : () => onDoubleClick(row, index)
                    : () => {
                          const metaRoutes = metaStore.meta.get('all')?.routes;
                          let metaDetailRoute = metaRoutes?.find(
                              (route) =>
                                  route.meta === meta &&
                                  route.path ===
                                      `${location.pathname}${
                                          location.state?.filterString
                                              ? `?${location.state?.filterString}`
                                              : ''
                                      }`
                          );

                          if (!metaDetailRoute) {
                              metaDetailRoute = metaRoutes?.find((route) => route.meta === meta);
                          }

                          const pathname = metaDetailRoute?.path.split('?')[0];
                          const filterString = metaDetailRoute?.path.split('?')[1];

                          const tunedPath = pathname ?? `/other/${meta}` ?? location.pathname;

                          if (row.Id) {
                              const { pageTitle, extraPageTitle } = getDetailPageTitle({
                                  pathname: tunedPath,
                                  state: { filterString },
                                  data: row,
                                  meta
                              });

                              const state = {
                                  ...location.state,
                                  cacheKey: fnv1aHash(`${meta}_${row.Id}_view`),
                                  data: row,
                                  pageTitle,
                                  extraPageTitle
                              };

                              storeNavigate(
                                  { pathname: `${tunedPath}/${row.Id}`, search: '' },
                                  { state }
                              );
                          }
                      };
            },
            [doubleClickable, location.pathname, location.state, meta, storeNavigate]
        );

        // const cachedLeftPinned = useRef<string | null>(null);

        // будет закрепляться СЛЕВА только первая визуально доступная колонка из fixedField
        const getFixed = useCallback(
            (dataIndex: string) => {
                if (isBigMobile) return undefined;

                const fixedFields = leftFixedField
                    ? { ...defaultFixedFields, left: [leftFixedField, ...defaultFixedFields.left] }
                    : defaultFixedFields;

                let fixed: 'left' | 'right' | undefined;

                if (fixedFields) {
                    if (dataIndex === fixedFirstColumnDataIndex) {
                        fixed = 'left';
                    } else if (fixedFields.right?.includes(dataIndex)) fixed = 'right';
                }

                return fixed;
            },
            [isBigMobile, fixedFirstColumnDataIndex, leftFixedField]
        );

        // ### КОЛОНКИ, которые формируем по данным из меты (info)
        // TODO: а зачем мы делаем memo на колонки? мне кажется надо обеспечить чтобы вся таблица не рендерилась сверху 5 раз как сейчас - тогда и колонки не будут рендериться
        // сейчас получается мы колонки закрыли от ререндера но вся иерархия почему-то реренедерит всю таблицу. т.е. мы не корневую проблему побороли а одно неприятное следствие
        const columns = useMemo(() => {
            const columns: TColumnType[] = [];

            // for (const field of fieldsSource.sort(sortByChildIndexRule)) {
            // for (const field of fieldsSource) {
            if (!refMetasData.loading && !refMetasData.error) {
                for (let i = 0; i < fieldsSource.length; i++) {
                    const field = fieldsSource[i];

                    const isCreatedAt = field.FieldName === 'CreatedAt';
                    const isIndicator = field.FieldName === 'Indicator';
                    if (field.IsHiddenOnTable && !isCreatedAt) continue;
                    // if (field.IsHiddenOnTable) continue;

                    const dataIndex = field.FieldName;
                    const key = field.ColumnName || dataIndex;
                    const readOnly = field.IsReadOnly;
                    const defaultWidth = dataIndex.includes('Name') ? 300 : 200;
                    const columnWidth = field.Options?.UIWidth ?? defaultWidth;

                    const title = field.Name
                        ? field.Name[language] || t(key || dataIndex)
                        : t(key || dataIndex);

                    let addMethod: 'push' | 'unshift' = 'push';
                    if (dataIndex === fixedFirstColumnDataIndex) {
                        addMethod = 'unshift';
                    }
                    const isViewAsLink = viewMode !== 'inline';

                    const { type, options } = parseValueType(
                        { valueType: field.ValueType, Options: field.Options },
                        language
                    );

                    const metaAndRoutes = getMetaAndRouteProperties(
                        options,
                        language,
                        field,
                        isViewAsLink
                    );

                    const fieldProperties = getFieldProperties(type, field, options, isViewAsLink);

                    const renderFunction = fieldRender({
                        field,
                        language: language as LANGUAGES,
                        baseLanguage,
                        fields: fieldsSource,
                        isViewAsLink: viewMode !== 'inline',
                        rootMeta,
                        rootDataSource: rootData,
                        fieldsMap,
                        options,
                        type,
                        fieldProps: fieldProperties,
                        metaAndRouteProps: metaAndRoutes
                    });

                    // TODO: подумать над оптимизацией
                    let render =
                        dataIndex === fixedFirstColumnDataIndex &&
                        fieldsMap.has('TotalDocumentsCount')
                            ? (value: any, row: IObjectWithId, rowIndex: number) => {
                                  //   console.log('RERENDER??');

                                  return (
                                      <Flex justify="space-between" style={{ width: '100%' }}>
                                          {renderFunction(value, row, rowIndex)}

                                          {row.TotalDocumentsCount > 0 && (
                                              <span>
                                                  {row.TotalDocumentsCount}
                                                  <PaperClipOutlined />
                                              </span>
                                          )}
                                      </Flex>
                                  );
                              }
                            : renderFunction;

                    if (fieldsRenderOverwrite && fieldsRenderOverwrite[field.FieldName]) {
                        render = fieldsRenderOverwrite[field.FieldName];
                    }

                    columns[addMethod]({
                        isOnMobile: field.Options?.IsOnMobileTable,
                        layoutArea: field.LayoutArea,
                        width: columnWidth,
                        className: 'smart_table__column',
                        key,
                        dataIndex,
                        title,
                        // shouldCellUpdate: (record, prevRecord) => {
                        //     return false;
                        // },
                        hidden: isIndicator || (isCreatedAt && field.IsHiddenOnTable),
                        ellipsis: true,
                        // ### сортировка ###
                        sortOrder: rowSorting?.[dataIndex],
                        sorter: {
                            compare: getColumnSorterRule(dataIndex, field.ValueType),
                            // @ts-ignore
                            getCompare: () => {
                                return getColumnSorterRule(dataIndex, field.ValueType);
                            }
                            // multiple: i
                        },
                        // sortDirections: ['descend', 'ascend', 'descend'],
                        // ### закреп ###
                        fixed: getFixed(dataIndex),
                        // ### передаем пропсы для компонента ячейки ###
                        onCell:
                            editable && viewMode === 'inline' && !readOnly
                                ? (row: IObjectWithId, rowIndex?: number) => ({
                                      row,
                                      rowIndex,
                                      dataIndex,
                                      title,
                                      editable,
                                      validation,
                                      onTableDataChange: (rowValue: any) => {
                                          handleDataSourceChange(rowValue);
                                      },
                                      render: !row.isHardGroup // строки собранные из полей для группировки
                                          ? fieldEditRender({
                                                field,
                                                language,
                                                fields: fieldsSource,
                                                rootMeta,
                                                rootDataSource: rootData
                                            })
                                          : fieldRender({
                                                field,
                                                language: language as LANGUAGES,
                                                baseLanguage,
                                                fields: fieldsSource,
                                                isViewAsLink: true,
                                                rootMeta,
                                                rootDataSource: rootData,
                                                fieldsMap
                                            })
                                  })
                                : undefined,
                        // ### рендерим ячейки (просмотр) ###
                        render
                    });
                }

                if (viewMode === 'modal') {
                    columns.push({
                        width: 50,
                        className: 'smart_table__column',
                        key: editable ? 'edit' : 'view',
                        dataIndex: editable ? 'Edit' : 'View',
                        title: '',
                        // ### закреп ###
                        fixed: 'right',
                        // ### рендерим ячейки ###
                        render: (_, row) =>
                            editable && !row.isHardGroup ? ( // строки собранные из полей для группировки
                                <EditOutlined
                                    style={{ marginTop: '3.5px', display: 'block' }}
                                    onClick={getHandleOpenRowModal(row)}
                                />
                            ) : (
                                <EyeOutlined
                                    style={{ marginTop: '3.5px', display: 'block' }}
                                    onClick={getHandleOpenRowModal(row)}
                                />
                            )
                    });
                }
                // }
            }
            console.log('[SmartTable] table columns:', columns);

            return columns.filter(columnFilterRule);
        }, [
            refMetasData.loading,
            refMetasData.error,
            fieldsSource,
            viewMode,
            language,
            t,
            rowSorting,
            getFixed,
            editable,
            validation,
            rootMeta,
            handleDataSourceChange,
            fixedFirstColumnDataIndex
        ]);

        const getCustomRowProps = useCallback<NonNullable<TableProps['onRow']>>(
            createCachedSelector(
                (row) => row,
                (row: any, index: number | undefined) => index,
                (row, index) => {
                    // console.log('CALL');
                    return {
                        row,
                        index,
                        virtual,
                        contextMenuItems: rowContextMenuItems,
                        onDoubleClick: getHandleDoubleClick(row, index),
                        onRightClick: getHandleRightClickRow(row),
                        onClick: getHandleClickRow(row),
                        onCtrlClick:
                            selectionType === 'checkbox'
                                ? getHandleCtrlClickRow(row)
                                : getHandleClickRow(row),
                        onShiftClick:
                            selectionType === 'checkbox'
                                ? getHandleShiftClick(row)
                                : getHandleClickRow(row)
                    };
                }
            )((row, index) => {
                // console.log('CACHE', index);
                return index;
            }),
            [
                getHandleClickRow,
                getHandleCtrlClickRow,
                getHandleDoubleClick,
                getHandleRightClickRow,
                getHandleShiftClick,
                rowContextMenuItems,
                selectionType,
                virtual
            ]
        );

        // ### Найстройки для РЕЖИМА РЕДАКТИРОВАНИЯ
        // const editableOptions = useMemo(() => {
        //     const options: EditableOptionsType = {};

        //     if (editable && viewMode === 'inline') {
        //         options.onRow = getCustomRowProps;
        //         options.components = components ?? tableEditableComponents;
        //     }

        //     return options;
        // }, [editable, viewMode, getCustomRowProps, components]);

        // const tableRef = useRef(null);

        // const scrollOptions = useMemo(() => {
        //     const y = windowHeight - 22 * 13.48;
        //     return {
        //         y,
        //         x: columns.length * 200,
        //         ...scroll
        //     };
        // }, [columns.length, windowHeight, scroll]);

        // const handleSort = useCallback<NonNullable<TableProps['onChange']>>(
        //     (pagination, filters, sorter) => {
        //         if (sorter.order === undefined) {
        //             setRowSorting({ CreatedAt: 'descend' });
        //         } else {
        //             setRowSorting({ [sorter.field]: sorter.order });
        //         }
        //     },
        //     []
        // );

        // const { value: useReactBaseTableParam, loading: useTableLoading } = useAsync(async () => {
        //     const useReactBaseTable = metaStore.meta.get('all')?.params?.USE_REACT_BASE_TABLE;

        //     if (useReactBaseTable) return useReactBaseTable;

        //     return metaStore.getParam({
        //         param_name: 'USE_REACT_BASE_TABLE',
        //         default_value: false
        //     });
        // }, []);

        // const c = useVirtualComponents();

        // console.log('components:', components);
        // if (useTableLoading) {
        //     return <Loader />;
        // }
        // const tblRef: Parameters<typeof Table>[0]['ref'] = React.useRef(null);
        return (
            <>
                {!isMobileView ? (
                    <Suspense fallback={<Skeleton />}>
                        {/* {useReactBaseTableParam ? ( */}
                        <BaseTable
                            selectionType={selectionType}
                            columns={columns}
                            className="smart_table"
                            // data={treeData}
                            data={tableData}
                            footerRender={footerRender}
                            titleRender={titleRender}
                            loading={loading || refMetasData.loading}
                            selectable={selectable}
                            selectedRows={selectedRows}
                            defaultSortOrder={defaultSortOrder}
                            onRowSelectionChange={onRowSelectionChange}
                            doubleClickable={doubleClickable}
                            onDoubleClick={getHandleDoubleClick}
                            editable={editable}
                            handleDataSourceChanged={handleDataSourceChange}
                            scroll={scroll}
                            groupFields={groupFields}
                            parentFieldName={parentFieldName}
                            fixedFirstColumnDataIndex={fixedFirstColumnDataIndex}
                            rowContextMenuItems={rowContextMenuItems}
                            noDataRenderer={
                                noDataRenderer ||
                                (() => {
                                    return (
                                        <div style={{ padding: 20, textAlign: 'center' }}>
                                            {t('no_data')}
                                        </div>
                                    );
                                })
                            }

                            // noDataRenderer={
                            //     components?.body
                            //         ? () => {
                            //               return components?.body;
                            //           }
                            //         : undefined
                            // }
                            // components.b
                        />
                        {/* ) : (
                            <>
                                <Table
                                    // ref={tableRef}
                                    className="smart_table"
                                    // ### статус загрузки ###
                                    loading={
                                        loading || refMetasData.loading
                                            ? {
                                                  spinning: loading || refMetasData.loading,
                                                  indicator: <Loader />,
                                                  delay: 100
                                              }
                                            : loading || refMetasData.loading
                                    }
                                    // ### данные таблицы ###
                                    expandable={{
                                        defaultExpandAllRows: false
                                        // defaultExpandedRowKeys: tableData.map(
                                        //     (item) => item[rowsKeyColumnName] || ''
                                        // )
                                    }}
                                    // columns={columns.filter(columnFilterRule)}
                                    columns={columns}
                                    dataSource={treeData}
                                    rowKey={rowsKeyColumnName}
                                    // ### внешний вид таблицы ##
                                    tableLayout={layout}
                                    size="small"
                                    bordered
                                    // ref={tblRef}
                                    // ### выбор строки ###
                                    rowSelection={selectable ? rowSelection : undefined}
                                    // ### виртуализация ###
                                    pagination={false}
                                    // virtual={true}
                                    // virtual={components ? false : virtual} // если извне переопределяем компоненты, то отключаем виртуализацию TODO: подумать
                                    onChange={handleSort}
                                    showSorterTooltip={false}
                                    // ### рамер тела таблицы ###
                                    scroll={scrollOptions}
                                    // ### тулбары ###
                                    title={titleRender}
                                    footer={footerRender}
                                    locale={{ emptyText: <EmptyMarker size="small" noImage /> }}
                                    // virtual={true}
                                    // components={components ?? tableViewComponents}
                                    // TODO: зачем у нас onDoubleClick и в Row и components.row ? это разве не про должно и то же? RE: Нет. в компоненты пропсы попадают через onRow
                                    components={components ?? c}
                                    onRow={getCustomRowProps}
                                    // ### РЕЖИМ РЕДАКТИРОВАНИЯ
                                    // TODO: надо убрать из этого компонета  - это только в производном для Detail page может быть
                                    {...editableOptions}
                                />
                            </>
                        )} */}
                    </Suspense>
                ) : (
                    <Suspense fallback={<Skeleton />}>
                        <MobileView
                            data={tableData}
                            columns={columns.filter(
                                (c) => c.isOnMobile
                                // c.isOnMobile ||
                                // c.dataIndex === 'Key' ||
                                // c.dataIndex === 'Code' ||
                                // c.dataIndex === 'Name' ||
                                // c.dataIndex === 'ShortTitle' ||
                                // c.dataIndex === 'PluralName' ||
                                // c.dataIndex === 'Type' ||
                                // c.dataIndex === 'DateAt'
                            )}
                            titleRender={titleRender}
                            footerRender={footerRender}
                            loading={loading}
                            getCustomRowProps={getCustomRowProps}
                            // selectedRows={selectedRows || selectedData}
                            cardToolbarRender={mobileCardToolbarRender}
                            height={scroll?.y ?? windowHeight - 22 * 9 - 2}
                        />
                    </Suspense>
                )}

                {viewMode === 'modal' && (
                    <Suspense fallback={<Skeleton />}>
                        <RowModal
                            rootMeta={rootMeta}
                            rootDataSource={rootData}
                            open={rowModalOpen}
                            data={rowModalData}
                            setData={setRowModalData}
                            metaFields={fields}
                            onClose={() => {
                                setRowModalData({});
                                setRowModalOpen(false);
                            }}
                            onOk={() => {
                                handleDataSourceChange(rowModalData);
                                setRowModalData({});
                                setRowModalOpen(false);
                            }}
                            mode={editable ? 'edit' : 'view'}
                        />
                    </Suspense>
                )}
            </>
        );
    }
);
