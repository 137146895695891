import { observer } from 'mobx-react-lite';
import Timeline from 'react-calendar-timeline';

import 'react-calendar-timeline/lib/Timeline.css';
import { Flex, Segmented } from 'antd';
import { useMemo, useState } from 'react';
import moment from 'moment';
import { useAsyncRetry } from 'react-use';
import { useTranslation } from 'react-i18next';
import { metaStore } from '../../../utils/store/MetaStore';
import { RegDriverSlot } from '../../../modules/services/backend-api/generated_models';

import './CalendarTimeline.scss';

// TODO: 1. поменять moment на dayjs

export const CalendarTimeline = observer(() => {
    const {
        i18n: { language }
    } = useTranslation();

    const [viewMode, setViewMode] = useState<'drivers' | 'vehicles'>('drivers');

    const bookedFleetIntervals = useAsyncRetry(async () => {
        const res = await metaStore.makeSelect({ meta: 'RegBookedFleetIntervals' });

        console.log('RegBookedFleetIntervals', res);
    }, [viewMode]);

    // ### DRIVERS ###
    const driverSlots = useAsyncRetry(async () => {
        if (viewMode === 'drivers') {
            const res = await metaStore.makeSelect({ meta: 'RegDriverSlots' });

            console.log('RegDriverSlots', res);

            return res.objects as RegDriverSlot[];
        }

        return undefined;
    }, [viewMode]);

    const driverGroups = useMemo(() => {
        if (driverSlots.value) {
            const seen = new Set();
            return driverSlots.value
                .filter((slot) => {
                    if (seen.has(slot.Driver?.Id)) return false;

                    seen.add(slot.Driver?.Id);
                    return true;
                })
                .map((slot) => {
                    const { Driver } = slot;

                    const id = Driver?.Id;
                    const title = Driver?.Name?.[language] ?? Driver?.Code ?? Driver?.Key;

                    return { id, title };
                });
        }

        return [];
    }, [driverSlots.value, language]);

    const driverItems = useMemo(() => {
        if (driverSlots.value && driverGroups.length) {
            return driverSlots.value.map((slot) => {
                const { SlotFromAt, SlotToAt, Id, Driver } = slot;

                return {
                    id: Id,
                    group: Driver?.Id,
                    title: null,
                    start_time: moment(SlotFromAt),
                    end_time: moment(SlotToAt),
                    canMove: false,
                    canResize: false,
                    canChangeGroup: false,
                    itemProps: {
                        // эти необязательные атрибуты передаются в корень <div /> каждого элемента как <div {...itemProps} />
                        'data-custom-attribute': 'Случайный контент',
                        'aria-hidden': true,
                        // onDoubleClick: () => {
                        //     console.log('Вы нажали дважды!');
                        // },
                        // onContextMenu: () => {
                        //     console.log('Контекстное меню!');
                        // },
                        className: 'free_interval',
                        style: {
                            background: 'white',
                            border: '1px solid #B8CBDD54',
                            pointerEvents: 'none' /* Заблокировать клики */,
                            zIndex: 99,
                            color: 'black'
                        }
                    }
                };
            });
        }

        return [];
    }, [driverGroups.length, driverSlots.value]);
    // !### DRIVERS ###

    // ### VEHICLES ###
    const vehicleSlots = useAsyncRetry(async () => {
        if (viewMode === 'vehicles') {
            const res = await metaStore.makeSelect({ meta: 'RegVehicleSlots' });

            console.log('RegVehicleSlots', res);

            return res.objects;
        }

        return undefined;
    }, [viewMode]);

    const vehicleGroups = useMemo(() => {
        if (vehicleSlots.value) {
            const seen = new Set();
            return vehicleSlots.value
                .filter((slot) => {
                    if (seen.has(slot.Vehicle?.Id)) return false;

                    seen.add(slot.Vehicle?.Id);
                    return true;
                })
                .map((slot) => {
                    const { Vehicle } = slot;

                    const id = Vehicle?.Id;
                    const title = Vehicle?.Name?.[language] ?? Vehicle?.Code ?? Vehicle?.Key;

                    return { id, title };
                });
        }

        return [];
    }, [vehicleSlots.value, language]);
    // !### VEHICLES ###

    const vehicleItems = useMemo(() => {
        if (vehicleSlots.value && vehicleGroups.length) {
            return vehicleSlots.value.map((slot) => {
                const { SlotFromAt, SlotToAt, Id, Vehicle } = slot;

                return {
                    id: Id,
                    group: Vehicle?.Id,
                    title: null,
                    start_time: moment(SlotFromAt),
                    end_time: moment(SlotToAt),
                    canMove: false,
                    canResize: false,
                    canChangeGroup: false,
                    itemProps: {
                        // эти необязательные атрибуты передаются в корень <div /> каждого элемента как <div {...itemProps} />
                        'data-custom-attribute': 'Случайный контент',
                        'aria-hidden': true,
                        // onDoubleClick: () => {
                        //     console.log('Вы нажали дважды!');
                        // },
                        // onContextMenu: () => {
                        //     console.log('Контекстное меню!');
                        // },
                        className: 'free_interval',
                        style: {
                            background: 'white',
                            border: '1px solid #B8CBDD54',
                            pointerEvents: 'none' /* Заблокировать клики */,
                            zIndex: 99,
                            color: 'black'
                        }
                    }
                };
            });
        }

        return [];
    }, [vehicleGroups.length, vehicleSlots.value]);

    return (
        <div className={'calendar_timeline'}>
            <Flex align="flex-start" gap={5} wrap="wrap" className={'calendar_timeline__toolbar'}>
                <Flex
                    align="center"
                    justify="flex-start"
                    className={'calendar_timeline__type_selector'}
                >
                    <Segmented
                        className={'calendar_timeline__type_selector_inner'}
                        block
                        // id="timeline_table_date_interval"
                        options={['drivers', 'vehicles']}
                        value={viewMode}
                        onChange={setViewMode}
                    />
                </Flex>
            </Flex>
            <Timeline
                groups={viewMode === 'drivers' ? driverGroups : vehicleGroups}
                items={viewMode === 'drivers' ? driverItems : vehicleItems}
                // *** дефолтный стартовый диапазон открытый на таймлайне
                defaultTimeStart={moment().add(-12, 'hour')}
                defaultTimeEnd={moment().add(12, 'hour')}
                // *** точный диапазон показываемый на таймлайне
                // visibleTimeStart={}
                // visibleTimeEnd={}
                // onTimeChange={} // в таком случае, нужно управлять скроллом с помощью этой функции
                // *** горизонтальная виртуализация, типа overscan
                buffer={1} // если 1, то откл. прокрутка
                // *** управление выбранными (по умолчанию выбирается один элемент при клике)
                // selected={}
                // onItemSelect={}
                // onItemDeselect={}
                // *** управление кастомным неймингом ключей в объекте данных (для групп и их айтемов)
                // keys={{
                //     groupIdKey: 'id',
                //     groupTitleKey: 'title',
                //     groupRightTitleKey: 'rightTitle',
                //     itemIdKey: 'id',
                //     itemTitleKey: 'title', // ключ для содержимого div элемента
                //     itemDivTitleKey: 'title', // ключ для заголовка div элемента (<div title="text"/>)
                //     itemGroupKey: 'group',
                //     itemTimeStartKey: 'start_time',
                //     itemTimeEndKey: 'end_time'
                // }} // по умолчанию такой набор
                // *** стилизация
                // itemRenderer={({ item, itemContext, getItemProps, getResizeProps }) => {
                //     const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
                //     return (
                //         <div
                //             {...getItemProps(item.itemProps)}
                //             style={{
                //                 ...getItemProps(item.itemProps).style,
                //                 background: 'white',
                //                 border: '1px solid #B8CBDD54',
                //                 pointerEvents: 'none' /* Заблокировать клики */,
                //                 zIndex: 99,
                //                 color: 'black'
                //             }}
                //         >
                //             {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}
                //
                //             <div
                //                 className="rct-item-content"
                //                 style={{ maxHeight: `${itemContext.dimensions.height}` }}
                //             >
                //                 {itemContext.title}
                //             </div>
                //
                //             {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
                //         </div>
                //     );
                // }}
                verticalLineClassNamesForTime={() => ['calendar_timeline__vertical_line']}
                // className={} // для корневого элемента таймлайна
                // sidebarWidth={} // ширина левого сайдбара
                // rightSidebarWidth={} // ширина правого сайдбара
                // sidebarContent={} // можно переопределять контент сайдбара
                // rightSidebarContent={} // можно переопределять контент правого сайдбара
                lineHeight={40} // Высота одной строки в календаре в пикселях. По умолчанию30
                itemHeightRatio={1} // Какой процент высоты строки занимает элемент? По умолчанию0.65
                // minZoom={} // Наименьшее время, на которое календарь может увеличиться в миллисекундах. По умолчанию 60 * 60 * 1000(1 час)
                // maxZoom={} // Наибольшее время, на которое календарь может увеличиться в миллисекундах. По умолчанию 5 * 365.24 * 86400 * 1000(5 лет)
                // *** изменение слотов
                // dragSnap={} // Единица привязки при перетаскивании элементов. По умолчанию 15 * 60 * 1000или 15 мин. Если это так, элементы будут привязываться к интервалам 15 мин при перетаскивании.
                // minResizeWidth={20} // Минимальная ширина записи на временной шкале в пикселях, когда возможно изменение размера. Если не достигнуто, необходимо увеличить масштаб, чтобы изменить размер еще больше. По умолчанию 20.
            >
                {/* <CustomMarker date={Date.now()}> */}
                {/*    {({ styles, date }) => <div style={styles} />} */}
                {/* </CustomMarker> */}
            </Timeline>
        </div>
    );
});
