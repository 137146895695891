import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { metaStore } from 'utils/store/MetaStore';
import AceEditor from 'react-ace';

// import 'ace-builds/src-noconflict/theme-monokai';
// import 'ace-builds/src-noconflict/snippets/javascript';
import { setCompleters } from 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-typescript';

  

                            
export type JsScriptFieldProps = {
    value: string;
    setValue: Dispatch<SetStateAction<string | undefined>>;
};

const LIB_META = 'SysJSLibForHandlers';

export const JsScriptField = ({ setValue, value }: JsScriptFieldProps) => {
    // const monaco = useMonaco();

    const editorRef = useRef<AceEditor | null>(null);

    const [initialised, setInitialiased] = useState<boolean>(false);
    // const [lib, setLib] = useState

    useEffect(() => {
        const initialise = async () => {
            const cachedLib = metaStore.meta.get(LIB_META)?.select;

            if (!cachedLib) {
                await metaStore.makeSelect({ meta: LIB_META });
            }

            setInitialiased(true);
        };
        if (!initialised) {
            initialise();
        }
    }, []);

    useEffect(() => {
        // or make sure that it exists by other ways
        if (initialised) {
            const cachedLib = metaStore.meta.get(LIB_META)?.select;

            if (cachedLib && cachedLib.objects && cachedLib.objects.length > 0 && editorRef) {
                const lib = cachedLib.objects[0];

                if (lib && lib.Suggestions) {
                    const completer = {
                        getCompletions: function (editor, session, pos, prefix, callback) {
                            const completions = [...lib.Suggestions];
                            callback(null, completions);
                        }
                    };

                    setCompleters([completer]);
                }
            }
        }
    }, [initialised]);

    return (
        <AceEditor
            ref={editorRef}
            mode={'typescript'}
            theme="monokai"
            value={value}
            // onBlur={beautify}
            name="typescript-editor"
            style={{
                padding: 5,
                border: '1px solid #f0f0f0',
                borderRadius: 6,
                height: '70vh',
                width: '100%'
            }}
            enableBasicAutocompletion={true}
            enableLiveAutocompletion={true}
            enableSnippets={true}
            editorProps={{ $blockScrolling: true }}
            setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true
            }}
            fontSize="1em"
            width="100%"
            // readOnly={readOnly}
            // Enable live editing for syntax highlighting and error detection
            onChange={setValue}
        />
    );
};
