import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { parseTemplate } from 'smart/utils';

import { FilterField } from '../../components/SmartTableFilterMenu/types';
import { Filter } from '../../../../../modules/supabase/utils/supabaseClient';
import { MetaField } from '../../../../../modules/services/backend-api/generated_info';
import { useFilterToStringConverter } from './useFilterToStringConverter';
import { useUserData } from 'modules/client/useAuthUser';

export const useFilters = (preFilters: string) => {
    const { i18n } = useTranslation();
    const location = useLocation();

    const { data: userData } = useUserData();
    const converter = useFilterToStringConverter();

    const [filters, setFilters] = useState<FilterField[]>([]);

    // console.log(filters);

    const filtersFromState = useMemo(() => {
        const stateFields = location.state?.metaFields || [];
        const stateFilters = location.state?.filters as Filter[];

        return stateFields?.map((field: MetaField) => ({
            field,
            values: stateFilters.map((f) => ({
                ...f,
                value: Array.isArray(f.value) ? f.value.map((id) => ({ Id: id })) : f.value
            }))
        }));
    }, [location]);

    const convertedFilterString = converter([...filtersFromState, ...filters]);

    const preFiltersEscapedValue = useMemo(() => {
        if (preFilters) {
            return parseTemplate(preFilters, i18n.language, {
                user: userData || undefined
            }).replaceAll('eq.undefined', 'is.null');
        }
        return preFilters;
    }, [i18n.language, preFilters, userData]);

    const filterString = convertedFilterString
        ? `${convertedFilterString}${preFiltersEscapedValue ? `&${preFiltersEscapedValue}` : ''}`
        : preFiltersEscapedValue;

    return { filters, setFilters, filterString };
};
