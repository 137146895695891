import { CopyOutlined, ExportOutlined, PaperClipOutlined } from '@ant-design/icons';
import { LabelValue, PlainObject } from '@gilbarbara/types';
import { Toast } from 'antd-mobile';
import { Button, Space, Typography } from 'antd';
import { lazy, memo, Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { useAsync, useBoolean, useEffectOnce, useMedia } from 'react-use';
import { Route } from 'modules/services/backend-api/generated_api';
import { SmartTooltip } from 'smart/ui';
import { checkAndMakeTreeData, buildTreeWithGrouping, downloadFile, fnv1aHash } from 'smart/utils';
import { Loader } from 'ui/Loader/Loader';
import { i18n } from 'utils/i18n/i18n';
import { emitter } from 'utils/emitter';
import { useStoreNavigate } from 'utils/store';

import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { SmartSelectInner } from './ui';
import { findRoute, renderOptionLabel, transformDataToOptions, useSelectData } from './utils';
import { transformFilterString } from '../../utils/transformFilterString';

import './SmartSelectField.scss';

const SmartSearchList = lazy(() =>
    import('./components').then((module) => ({ default: module.SmartSearchList }))
);

interface SmartSelectFieldProps {
    value: any | null;
    meta: string;
    onChange: (newValue: any) => void;
    popoverContainerHtmlId?: string;
    simpleMode?: boolean;
    label?: string;
    loading?: boolean;
    disabled?: boolean;
    filters?: string;
    style?: React.CSSProperties;
    size?: 'small' | 'middle' | 'large';
    placeholder?: string;
    className?: string;
    navigateMetaOptions?: {
        metaName: string;
    };
    variant?: 'filled' | 'outlined' | 'borderless';
    myViewFieldName?: string;
    isFetchOnMount?: boolean;
    treeOptions?: {
        forceDisable?: boolean;
        groupKeys?: string[];
        parentField?: string;
    };
    returnFullObject?: boolean;
    downloadOptions?: { bucket: string; filePath: string; fileName: string };
}

export const SmartSelectField = memo<SmartSelectFieldProps>(
    ({
        value,
        popoverContainerHtmlId,
        meta,
        onChange,
        label,
        loading,
        disabled,
        style,
        navigateMetaOptions,
        variant,
        treeOptions,
        size,
        placeholder,
        downloadOptions,
        isFetchOnMount = false,
        className = '',
        filters = '',
        simpleMode = false,
        myViewFieldName = ''
    }) => {
        const location = useLocation();
        const {
            t,
            i18n: { language }
        } = useTranslation();
        const navigate = useStoreNavigate();
        const {
            fetchData,
            dataSource,
            insertAtDataSource,
            metaSource,
            modalFields,
            modalTitle,
            isLoading
        } = useSelectData({
            meta,
            filters
        });

        const isBigMobile = useMedia('(max-width: 480px)');

        const isMetaReadOnly = metaSource?.IsReadOnly || false;
        const uiAllowView = metaSource?.UiAllowView ?? true;
        const uiAllowCreate = metaSource?.UiAllowCreate ?? true;
        const uiAllowSelect = metaSource?.UiAllowSelect ?? true;
        const viewFieldName = metaSource?.ViewFieldName;
        const refViewTemplate = metaSource?.RefViewTemplate;

        const [isOpenMore, setOpenMore] = useBoolean(false);
        const [searchValue, setSearchValue] = useState('');

        const isFetched = useRef(false);

        const mountFetchLoading = isFetchOnMount && !isFetched.current;

        useAsync(async () => {
            if (isFetchOnMount && value && !isFetched.current) {
                const data = await fetchData();

                if (value && typeof value === 'object' && Array.isArray(data)) {
                    const valueForRewrite = data.find((dataItem) => dataItem.Id === value.Id);

                    if (valueForRewrite) {
                        onChange(valueForRewrite);
                    }
                }

                isFetched.current = true;
            }
        }, [value]);

        useEffect(() => {
            const cb = (e: unknown) => {
                onChange(e);
                insertAtDataSource(0, e as IObjectWithId);
            };

            if (!simpleMode && !isLoading) {
                // console.log('ON', `create_${meta}_${filters}`);
                emitter.on(`create_${meta}_${filters}`, cb);
            }

            return () => emitter.off(`create_${meta}_${filters}`, cb);
        }, [isLoading, meta, onChange, simpleMode, filters]);

        const isWithMetaNavParams =
            uiAllowView &&
            navigateMetaOptions &&
            value &&
            !!findRoute(navigateMetaOptions.metaName);

        const outputOptions = useMemo<LabelValue[]>(() => {
            const viewField = myViewFieldName || viewFieldName || 'Name';

            // const treeData = checkAndMakeTreeData({
            //     data: dataSource,
            //     groupKeys: treeOptions?.groupKeys ?? [],
            //     parentField: treeOptions?.parentField ?? 'Parent',
            //     viewFieldName: viewField
            // });

            const treeData = buildTreeWithGrouping(
                dataSource,
                treeOptions?.parentField ?? 'Parent',
                treeOptions?.groupKeys ?? [],
                viewField
            );

            const options = transformDataToOptions(treeData, viewField, language, refViewTemplate);

            // const options = dataSource.map((item) => ({
            //     value: item.Code || item.Id,
            //     label: renderOptionLabel(item, myViewFieldName || viewFieldName)
            // }));
            // console.log('[SmartSelectField] options:', options);

            return options;
        }, [
            myViewFieldName,
            viewFieldName,
            dataSource,
            treeOptions?.groupKeys,
            treeOptions?.parentField,
            language,
            refViewTemplate
        ]);

        const displayValue = useMemo(() => {
            const selectedOption = outputOptions.find((opt) => value && opt.value === value.Id);

            return selectedOption ? selectedOption.label : renderOptionLabel(value, 'Name');
        }, [outputOptions, value]);

        const handleNavigate = useCallback(
            async (event: React.MouseEvent) => {
                event.stopPropagation();

                if (!navigateMetaOptions) {
                    console.warn('[SmartSelectField.handleNavigate] navigateMetaOptions is empty.');
                    return;
                }

                const needle = findRoute(navigateMetaOptions.metaName);

                if (!needle) {
                    console.warn(
                        `[SmartSelectField.handleNavigate] ${navigateMetaOptions.metaName} no found.`
                    );
                    return;
                }

                // const navId = needle.detail_route_id || value.Id;
                const navId = value.Id;

                navigate(`${needle.path?.split('?')?.[0]}/${navId}`);
            },
            [navigate, navigateMetaOptions, value?.Id]
        );

        const handleCopy = useCallback(
            async (event: React.MouseEvent) => {
                event.stopPropagation();

                window.navigator.clipboard.writeText(displayValue);

                Toast.show({
                    content: t('copied')
                });
            },
            [t, displayValue]
        );

        const handleCloseMore = useCallback(() => {
            setOpenMore(false);
        }, []);

        const handleOpenMore = useCallback(() => {
            setOpenMore(true);
        }, []);

        const handleCreate = useCallback(async () => {
            const metaRoutes = metaStore.meta.get('all')?.routesMap?.get(meta);

            let needle = metaRoutes?.[0];

            if (!needle) needle = { path: `/other/${meta}` } as Route;

            const path = needle?.path.split('?')[0];
            // const filterString = needle?.path.split('?')[1];
            const filterString = filters;

            let pathname = `${path}/new`;
            if (location.pathname.endsWith('/')) {
                pathname = `${path}new`;
            }

            const singularName =
                // metaSource?.SingularName?.[i18n.language] || t(pathname.split('/').at(-2) || ''); // get pre last pathname element for correctly displaying object context
                metaSource?.SingularName || metaSource?.Code; // get pre last pathname element for correctly displaying object context

            let preDataFromParams: PlainObject<any> = {};

            if (filterString) {
                const fields = metaSource?.Fields ?? [];
                preDataFromParams = await transformFilterString(filterString, fields, language);
            }

            const state: PlainObject<any> = {
                // ...location.state,
                cacheKey: fnv1aHash(`${meta}_new_edit`),
                // pageTitle: `${t('new')} (${singularName})`,
                pageTitle: 'new',
                extraPageTitle: singularName,
                filterString,
                data: preDataFromParams
            };

            if (metaSource?.Fields.find((f) => f.FieldName === 'IsActive')) {
                state.data.IsActive = true;
            }

            navigate({ pathname }, { state });
        }, [location.pathname, meta, metaSource, navigate, filters, language]);

        const handleDownload = useCallback(() => {
            if (downloadOptions) {
                const { bucket, filePath, fileName } = downloadOptions;

                downloadFile(bucket, filePath, fileName);
            }
        }, [downloadOptions]);

        return (
            <div style={{ width: '100%' }}>
                <Suspense fallback={<></>}>
                    <SmartSearchList
                        fields={modalFields}
                        title={modalTitle}
                        meta={meta}
                        open={isOpenMore}
                        onCancel={handleCloseMore}
                        data={dataSource}
                        initialSelectedRows={value ? [value] : []}
                        onOk={(selectedRows) => {
                            const data = selectedRows[0];
                            onChange(
                                data
                                // ? {
                                //       Id: data.Id,
                                //       Code: data.Code,
                                //       Key: data.Key,
                                //       Name: data.Name,
                                //       PluralName: data.PluralName,
                                //       DisplayName: data.DisplayName,
                                //       PropertyName: data.PropertyName,
                                //       ValueType: data.ValueType
                                //   }
                                // : undefined
                            );
                            handleCloseMore();
                        }}
                        searchFilter={searchValue}
                    />
                </Suspense>

                {label && <Typography.Text type="secondary">{label}</Typography.Text>}
                <Space.Compact className="smart_select_field" size={size}>
                    <SmartSelectInner
                        size={size}
                        searchValue={searchValue}
                        onSearch={setSearchValue}
                        style={{
                            width:
                                isWithMetaNavParams && !simpleMode
                                    ? 'calc(100% - 31px - 31px)'
                                    : value && !simpleMode
                                    ? 'calc(100% - 31px)'
                                    : '100%',
                            ...style
                        }}
                        popupClassName="smart_select_field__popup"
                        disableTree={treeOptions?.forceDisable}
                        onFocus={fetchData}
                        variant={variant}
                        loading={mountFetchLoading || loading}
                        disabled={disabled}
                        showSearch={!simpleMode}
                        className={className}
                        treeTitleRender={(item) => (
                            <SmartTooltip lineClamp={3}>{item.label ?? item.value}</SmartTooltip>
                        )}
                        value={
                            typeof displayValue === 'number' ? String(displayValue) : displayValue
                        }
                        allowClear
                        placeholder={!simpleMode && !placeholder ? t('no_value') : placeholder}
                        options={outputOptions}
                        popupMatchSelectWidth={isBigMobile ? undefined : 380}
                        onChange={(value) => {
                            const data = dataSource.find((item) => item.Id === value);
                            onChange(
                                data
                                // ? returnFullObject
                                //     ? data
                                //     : {
                                //           Id: data.Id,
                                //           Code: data.Code,
                                //           Key: data.Key,
                                //           Name: data.Name,
                                //           PluralName: data.PluralName,
                                //           DisplayName: data.DisplayName,
                                //           PropertyName: data.PropertyName,
                                //           ValueType: data.ValueType
                                //       }
                                // : undefined
                            );
                        }}
                        notFoundContent={isLoading ? <Loader size="small" /> : undefined}
                        dropdownRender={
                            !simpleMode && !isLoading
                                ? (menu) => (
                                      <>
                                          {menu}
                                          {outputOptions.length > 0 && uiAllowSelect && (
                                              <Button
                                                  size={size}
                                                  type="link"
                                                  onClick={handleOpenMore}
                                              >
                                                  {t('show_more')}
                                              </Button>
                                          )}
                                          <br />
                                          {uiAllowCreate && (
                                              <Button
                                                  size={size}
                                                  disabled={isMetaReadOnly}
                                                  type="link"
                                                  onClick={handleCreate}
                                              >
                                                  {t('create')}
                                              </Button>
                                          )}
                                      </>
                                  )
                                : undefined
                        }
                        getPopupContainer={
                            popoverContainerHtmlId
                                ? () =>
                                      document.getElementById(popoverContainerHtmlId) as HTMLElement
                                : undefined
                        }
                    />
                    {isWithMetaNavParams ? (
                        <Button
                            size={size}
                            type="default"
                            className="meta_nav_button"
                            onClick={handleNavigate}
                            icon={<ExportOutlined />}
                        />
                    ) : null}
                    {value && !simpleMode ? (
                        <Button
                            size={size}
                            type="default"
                            className="meta_copy_button"
                            onClick={handleCopy}
                            icon={<CopyOutlined />}
                        />
                    ) : null}
                    {downloadOptions ? (
                        <Button
                            size={size}
                            type="default"
                            className="meta_download_button"
                            onClick={handleDownload}
                            icon={<PaperClipOutlined />}
                        />
                    ) : null}
                </Space.Compact>
            </div>
        );
    }
);
