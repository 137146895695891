import { useCallback, useState } from 'react';

interface UseModeProps {
    meta: string;
    id: string;
    initialMode?: 'edit' | 'view';
}

type Mode = 'view' | 'edit';

export const useMode = ({
    meta,
    id,
    initialMode = 'view'
}: UseModeProps): [Mode, (mode: Mode) => void] => {
    const [mode, setMode] = useState({ [meta]: { [id]: initialMode, new: 'edit', copy: 'edit' } });

    const currentMode = (mode[meta]?.[id] || initialMode) as Mode;

    const setCurrentMode = useCallback(
        (mode: Mode) =>
            setMode((prevMode: any) => ({
                ...prevMode,
                [meta]: { ...prevMode[meta], [id]: mode }
            })),
        [id, meta]
    );

    return [currentMode, setCurrentMode];
};
