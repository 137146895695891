import { makeAutoObservable } from 'mobx';
import { DctLanguage } from 'modules/services/backend-api/generated_models';
import { metaStore } from '../MetaStore';

export class LanguagesStore {
    private api;

    languages: DctLanguage[] = [];

    current = 'ru';

    constructor() {
        makeAutoObservable(this);
        this.api = window.api;
    }

    fetch = async () => {
        if (!this.api) this.api = window.api;
        const isConnected = this.api.isConnectedAndAuthenticated();
        console.log('[LanguagesStore] fetch', isConnected);
        if (isConnected) {
            const response = await metaStore.makeSelect({ meta: 'DctLanguages' });

            if (response && response.objects.length > 0) {
                this.languages = response.objects as DctLanguage[];

                // const enableTechLanguage =
                // metaStore.meta.get('all')?.params?.ENABLE_TECHNICAL_LANGUAGE;
                if (window.env && window.env.ENABLE_TECHNICAL_LANGUAGE) {
                    // if (enableTechLanguage) {
                    this.languages.push({
                        Code: 'tech',
                        Name: { ru: 'Технический', en: 'Technical' },
                        IsActive: true
                    } as unknown as DctLanguage);
                }
            }
        } else {
            this.languages = [
                {
                    Code: 'ru',
                    Name: { ru: 'Русский', en: 'Russian' },
                    IsActive: true
                } as unknown as DctLanguage,
                {
                    Code: 'en',
                    Name: { ru: 'Английский', en: 'English' },
                    IsActive: true
                } as unknown as DctLanguage,
                {
                    Code: 'tech',
                    Name: { ru: 'Технический', en: 'Technical' },
                    IsActive: true
                } as unknown as DctLanguage
            ];
        }
    };

    setCurrent(language: string) {
        this.current = language;
    }
}

export const languagesStore = new LanguagesStore();
