import { memo, useEffect, useState } from 'react';
import { Icon as MDIcon } from '@mdi/react';
import * as AntIcon from '@ant-design/icons';
import Icon, { createFromIconfontCN } from '@ant-design/icons';
import { isDefined } from 'is-lite/exports';
import { PlainObject } from '@gilbarbara/types';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';
import * as MdiIcon from '@mdi/js';

interface LazyIconProps {
    icon?: string;
    size?: number;
    iconfontCNOptions?: {
        scriptUrl: string;
        iconType: string;
    };
    component?: IconComponentProps['component'];
    style?: React.CSSProperties;
    className?: string;
}

export const LazyIcon = memo<LazyIconProps>(
    ({ icon, size, iconfontCNOptions, component, style, className }) => {
        const antIcons = AntIcon as unknown as PlainObject<React.ReactNode>;

        // console.log(antIcons[icon])

        if (component) {
            return <Icon size={size} component={component} style={style} className={className} />;
        }

        if (iconfontCNOptions) {
            const IconFont = createFromIconfontCN({
                scriptUrl: iconfontCNOptions.scriptUrl
            });

            return (
                <IconFont
                    size={size}
                    type={iconfontCNOptions.iconType}
                    style={style}
                    className={className}
                />
            );
        }

        if (!icon) return null;

        const Elem = antIcons[icon];
        if (isDefined(Elem)) {
            // if (Icon)
            return <Elem size={size} style={style} className={className} />;

            // return null;
        }

        const elem = MdiIcon[icon];

        if (isDefined(elem)) {
            // const Icon = antIcons[icon];
            // const Icon = antIcons[icon];
            // const elem = MdiIcon[icon];
            // console.log(elem, MdiIcon);
            // if (Icon)
            return (
                <Icon
                    component={() => (
                        <MDIcon
                            path={elem.toString()}
                            size={`${size || 1}em`}
                            style={style}
                            className={className}
                        />
                    )}
                />
            );

            // return null;
        }

        // const [path, setPath] = useState();

        // useEffect(() => {
        //     import('@mdi/js').then((icons) => {
        //         if (icons[icon]) {
        //             const elem = icons[icon];
        //             // setPath(elem.toString());
        //         }
        //     });
        // }, [icon]);

        // if (path)
        //     return (
        //         <Icon
        //             component={() => (
        //                 <MDIcon
        //                     path={path}
        //                     size={`${size || 1}em`}
        //                     style={style}
        //                     className={className}
        //                 />
        //             )}
        //         />
        //     );

        return null;
    }
);
