import { useAsyncRetry, useList, useMedia } from 'react-use';
import { PlainObject } from '@gilbarbara/types';
import { toJS } from 'mobx';
import React, { useCallback, useMemo } from 'react';
import { Dropdown } from 'antd';
import { AppstoreOutlined, CaretDownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { metaStore } from '../../../../../utils/store/MetaStore';
import { LANGUAGES } from '../../../../../utils/i18n/i18n';
import { ButtonWithTooltips } from '../../../../../ui';

export const useAdvancedViewData = (meta: string, selectedRowKeys: string[]) => {
    const {
        t,
        i18n: { language }
    } = useTranslation();
    const isSmallTablet = useMedia('(max-width: 620px)');
    // const isSmallMobile = useMedia('(max-width: 390px)');
    const isBigMobile = useMedia('(max-width: 480px)');

    const hasSelected = !!selectedRowKeys.length;
    const metaData = metaStore.meta.get(meta);
    const actions = useMemo(
        () =>
            toJS(metaData?.info?.Actions)?.sort((action) =>
                action.Type_Code === 'FOLDER' ? -1 : 1
            ),
        [metaData?.info?.Actions]
    );

    const [associations, associationsMethods] = useList<PlainObject>([]);
    const associationsKeys = associations.map((a) => a.key);

    const viewDataArray = useAsyncRetry(async () => {
        if (selectedRowKeys.length && associations.length) {
            const all = await Promise.all(
                associations?.map(async (avd) => {
                    if (avd.data?.targetMetaCode && avd.data?.assocCode) {
                        const info =
                            metaStore.meta.get(avd.data?.targetMetaCode)?.info ??
                            (await metaStore.getInfo(avd.data?.targetMetaCode));

                        const select = await metaStore.makeSelect({
                            meta: avd.data?.targetMetaCode,
                            filters: `Id=assoc.${avd.data?.assocCode}([${[selectedRowKeys.at(-1)]
                                .map((k) => `"${k}"`)
                                .join(',')}])`
                        });

                        return { info: toJS(info), select: toJS(select) };
                    }

                    return { info: null, select: null };
                })
            );

            return all;
        }

        return [];
    }, [associations, selectedRowKeys]);

    const detailsMenuItems = useMemo(() => {
        const details: MenuItem[] = [];

        for (const action of actions ?? []) {
            if (!action.IsVisibleInList || !action.IsActive) {
                continue;
            }

            if (!action.IsFolder && action.Type_Code === 'ASSOTIATION' && action.IsVisibleInFrame) {
                const item = {
                    key: action.Id,
                    label: action.Name[language as LANGUAGES],
                    disabled:
                        (!action?.Handler?.IsForNoneObjects && !hasSelected) || action.IsDisabled,
                    data: {
                        assocCode: action.Association?.Code,
                        targetMetaCode: action.Association?.TargetMeta_Code,
                        sourceMetaCode: action.Association?.SourceMeta_Code,
                        action
                    }
                };

                details.push(item);
            }
        }

        return details;
    }, [actions, language, hasSelected]);

    const handleSelectAssociations = useCallback(
        ({ selectedKeys }: { selectedKeys: string[] }) => {
            const selectedItems = detailsMenuItems.filter((i) => selectedKeys.includes(i.key));
            associationsMethods.set(selectedItems);
        },
        [detailsMenuItems]
    );

    const handleDeselectAssociations = useCallback(
        ({ selectedKeys }: { selectedKeys: string[] }) => {
            associationsMethods.filter((a) => !selectedKeys.includes(a.key));
        },
        []
    );

    return {
        associations,
        clearAssociations: associationsMethods.clear,
        setAssociations: associationsMethods.set,
        dataArray: viewDataArray,
        renderer: () =>
            detailsMenuItems.length ? (
                <Dropdown
                    menu={{
                        items: detailsMenuItems,
                        style: { maxHeight: '400px', overflow: 'auto' },
                        selectable: true,
                        selectedKeys: associationsKeys,
                        onSelect: handleSelectAssociations,
                        onDeselect: handleDeselectAssociations
                        // multiple: true
                    }}
                    trigger={['click']}
                    placement="bottomLeft"
                    destroyPopupOnHide
                >
                    <ButtonWithTooltips type={'default'} className={''} id="details">
                        {isSmallTablet ? <AppstoreOutlined /> : t('details')}
                        {!isBigMobile && <CaretDownOutlined />}
                    </ButtonWithTooltips>
                </Dropdown>
            ) : null
    };
};
