import { DownOutlined } from '@ant-design/icons';
import { Card, Flex, Modal, Tree, TreeDataNode } from 'antd';
import * as JsSearch from 'js-search';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Meta, MetaField } from 'modules/services/backend-api/generated_info';

import { isEqual } from 'lodash';
import { toJS } from 'mobx';
import { useList } from 'react-use';
import { FilterField } from '../../types';
import {
    getActiveFiltersFromFields,
    getDefaultFilterOperator,
    getInitialActiveFilters
} from '../../utils';
import { LocalSearchComponent } from '../../../LocalSearchComponent/LocalSearchComponent';

interface AllFiltersModalProps {
    open: boolean;
    meta: string;
    setOpen: (open: boolean) => void;
    setActiveFilters: (filters: FilterField[]) => void;
    activeFilters: FilterField[];
    metaFields: MetaField[];
    metaChilds: Meta[];
    filtersFromLayout: FilterField[];
}

interface DataNode extends TreeDataNode {
    data: Meta | MetaField;
    parentName: string | null;
    children: DataNode[];
    parentId: React.Key | string | null;
    technicalName: string;
    parentTechnicalName: string | null;
}

// Функция для создания дерева из массивов fields и children
function buildTree(
    fields: MetaField[],
    children: Meta[],
    language: string,
    filtersFromLayout?: FilterField[]
) {
    // Вспомогательная функция для создания узла
    const createNode = (
        item: Meta | MetaField,
        parent: DataNode | MetaField | Meta | null = null
    ): DataNode => {
        const pluralName = (item as Meta).PluralName?.[language];
        const name = (item as MetaField).Name?.[language];
        const id = item.Id as string;

        let parentName = null;

        if (parent) {
            const parentPluralName = (parent as Meta)?.PluralName?.[language];
            const parentFieldName = (parent as MetaField)?.Name?.[language];
            const parentTitle = (parent as DataNode)?.title as string;

            parentName = parentTitle ?? parentPluralName ?? parentFieldName;
        }

        const isNoFilterIndex = !(item as MetaField).FilterIndex;
        const isFieldNotInLayoutsFilter = !filtersFromLayout?.find(
            ({ field: { FieldName } }) => FieldName === (item as MetaField).FieldName
        );

        return {
            title: pluralName ?? name,
            key: id,
            children: [] as DataNode[],
            data: item,
            technicalName: (item as Meta).ArrayNameInRoot ?? (item as MetaField).FieldName,
            parentTechnicalName: parent
                ? (parent as Meta).ArrayNameInRoot ??
                  (parent as MetaField).FieldName ??
                  (parent as DataNode).technicalName
                : null,
            parentId: (parent as DataNode)?.key ?? (parent as Meta | MetaField)?.Id ?? null,
            parentName,
            selectable: isNoFilterIndex && isFieldNotInLayoutsFilter
        };
    };

    // Формируем узлы первого уровня из `fields`
    const tree = fields.map((field) => createNode(field));

    // Функция для добавления дочерних узлов рекурсивно
    const addChildrenNodes = (parentNode: DataNode, childArray: MetaField[]) => {
        childArray.forEach((child) => {
            // Создаем узел для элемента child
            const childNode = createNode(child, parentNode);

            // // Рекурсивно добавляем дочерние узлы // TODO: может ли быть вложенность больше чем 2?
            // if (child.Fields && child.Fields.length > 0) {
            //     childNode.children = child.Fields.map((field) => createNode(field, child));
            // }
            parentNode.children.push(childNode);
        });
    };

    // Добавляем дочерние узлы к дереву
    children.forEach((child) => {
        const parentNode = createNode(child);
        addChildrenNodes(parentNode, child.Fields);
        tree.push(parentNode);
    });

    return tree;
}

// "Id":"36d1f823-ad70-e298-0db6-e30840d819f4", KEY
// "Id":"e4c8b04e-98f3-09d7-7a11-5c867545b4bf", TYPE

export const AllFiltersModal = memo<AllFiltersModalProps>(
    ({
        open,
        activeFilters: af,
        setActiveFilters: setAf,
        setOpen,
        meta,
        metaChilds,
        metaFields,
        filtersFromLayout
    }) => {
        const {
            t,
            i18n: { language }
        } = useTranslation();

        // const [activeData, setActiveData] = useState<FilterField[]>([]);
        const [activeFilters, activeFiltersMethods] = useList<DataNode & { filter: FilterField }>(
            []
        );
        // console.log('2', activeFilters);
        // const [actieFiltersKeys, setActiveFiltersKeys] = useState<React.Key[]>([]);
        const [actieFiltersKeys, activeFiltersKeysMethods] = useList<React.Key>([]);
        const [localSearchFilter, setLocalSearchFilter] = useState('');
        // console.log(actieFiltersKeys, activeFilters);

        const mappedAF = useMemo<DataNode[]>(
            () =>
                af.map((item) => {
                    const field = item.field;

                    const splitedDisplayTitle = field.DisplayTitle?.split('.');
                    const parentName =
                        splitedDisplayTitle?.length > 1 ? splitedDisplayTitle[1] : null;

                    const isNoFilterIndex = !field.FilterIndex;
                    const isFieldNotInLayoutsFilter = Array.isArray(filtersFromLayout)
                        ? !filtersFromLayout.find(
                              ({ field: { FieldName } }) => FieldName === field.FieldName
                          )
                        : true;

                    return {
                        title: field.Name?.[language],
                        key: field.Id as React.Key,
                        children: [] as DataNode[],
                        data: field,
                        // parentId: (parent as DataNode)?.key ?? (parent as Meta | MetaField)?.Id ?? null,
                        parentName,
                        filter: item,
                        selectable: isNoFilterIndex && isFieldNotInLayoutsFilter,
                        parentId: null,
                        technicalName: field.FieldName,
                        parentTechnicalName: null
                    };
                }),
            [af, filtersFromLayout, language]
        );

        // console.log(mappedAF);

        useEffect(() => {
            activeFiltersMethods.set(mappedAF);
            activeFiltersKeysMethods.set(mappedAF.map((filter) => filter.key));
            // setActiveData(activeFilters);
        }, [mappedAF]);

        useEffect(() => {
            // console.log('INIT');
            const initialActiveFilters = getInitialActiveFilters(metaFields, filtersFromLayout);

            activeFiltersMethods.set((prev) => {
                if (prev.length > 0) {
                    return prev;
                }

                return initialActiveFilters;
            });

            setAf((prev) => {
                const isPrevEqFieldsFilter = isEqual(prev, getActiveFiltersFromFields(metaFields));
                const isPrevEqInitialFilter = isEqual(prev, initialActiveFilters);

                // console.log(prev, getActiveFiltersFromFields(metaFields), initialActiveFilters);

                if (prev.length === 0) return initialActiveFilters;
                if (isPrevEqFieldsFilter && !isPrevEqInitialFilter) return initialActiveFilters;
                if (isPrevEqInitialFilter) return initialActiveFilters;

                if (!isPrevEqInitialFilter && !isPrevEqFieldsFilter) return prev;
                return prev;
            });
        }, [metaFields, filtersFromLayout]);
        // }, [metaFields, filtersFromLayout]);

        const addAppliedField = useCallback((field: FilterField) => {
            activeFiltersMethods.push(field);
            // setActiveData((prevValue) => {
            //     return [...prevValue, field];
            // });
        }, []);

        const handleCancel = () => {
            activeFiltersMethods.set(mappedAF);
            activeFiltersKeysMethods.set(mappedAF.map((filter) => filter.key));

            setOpen(false);
        };

        const handleOk = () => {
            setAf(
                activeFilters.map((activeFilter) => ({
                    field: {
                        ...activeFilter.filter.field,
                        isChild: !!activeFilter.parentName,
                        DisplayTitle: activeFilter.parentName
                            ? `${activeFilter.parentName}.${activeFilter.title}`
                            : activeFilter.title,
                        withAny: activeFilter.parentName
                            ? (filter: string) =>
                                  `${activeFilter.parentTechnicalName}=any(${filter})`
                            : (filter: string) => filter
                    },
                    values: activeFilter.filter.values
                }))
            );
            // setActiveFilters(activeData);
            setOpen(false);
        };

        const allFilters = useMemo(() => {
            const treeItems = buildTree(metaFields, metaChilds, language, filtersFromLayout);

            return treeItems;
        }, [metaFields, metaChilds, language, filtersFromLayout]);
        // console.log(searchedFields, searchedChildsAndTheyFields, allFilters);

        const searchedData = useMemo(() => {
            if (localSearchFilter) {
                const index = new JsSearch.Search('key');

                index.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
                index.addIndex('title');

                index.addDocuments(
                    allFilters.map((filter) => {
                        const index = new JsSearch.Search('key');

                        index.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
                        index.addIndex('title');

                        index.addDocuments(filter.children || []);

                        if (localSearchFilter) {
                            return { ...filter, children: index.search(localSearchFilter) };
                        }
                        return filter;
                    }) || []
                );

                return index.search(localSearchFilter);
            }

            return allFilters || [];
        }, [localSearchFilter, allFilters]);

        // console.log(allFilters, searchedData);

        return (
            <Modal
                open={open}
                onCancel={handleCancel}
                onOk={handleOk}
                okText={t('ok')}
                cancelText={t('cancel')}
                width="60%"
                destroyOnClose
                title={t('choose_fields')}
                centered
            >
                <Flex gap={5}>
                    <Card
                        title={`${t('all_fields')}:`}
                        extra={
                            <LocalSearchComponent
                                searchFilter={localSearchFilter}
                                setFilter={setLocalSearchFilter}
                                placeholder={`${t('search')}...`}
                            />
                        }
                        style={{ width: '100%' }}
                    >
                        <Tree
                            style={{ height: 'calc(100vh - 34rem)', overflow: 'auto' }}
                            showLine
                            switcherIcon={<DownOutlined />}
                            selectedKeys={actieFiltersKeys}
                            checkStrictly={true}
                            multiple={true}
                            onSelect={(selectedKeys, { selectedNodes }) => {
                                activeFiltersMethods.clear();

                                const notParentSelectedNodes = selectedNodes.filter(
                                    (node) => !node.children.length
                                );
                                const keys = notParentSelectedNodes.map((node) => node.key);

                                activeFiltersKeysMethods.set(keys);

                                for (const node of notParentSelectedNodes) {
                                    const nodeData = node.data as MetaField;
                                    const fieldId = nodeData.Id;
                                    // console.log(activeFilters, node);

                                    const alreadySelectedFilter = activeFilters.find(
                                        (activeFilter) => activeFilter.filter?.field?.Id === fieldId
                                    )?.filter;

                                    const filterField: FilterField = alreadySelectedFilter ?? {
                                        field: toJS(nodeData),
                                        values: []
                                    };

                                    if (!alreadySelectedFilter) {
                                        filterField.values.push({
                                            operator: getDefaultFilterOperator(filterField),
                                            column: nodeData.ColumnName
                                        });
                                    }

                                    addAppliedField({ ...node, filter: filterField });
                                }
                            }}
                            // treeData={allFilters}
                            treeData={searchedData}
                        />
                    </Card>
                </Flex>
            </Modal>
        );
    }
);
