import { CloseCircleFilled } from '@ant-design/icons';
import { DatePicker } from 'antd';
import { DatePicker as MobileDatePicker } from 'antd-mobile';
import dayjs, { Dayjs } from 'dayjs';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

import { localeFormats, REGEX_REPLACE_GMT, TIME_FORMAT } from 'utils/helpers/dates';
import { LANGUAGES } from 'utils/i18n/i18n';
import { langMasks } from '../SmartDateField/SmartDateField';

import './SmartDatetimeRangeField.scss';

type AntdRangeValue<T> = [T | null, T | null];

type DateTimeRangeValue = {
    FromDatetime: string | null;
    ToDatetime: string | null;
};

interface SmartDatetimeRangeFieldProps {
    value: DateTimeRangeValue | null; // ISO string (from Supabase DB) aka DbDateTime
    onChange: (newValue: DateTimeRangeValue | null) => void; // ISO string (from Supabase DB) aka DbDateTime
    popoverContainerHtmlId?: string;
    disabled?: boolean;
    showTime?: boolean;
    utc?: boolean; //  если utc=true Сохраняем в UTC, отображаем как есть, т.е. учитывается часовой пояс
    style?: { [key: string]: any };
    className?: string;
}
export const SmartDatetimeRangeField = memo<SmartDatetimeRangeFieldProps>(
    ({
        value,
        onChange,
        disabled,
        popoverContainerHtmlId,
        className,
        style,
        showTime = true,
        utc
    }) => {
        const { t, i18n } = useTranslation();
        const lang = i18n.language;
        const isRu = lang === 'ru';
        const isEn = lang === 'en';
        const isBigMobile = useMedia('(max-width: 480px)');

        const format = showTime
            ? langMasks[i18n.language === 'ru' ? 'ru' : 'en'].datetime
            : langMasks[i18n.language === 'ru' ? 'ru' : 'en'].date;

        // Определение функции для рендеринга компонента выбора даты и времени
        const handleClear: React.MouseEventHandler<HTMLSpanElement> = (e) => {
            e.stopPropagation();
            onChange(null);
        };

        const [localValue, setLocalValue] = useState();

        const handleChange = useCallback(
            (date: AntdRangeValue<Dayjs> | null) => {
                onChange({
                    FromDatetime: date
                        ? dayjs(date[0]).format(showTime ? 'YYYY-MM-DDTHH:mm:ssZ' : 'YYYY-MM-DD')
                        : null,
                    ToDatetime: date
                        ? dayjs(date[1]).format(showTime ? 'YYYY-MM-DDTHH:mm:ssZ' : 'YYYY-MM-DD')
                        : null
                });
            },
            [onChange, showTime]
        );

        const antdValue: AntdRangeValue<Dayjs> = [
            value?.FromDatetime
                ? dayjs(value.FromDatetime.replace(REGEX_REPLACE_GMT, '').replace('Z', ''))
                : null,
            value?.ToDatetime
                ? dayjs(value.ToDatetime.replace(REGEX_REPLACE_GMT, '').replace('Z', ''))
                : null
        ];
        // console.log(value, antdValue);
        // The component is used to display and edit date and time values using user's preferred format!
        const handleKeyPress: (event: React.KeyboardEvent<HTMLElement>) => void = (e) => {
            if (['Enter', 'Tab'].includes(e.key)) {
                if (!e.target.value) {
                    onChange(null);
                    return;
                }

                const date = dayjs(
                    e.target.value,
                    localeFormats[(i18n.language as Exclude<LANGUAGES, 'tech'>) || 'en'].formats.L,
                    true
                ).locale(i18n.language);

                if (date.isValid()) {
                    handleChange(date);
                }
            }
        };

        const [openMobilePicker, setOpenMobilePicker] = useState(false);
        const [openMobilePickerNext, setOpenMobilePickerNext] = useState(false);

        if (isBigMobile) {
            const renderLabel = (type: string, data: number) => {
                switch (type) {
                    case 'hour':
                        return `${data} ${isRu ? 'ч' : 'h'}`;
                    case 'minute':
                        return `${data} ${isRu ? 'мин' : 'min'}`;
                    case 'second':
                        return `${data} ${isRu ? 'с' : 's'}`;
                    default:
                        return data;
                }
            };

            const startValue = antdValue?.[0];
            const startDate = startValue?.toDate();

            const endValue = antdValue?.[1];
            const endDate = endValue?.toDate();

            return (
                <div className="smart_date_range_field smart_date_range_field__mobile">
                    <DatePicker.RangePicker
                        placeholder={[t('no_value') as string, t('no_value') as string]}
                        value={antdValue}
                        onChange={handleChange}
                        format={{ format, type: 'mask' }}
                        allowClear={{ clearIcon: <CloseCircleFilled onClick={handleClear} /> }}
                        showTime={showTime ? { format: TIME_FORMAT } : false}
                        className={className}
                        onKeyDown={handleKeyPress}
                        style={style}
                        disabled={disabled}
                        open={false}
                        onClick={() => setOpenMobilePicker(true)}
                    />
                    <MobileDatePicker
                        title={t('start')}
                        precision={showTime ? 'minute' : 'day'}
                        defaultValue={new Date()}
                        visible={openMobilePicker}
                        onClose={() => {
                            setOpenMobilePicker(false);
                        }}
                        value={startDate}
                        confirmText={t('next')}
                        cancelText={t('cancel')}
                        onConfirm={(date) => {
                            handleChange([dayjs(date), endValue || null]);
                            setOpenMobilePickerNext(true);
                        }}
                        renderLabel={renderLabel}
                        max={endDate}
                    />
                    <MobileDatePicker
                        title={t('end')}
                        precision={showTime ? 'minute' : 'day'}
                        defaultValue={new Date()}
                        visible={openMobilePickerNext}
                        onClose={() => {
                            setOpenMobilePickerNext(false);
                        }}
                        onCancel={() => {
                            setOpenMobilePickerNext(false);
                            setOpenMobilePicker(true);
                        }}
                        value={endDate}
                        confirmText={t('ok')}
                        cancelText={t('prev')}
                        onConfirm={(date) => handleChange([startValue || null, dayjs(date)])}
                        renderLabel={renderLabel}
                        min={startDate}
                    />
                </div>
            );
        }

        // Рендеринг базового компонента поля ввода с переданными пропсами и определенными функциями рендеринга компонентов выбора и просмотра даты и времени
        return (
            <DatePicker.RangePicker
                placeholder={[t('no_value') as string, t('no_value') as string]}
                value={antdValue}
                onChange={handleChange}
                format={{ format, type: 'mask' }}
                allowClear={{ clearIcon: <CloseCircleFilled onClick={handleClear} /> }}
                showTime={showTime}
                className={className}
                style={style}
                onBlur={(e) =>
                    handleKeyPress({
                        target: { value: e.target.value || setLocalValue },
                        key: 'Enter'
                    })
                }
                onKeyDown={handleKeyPress}
                disabled={disabled}
                getPopupContainer={
                    popoverContainerHtmlId
                        ? () => document.getElementById(popoverContainerHtmlId) as HTMLElement
                        : undefined
                }
            />
        );
    }
);
