import React, { useEffect, useMemo, useState } from 'react';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { HandlerRunModal } from '../HandlerRunModal/HandlerRunModal';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Action, Handler } from 'modules/services/backend-api/generated_info';

export type ArgsFieldProps = {
    argsJSONValue: any | undefined;
    onChange: (newArgsJSONValue: any) => void;
    handlerId: string | undefined;
    style?: React.CSSProperties;
};

const HANDLERS_META = 'InfoHandlers';

export const ArgsField = ({ argsJSONValue, handlerId, onChange }: ArgsFieldProps) => {
    const [handler, setHandler] = useState<IObjectWithId>();

    const [initialised, setInitialised] = useState<boolean>(false);
    const [openModel, setOpenModal] = useState<boolean>(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (initialised && handler?.Id && handler?.Id !== handlerId) {
            setInitialised(false);
        }
    }, [initialised, handlerId, handler]);

    useEffect(() => {
        const init = async () => {
            if (handlerId) {
                const cachedHandler = metaStore.meta.get(HANDLERS_META)?.get;
                if (cachedHandler && cachedHandler[handlerId]) {
                    const handler = cachedHandler[handlerId]?.object;
                    if (handler) {
                        setHandler(handler);
                    }
                } else {
                    const handler = (
                        await metaStore.makeGet({ meta: HANDLERS_META, id: handlerId })
                    ).object;

                    if (handler) {
                        setHandler(handler as IObjectWithId);
                    }
                }

                setInitialised(true);
            }
        };

        if (!initialised) {
            init();
        }
    }, [initialised, handlerId]);

    const actionAsHandler = useMemo(() => {
        const actionHandler: Partial<Action> = {};

        if (handler) {
            actionHandler.Handler = handler as Handler;
            actionHandler.Handler_Code = handler?.Code;

            if (argsJSONValue) {
                const paramNames = Object.keys(argsJSONValue);

                if (paramNames && Array.isArray(paramNames)) {
                    paramNames.forEach((paramName) => {
                        const param = actionHandler.Handler?.ChildParams.find(
                            (param) => param.ParamName === paramName
                        );

                        if (param) {
                            param.DefaultValue = argsJSONValue[paramName];
                        }
                    });
                }
            }


            actionHandler.Handler?.ChildParams?.forEach((param) => {
                param.IsRequested = true;
            });
        }

        return actionHandler;
    }, [argsJSONValue, handler]);

    return (
        <div>
            <>
                {actionAsHandler &&
                    actionAsHandler.Handler &&
                    actionAsHandler.Handler.ChildParams &&
                    Array.isArray(actionAsHandler.Handler.ChildParams) && (
                        <HandlerRunModal
                            open={openModel}
                            onCancel={() => {
                                setOpenModal(false);
                            }}
                            metaName={handler?.Meta?.Code || ''}
                            ids={[]}
                            onRefresh={() => {}}
                            onResponseCallback={(newArgs) => {
                                onChange(newArgs);
                                setOpenModal(false);
                            }}
                            action={actionAsHandler as Action}
                            disableNotify={true}
                            localMode={true}
                        />
                    )}

                <Button
                    type="primary"
                    // style={{ marginBottom: '10px', marginLeft: '10px' }}
                    disabled={!actionAsHandler}
                    onClick={() => {
                        setOpenModal(true);
                    }}
                >
                    {t('show_params')}
                </Button>
            </>

            {/* handlerCode: {handlerId}; value:{argsJSONValue}; handlerParams:{' '} */}
            {/* {JSON.stringify(handler)} */}
        </div>
    );
};
