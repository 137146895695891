import { Drawer, FloatButton } from 'antd';
import { isDefined } from 'is-lite/exports';
import { memo, useState } from 'react';
import { useAsync, useBoolean } from 'react-use';

import { LogMessagesTable, SmartTable } from 'smart/components';
import { Loader } from 'ui/Loader/Loader';
import { metaStore } from 'utils/store/MetaStore';

const meta = 'InfoHandlerRuns';

export const SmartFloatLogs = memo(() => {
    const [openLogs, setOpenLogs] = useBoolean(false);

    const [requestId, setRequestId] = useState<string>();

    const metaSource = useAsync(async () => {
        const info = await metaStore.getInfo(meta);
        return info;
    }, []);

    const dataSource = useAsync(async () => {
        if (openLogs) {
            const select = await metaStore.makeSelect({ meta, page: 1, page_size: 100 });
            return select.objects;
        }

        return metaStore.meta.get(meta)?.select?.objects;
    }, [openLogs]);

    const logs = useAsync(async () => {
        const logs = await metaStore.getLogs({
            request_id: requestId as string
        });

        return logs?.log_messages;
    }, [requestId]);

    return (
        <FloatButton.Group
            shape="circle"
            style={{
                insetBlockEnd: 70,
                insetInlineEnd: 30
            }}
        >
            <FloatButton
                // badge={{ dot: true }}
                tooltip="Просмотреть логи"
                onClick={() => setOpenLogs(true)}
            />

            <Drawer width="70vw" open={openLogs} onClose={() => setOpenLogs(false)}>
                <Loader status={dataSource.loading || metaSource.loading}>
                    <SmartTable
                        meta={meta}
                        fields={metaSource?.value?.Fields}
                        data={dataSource?.value}
                        doubleClickable
                        onDoubleClick={(row) => {
                            setRequestId(row.RequestId);
                        }}
                        virtual
                    />
                </Loader>

                <Drawer
                    width="70vw"
                    open={isDefined(requestId)}
                    onClose={() => setRequestId(undefined)}
                >
                    <Loader status={logs.loading}>
                        <LogMessagesTable logMessages={logs?.value} showAlways />
                    </Loader>
                </Drawer>
            </Drawer>
        </FloatButton.Group>
    );
});
