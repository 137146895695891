import i18n, { FallbackLngObjList, InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import azTranslation from './json/az.json';
import enTranslation from './json/en.json';
import kkTranslation from './json/kk.json';
import ruTranslation from './json/ru.json';
import trTranslation from './json/tr.json';

enum LANGUAGES {
    ru = 'ru',
    en = 'en',
    kk = 'kk',
    az = 'az',
    tr = 'tr',
    tech = 'tech'
}

const options: InitOptions = {
    fallbackLng: {
        ru: ['en', 'kk', 'az', 'tr'],
        en: ['ru', 'kk', 'az', 'tr'],
        kk: ['ru', 'en', 'az', 'tr'],
        az: ['ru', 'en', 'kk', 'tr'],
        tr: ['ru', 'en', 'kk', 'az'],
        tech: [],
        default: ['ru', 'en', 'kk', 'az', 'tr']
    },
    lng: 'ru',
    resources: {
        ru: {
            translations: ruTranslation
        },
        kk: {
            translations: kkTranslation
        },
        en: {
            translations: enTranslation
        },
        az: {
            translations: azTranslation
        },
        tr: {
            translations: trTranslation
        },
        tech: {
            translations: JSON.stringify({})
        }
    },
    ns: ['translations'],
    defaultNS: 'translations'
};

console.log('[i18n] init');
i18n.use(initReactI18next).init(options);

i18n.languages = Object.keys(LANGUAGES);

export { i18n, LANGUAGES };
