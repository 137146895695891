import { ConfigProvider, Drawer, Flex, Menu, Typography } from 'antd';
import { DrawerProps } from 'antd/es/drawer/index';
import MenuItem from 'antd/es/menu/MenuItem';
import SubMenu from 'antd/es/menu/SubMenu';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { useAsync, useMedia } from 'react-use';
import { ReactComponent as ITVecturaIcon } from 'assets/icons/logo-vectura.svg';

import { MenuItem as MenuItemServerType } from 'modules/services/backend-api/generated_api';
import { camelize } from 'utils';
import { useAppVersion } from 'utils/hooks/useAppVersion';
import { modalStores, useStoreNavigate } from 'utils/store';
import { metaStore } from 'utils/store/MetaStore';
import { cssStringToObject, fnv1aHash } from 'smart/utils';

import { ASIDE_CLOSE_WIDTH, ASIDE_CLOSE_WIDTH_MOBILE, ASIDE_OPEN_WIDTH, THEME } from '../constants';
import type { AsideNavigatonPanelProps, IMenuItem } from './types';
import { LeftMenuHeaderLabel, LeftMenuRouteLabel } from './ui';
import { LazyIcon } from '../../../../../smart/ui';

import './Aside.scss';
import { useBaseLanguage } from 'smart/utils/hooks';

const { Text } = Typography;

// // Список маршрутов словарей
// const dictionariesRoutes = Object.keys(routes).filter((route) => route === 'dictionaries');
// // Отфильтрованный и отсортированный список маршрутов
// const sortedRoutes = Object.keys(routes).filter(
//     (route) => route !== 'baseRoutes' && route !== 'dictionaries' && route !== 'profile'
// );

// function sortMenu(menuItem: IMenuItem | null) {
//     if (!menuItem) return null;

//     if (menuItem?.children) {
//         menuItem.children.sort((a, b) => a?.path?.localeCompare(b.path || '') || 0);
//         menuItem.children.map((child) => sortMenu(child));
//     }

//     return menuItem;
// }

function sortMetaMenu(menuItem: IMenuItem) {
    if (menuItem?.children) {
        menuItem.children.sort((a, b) => (a.child_index || 0) - (b.child_index || 0));
        menuItem.children.map((child) => sortMetaMenu(child));
    }

    return menuItem;
}

export const Aside = observer<AsideNavigatonPanelProps>(({ isOpenStatus, onClose }) => {
    const appVersion = useAppVersion();
    const isMiddleTablet = useMedia('(max-width: 720px)');

    const config = useContext(ConfigProvider.ConfigContext);
    const { theme } = config;
    const asideMenuBg = theme?.token?.asideMenuBg;
    const asideMenuHoverBg = theme?.token?.asideMenuHoverBg;
    const logoMedium = theme?.token?.logoMedium;
    const logoMediumStyle = theme?.token?.logoMediumStyle;
    const logoSmall = theme?.token?.logoSmall;
    const logoSmallStyle = theme?.token?.logoSmallStyle;

    const isGroupNavItems = metaStore.meta.get('all')?.params?.GROUP_NAVITEMS;

    const open = isMiddleTablet ? isOpenStatus : true;

    const styles = useMemo<DrawerProps['styles']>(() => {
        return {
            header: {
                padding: 0,
                // backgroundColor: '#07204A',
                backgroundColor: asideMenuBg,
                overflowX: 'hidden'
            },
            body: {
                padding: 0,
                // backgroundColor: '#07204A',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: asideMenuBg,
                overflowX: 'hidden'
            },
            mask: {
                backdropFilter: 'blur(5px)'
            }
        };
    }, [asideMenuBg]);

    const asideWidth = useMemo(() => {
        if (isOpenStatus) return ASIDE_OPEN_WIDTH;
        if (isMiddleTablet) return ASIDE_CLOSE_WIDTH_MOBILE;
        return ASIDE_CLOSE_WIDTH;
    }, [isOpenStatus, isMiddleTablet]);

    const {
        t,
        i18n: { language }
    } = useTranslation(); // Хук для перевода текста
    const storeNavigate = useStoreNavigate(); // Хук для навигации
    const location = useLocation();
    const baseLanguage = useBaseLanguage();

    const [activeKeys, setActiveKeys] = useState<string[]>([]);

    useAsync(async () => {
        await metaStore.getParam({ param_name: 'SHOW_SUBLEVEL_ICONS' });
        await metaStore.getParam({ param_name: 'MAX_MENU_LEVEL' });
        // await metaStore.getParam({ param_name: 'DASHBOARDS_ENABLED' });
    }, []);

    const maxMenuLevel = metaStore.meta.get('all')?.params?.MAX_MENU_LEVEL?.param_value;
    const showSublevelIcons = metaStore.meta.get('all')?.params?.SHOW_SUBLEVEL_ICONS?.param_value;
    // const disableOldMenu = metaStore.meta.get('all')?.params?.DISABLE_OLD_MENU?.param_value;

    useEffect(() => {
        // Разбиваем URL-адрес на части
        const parts = location.pathname.split('/');

        // Определяем активные ключи пунктов и подпунктов
        const newActiveKeys = [];
        if (parts.length >= 2) {
            newActiveKeys.push(parts[1]); // Ключ пункта
            if (parts.length >= 3) {
                newActiveKeys.push(`sub${parts[2]}`); // Ключ подпункта
            }
        }

        // Устанавливаем активные ключи в состояние
        setActiveKeys(newActiveKeys);
    }, [location.pathname]);

    // Формирование header меню
    const createHeaderItem = useCallback(() => {
        const openLogo = logoMedium ? (
            <img
                className={'logo'}
                style={{ width: '100%', height: '100%' }}
                src={logoMedium}
                alt={'logo_medium'}
            />
        ) : (
            <ITVecturaIcon className={`logo`} />
        );

        const closeLogo = logoSmall ? (
            <img className={'logo logo-close'} src={logoSmall} alt={'logo_small'} />
        ) : (
            <ITVecturaIcon className={`logo logo-close`} />
        );

        return {
            // label: logoMedium ? t('home') : <LeftMenuHeaderLabel />,
            label: !isOpenStatus ? t('home') : null,
            onClick: () => storeNavigate({ pathname: '/' }),
            icon: isOpenStatus ? openLogo : closeLogo,
            key: 'header-menu',
            id: 'header-menu',
            theme: THEME,
            style: {
                marginBottom: isOpenStatus ? 9 : 0,
                paddingLeft: 16,
                marginLeft: isOpenStatus ? 0 : undefined,
                marginRight: isOpenStatus ? 0 : undefined,
                marginTop: isOpenStatus ? 0 : undefined,
                paddingInline: isOpenStatus ? 0 : undefined,
                width: isOpenStatus ? '100%' : undefined,
                ...(isOpenStatus
                    ? { ...cssStringToObject(logoMediumStyle) }
                    : { ...cssStringToObject(logoSmallStyle) })
            }
        };
    }, [isOpenStatus, logoMedium, logoSmall, logoMediumStyle, logoSmallStyle, t]);

    const createMetaRouteItems = useCallback(() => {
        // ### meta menu items ###
        const items: IMenuItem[] = [];

        const menu = toJS(metaStore.meta.get('all')?.menu);

        const existsMenusMap: { [nav_item_id_plus_path: string]: MenuItemServerType } = {};

        const notHiddenItems = menu?.items.filter((m) => !m.is_hidden);
        // console.log('[Aside] meta menu:', menu);
        const map: Record<string, any> = {};

        // только НЕ скрытые
        for (const item of notHiddenItems || []) {
            // const itemCode = item.code || camelize(item.name.en);
            const itemCode = camelize(item?.name?.en || '');
            // const itemLabel = item.name[language] || t(itemCode);
            const itemLabel = item.name ?? t(itemCode);

            // перменные, необходимые для навигации
            let pathname = '';
            let filterString = '';

            // если по пункту меню можно навигироваться, то выполняем логику ниже (заполняем переменные выше)
            if (item.path) {
                // прилетает строка вида "/orders-management/transportation-orders?type_code=eq.PURCHASE_ORDER"
                // разделяем по знаку '?' - первый элемент это наш pathname, второй - фильтры
                const [path, filterStr] = item.path.split('?');
                pathname = path;
                filterString = filterStr;
            }

            const handleClickMenu = () => {
                // console.log('onClick menu');
                if (pathname && filterString) {
                    storeNavigate(
                        { pathname },
                        {
                            state: {
                                filterString,
                                pageTitle: itemLabel,
                                cacheKey: fnv1aHash(`${item.id}_${item.name?.[language]}`)
                            }
                        }
                    );
                } else if (pathname && !filterString) {
                    storeNavigate(
                        { pathname },
                        {
                            state: {
                                pageTitle: itemLabel,
                                cacheKey: fnv1aHash(`${item.id}_${item.name?.[language]}`)
                            }
                        }
                    );
                }
            };

            const itemLabelMarkup =
                item.is_folder && pathname ? (
                    <LeftMenuRouteLabel
                        isOpen={isOpenStatus}
                        text={itemLabel[language] ?? itemLabel[baseLanguage] ?? t(itemCode)}
                        onClick={handleClickMenu}
                        buttonId={`${t(itemCode)}-overview`}
                    />
                ) : (
                    <Text className="left-menu-text">
                        {itemLabel[language] ?? itemLabel[baseLanguage] ?? t(itemCode)}
                    </Text>
                );

            // existsMenusMap[item.nav_item_id] = item;

            const existsKey = item.nav_item_id + item.path;

            if (!existsMenusMap[existsKey] || !isGroupNavItems) {
                map[item.id] = {
                    id: item.id,
                    key: item.id,
                    icon:
                        item.is_folder && !item.parent_id ? (
                            <LazyIcon icon={item.icon} />
                        ) : showSublevelIcons && item.icon ? (
                            <LazyIcon icon={item.icon} />
                        ) : null,
                    theme: THEME,
                    label: itemLabelMarkup,
                    path: itemLabel[language] ?? itemLabel[baseLanguage] ?? t(itemCode),
                    child_index: item.child_index,
                    // folder option's settings
                    onClick: item.is_folder ? undefined : handleClickMenu,
                    children: item.is_folder ? [] : undefined,
                    level: item.is_folder ? (item.parent_id ? 2 : 1) : 2
                };
            }

            existsMenusMap[existsKey] = item;
        }

        // console.log(existsMenusMap, maxMenuLevel);

        if (maxMenuLevel) {
            for (const item of notHiddenItems || []) {
                if (item.parent_id && map[item.parent_id] && map[item.parent_id].children) {
                    map[item.id].level = map[item.parent_id].level + 1;
                    if (map[item.id]?.level <= maxMenuLevel) {
                        map[item.parent_id].children.push(map[item.id]);
                    }
                } else if (item.parent_id && !map[item.parent_id]) {
                    // ### кейс для ситуации когда айтему нужен родитель но его нет в мапке ###
                    // console.log('item', item);
                    const parent = notHiddenItems?.find((i) => i.id === item.parent_id);
                    // console.log('parent', parent);
                    const parentNavItemId = parent?.nav_item_id;
                    const otherParent = notHiddenItems?.find(
                        (i) => i.nav_item_id === parentNavItemId && i.id !== parent?.id
                    );
                    if (otherParent?.id && !otherParent.parent_id) {
                        // console.log('other parent', otherParent);
                        map[otherParent.id]?.children?.push(map[item.id]);
                    } else if (map[item.id]?.level <= maxMenuLevel) {
                        items.push(map[item.id]);
                    }
                } else if (map[item.id]?.level <= maxMenuLevel) items.push(map[item.id]);
            }
        } else {
            for (const item of notHiddenItems || []) {
                if (item.parent_id && map[item.parent_id] && map[item.parent_id].children) {
                    map[item.parent_id].children.push(map[item.id]);
                } else if (item.parent_id && !map[item.parent_id]) {
                    // ### кейс для ситуации когда айтему нужен родитель но его нет в мапке ###
                    // console.log('item', item);
                    const parent = notHiddenItems?.find((i) => i.id === item.parent_id);
                    // console.log('parent', parent);
                    const parentNavItemId = parent?.nav_item_id;
                    const otherParent = notHiddenItems?.find(
                        (i) => i.nav_item_id === parentNavItemId && i.id !== parent?.id
                    );
                    if (otherParent?.id && !otherParent.parent_id) {
                        // console.log('other parent', otherParent);
                        map[otherParent.id]?.children?.push(map[item.id]);
                    } else {
                        items.push(map[item.id]);
                    }
                } else items.push(map[item.id]);
            }
        }

        return items
            .sort((a, b) => (a.child_index || 0) - (b.child_index || 0))
            .map((item) => sortMetaMenu(item));
        // ! ### meta menu items ###
    }, [
        isGroupNavItems,
        isOpenStatus,
        language,
        maxMenuLevel,
        showSublevelIcons,
        storeNavigate,
        t
    ]);

    const items = useMemo(
        () => [createHeaderItem(), ...createMetaRouteItems()],
        [createHeaderItem, createMetaRouteItems]
    );

    const renderMenuItems = (items: IMenuItem[]) => {
        return items.map((item) => {
            if (item.children && item.children.length > 0) {
                return (
                    <SubMenu {...item} key={item.id} title={item.label}>
                        {renderMenuItems(item.children)}
                    </SubMenu>
                );
            }

            return <MenuItem {...item}>{item.label}</MenuItem>;
        });
    };

    return (
        <Drawer
            onClose={onClose}
            width={asideWidth}
            styles={styles}
            mask={isMiddleTablet}
            closable={isMiddleTablet}
            open={open}
            placement="left"
            className="aside"
        >
            <Menu
                mode="inline"
                inlineCollapsed={!isOpenStatus}
                items={items}
                className="left-menu"
                style={{
                    '--aside-menu-bg': asideMenuBg,
                    '--aside-menu-bg-hover': asideMenuHoverBg
                }}
                selectedKeys={activeKeys}
            />
            <Flex
                className="aside__app-version"
                style={{ paddingLeft: isOpenStatus ? 20 : 6 }}
                justify="space-between"
            >
                {isOpenStatus && <Typography.Text>© ООО "ИТ ВЕКТУРА", 2024</Typography.Text>}
                <Typography.Text
                    onClick={() => {
                        const versionParts = appVersion.split('.');
                        const lastPart = versionParts.at(-1);

                        storeNavigate(
                            { pathname: `/changelog` },
                            {
                                state: {
                                    pageTitle: 'changelog',
                                    meta: appVersion.startsWith('v')
                                        ? `${versionParts.slice(1, -1).join('.')}.${lastPart}`.trim()
                                        : `${versionParts.slice(0, -1).join('.')}.${lastPart}`.trim()
                                }
                            }
                        );
                    }}
                >
                    {appVersion}
                </Typography.Text>
            </Flex>
        </Drawer>
    );
});
