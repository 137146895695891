import { decodeJWT } from 'utils/helpers/decode';
import { createMinioStorage } from 'modules/storage/minioStorage';
import { ApiClient, itvecturaTokenResponseType, KeycloakTokenResponseType } from './types';
import { CLIENT_KEY_LOCAL_STORAGE } from './localStorage';

const domain = window.location.origin;

// NEW
const api = window.env.BACKEND_API_URL;
const endpoint = '/storage/';
const isApiWithDomain = !api.startsWith('/');
export const storageApiUrl = !isApiWithDomain ? `${domain}${api}${endpoint}` : `${api}${endpoint}`;

// OLD
// const endpoint = window.env.STORAGE_API_URL;
// const isEndpointWithDomain = !endpoint.startsWith('/');
// export const storageApiUrl = !isEndpointWithDomain ? `${domain}${endpoint}` : endpoint;
// const storageApiUrl = window.env.STORAGE_API_URL;

export function isTokenExpired(token: string | undefined): boolean {
    if (!token) return false;

    const decodedToken = decodeJWT(token);
    const currentTime = Math.floor(Date.now() / 1000);
    const bufferTime = 60;

    // считаем токен просроченным, если его срок действия меньше текущего времени + буфер
    return decodedToken.exp <= currentTime + bufferTime;
}

// Функция для создания клиента с поддержкой itvectura
export const createItvecturaClient = (): ApiClient => {
    // let session: Session = window.localStorage.getItem('session');
    // // let acessToken = null;

    // NEW
    const api = window.env.BACKEND_API_URL;
    const endpoint = '/auth/login';
    const isApiWithDomain = !api.startsWith('/');
    const authApiUrl = !isApiWithDomain ? `${domain}${api}${endpoint}` : `${api}${endpoint}`;
    console.log(authApiUrl);

    // OLD
    // const endpoint = window.env.AUTH_API_URL;
    // const isEndpointWithDomain = !endpoint.startsWith('/');
    // const authApiUrl = !isEndpointWithDomain ? `${domain}${endpoint}login` : `${endpoint}login`;

    // const authApiUrl = `${window.env.AUTH_API_URL}login`;
    let refreshingPromise: Promise<void> | null = null; // промис чтобы был только один запрос на обнавление токена и остальные запросы ждали его обнавления

    // if (session) {
    //     session = JSON.parse(session);
    // acessToken = session?.data?.access_token;
    // }

    const client: ApiClient = {
        // authProvider: 'keycloak',
        session: null,
        // accessToken: acessToken,
        async getUser() {
            const session = await this.getSession();
            return {
                data: session?.data?.user
            };
        },

        async getSession() {
            if (!this.session) {
                throw new Error('No session found');
            }

            if (isTokenExpired(this.session?.data?.access_token)) {
                // Обновляем токен, если он истёк
                await this.refreshAccessToken();
            }

            return this.session;
        },

        async setSession(accessToken: string, refreshToken: string) {
            this.session = { data: { access_token: accessToken, refresh_token: refreshToken } };
            return this.session;
        },

        async signOut() {
            console.log('Keycloak signOut');
            this.session = null;
            window.localStorage.removeItem(CLIENT_KEY_LOCAL_STORAGE);
        },
        async isAuthenticated() {
            // const sessionExpired =
            //     this.session && this.session.data && isTokenExpired(this.session.data.access_token);
            return this.session !== null;
        },
        async refreshAccessToken() {
            if (refreshingPromise) {
                // Если обновление уже запущено, возвращаем текущее Promise
                return refreshingPromise;
            }

            if (!this.session?.data?.refresh_token) {
                console.warn('Сессия истекла, не найден refresh токен');
                this.session = null;
                window.localStorage.clear();
                window.sessionStorage.clear();
                window.location.reload();
            }

            refreshingPromise = (async () => {
                try {
                    const refreshToken = this?.session?.data?.refresh_token;
                    const user = this?.session?.data?.user;
                    const response = await fetch(authApiUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ProviderCode: 'ITV_TOKEN',
                            Token: refreshToken || '' // Поля должны соответствовать ожидаемым сервером
                        })
                    });

                    if (!response.ok) {
                        console.warn('Сессия истекла, требуется повторная авторизация');
                        this.session = null;

                        window.localStorage.clear();
                        window.sessionStorage.clear();
                        window.location.reload();
                    }

                    const data = await response.json();
                    this.session = {
                        data: {
                            access_token: data.access_token,
                            refresh_token: refreshToken,
                            user
                        }
                    };

                    // Сохраняем обновлённую сессию
                    window.localStorage.setItem(CLIENT_KEY_LOCAL_STORAGE, JSON.stringify(this));

                    console.debug('Сессия истекла, успешно обновили по токену');
                } catch (error) {
                    console.error('Error refreshing access token:', error);
                    throw error;
                } finally {
                    refreshingPromise = null; // Завершаем процесс обновления
                }
            })();

            return refreshingPromise;
        },

        async signIn({ username, password, code, provider_code }) {
            console.log('signIn provider: ', provider_code);
            if (!authApiUrl) {
                throw new Error('Auth URL not found');
            }

            let body: BodyInit;
            if (code && provider_code) {
                body = JSON.stringify({
                    ProviderCode: provider_code,
                    Token: code
                });
            } else {
                body = JSON.stringify({
                    Login: username, // Поля должны соответствовать ожидаемым сервером
                    Password: password
                });
            }
            // debugger;
            const response = await fetch(authApiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body
            });

            if (!response.ok) {
                const errorText = await response.text();
                // throw new Error(`Failed to authenticate with itvectura: ${errorText}`);
                return {
                    error: `Failed to authenticate with itvectura: ${errorText}`
                };
            }

            const data: KeycloakTokenResponseType = await response.json();

            const decodedToken: itvecturaTokenResponseType = decodeJWT(data.access_token);

            // Сохраняем данные авторизации Keycloak
            console.log('Аутентифицирован через itvectura');
            const user = {
                id: decodedToken.user_id
            };
            this.session = {
                data: {
                    access_token: data.access_token,
                    refresh_token: data.refresh_token,
                    user
                }
            };
            // this.accessToken = data.access_token;
            // window.localStorage.setItem('session', JSON.stringify(this.session));
            return {
                data: {
                    user,
                    session: {
                        access_token: data.access_token,
                        refresh_token: data.refresh_token,
                        user
                    }
                },
                error: undefined
            };
        }

        // storage: createMinioStorage(storageApiUrl, () => {
        //     debugger;
        //     return this.session?.data?.access_token;
        // })
    };
    client.storage = createMinioStorage(storageApiUrl, async () => {
        const session = await client.getSession();
        return session?.data?.access_token || '';
    });
    return client;
};
