import { BugOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { toJS } from 'mobx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';

import { useLogsModal } from 'App';
import { metaStore } from 'utils/store/MetaStore';
import { observer } from 'mobx-react-lite';
import { LogsResponse } from '../../../modules/services/backend-api/generated_api';

export const LogsField = observer<{
    requestId: string;
    logMessages?: LogsResponse;
    btnSize?: SizeType;
    hardDuration?: number;
    onClick?: (e?: React.SyntheticEvent) => void;
    onClose?: (e?: React.SyntheticEvent) => void;
}>(({ requestId, logMessages, btnSize, hardDuration, onClick, onClose }) => {
    const { t } = useTranslation();
    const [_, setLogModalState] = useLogsModal();

    const [logs, fetchLogs] = useAsyncFn(async () => {
        if (logMessages) {
            return logMessages;
        }

        const res = await metaStore.getLogs({ request_id: requestId });
        return toJS(res);
    }, [requestId, logMessages]);

    useEffect(() => {
        setLogModalState((prev) => ({
            ...prev,
            requestId,
            logs: logs?.value,
            onCancel: (e) => {
                setLogModalState((prev) => ({ ...prev, open: false }));
                if (onClose) onClose(e);
            },
            hardDuration
        }));
    }, [hardDuration, logs?.value, onClose, setLogModalState]);

    return (
        <>
            <Button
                size={btnSize}
                // icon={<EyeOutlined />}
                icon={<BugOutlined />}
                onClick={async (e) => {
                    e.stopPropagation();
                    // setOpenModal(true);
                    await fetchLogs();
                    setLogModalState((prev) => ({ ...prev, open: true }));

                    if (onClick) onClick(e);
                }}
            >
                {t('logs')}
            </Button>
        </>
    );
});
