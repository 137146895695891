import {
    CloudDownloadOutlined,
    CloudUploadOutlined,
    DeliveredProcedureOutlined,
    PrinterOutlined,
    TagsOutlined
} from '@ant-design/icons';
import { PlainObject } from '@gilbarbara/types';
import { Flex, Space, Typography } from 'antd';
import { toJS } from 'mobx';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { Action, MetaField } from 'modules/services/backend-api/generated_info';
import { ButtonWithTooltips } from 'ui';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { Response } from 'modules/services/backend-api/generated_api';
import { exportDataToExcel } from 'smart/utils/exportTable';
import { useUserData } from 'modules/client/useAuthUser';
import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import { useNotifications } from 'utils/hooks';
import { useReactToPrint } from 'react-to-print';
import { observer } from 'mobx-react-lite';
import { useAsync, useMap, useMedia } from 'react-use';
import { getMockActionDataByGetPartially } from '../../utils';
import { ExcelEditor, ExcelField } from '../../../../components';

import './SmartTablePageFooterToolbar.scss';
import { renderExcelToPrintCells } from '../../../../utils/renderExcelToPrintCells';
import { LazyIcon } from 'smart/ui';

interface SmartTablePageToolbarProps {
    meta: string;
    selectedRowKeys: string[];
    selectedRows: IObjectWithId[];
    hasSelected: boolean;
    totalDataCount: number;
    fieldsSource: MetaField[];
    onClickAction: (
        action: Action,
        hardIds?: string[],
        hardArgs?: PlainObject
    ) => Promise<Response | undefined>;
}

type PrintDataArray = [{ value: string }, { value: string | null | undefined }][];

export const SmartTablePageFooterToolbar = observer<SmartTablePageToolbarProps>(
    ({
        meta,
        selectedRowKeys,
        hasSelected,
        totalDataCount,
        selectedRows,
        fieldsSource,
        onClickAction
    }) => {
        const {
            t,
            i18n: { language }
        } = useTranslation();
        const isBigMobile = useMedia('(max-width: 480px)');
        // const { isPowerUser } = useUserData();

        // console.log(toJS(metaStore.meta.get(meta)?.info));
        const metaInfo = metaStore.meta.get(meta)?.info;

        const actions = useMemo(() => {
            return toJS(metaInfo?.Actions);
        }, [metaInfo]);

        // console.log(
        //     actions?.filter((action) => action.IsFooterButton),
        //     actions
        // );

        // const { value: hideMassUpdate } = useAsync(async () => {
        //     const hideMassUpdateParam = metaStore.meta.get('all')?.params?.HIDE_MASS_UPDATE;

        //     if (hideMassUpdateParam) return hideMassUpdateParam;

        //     return metaStore.getParam({
        //         param_name: 'HIDE_MASS_UPDATE',
        //         default_value: false
        //     });
        // }, []);

        // const { value: hideImportFromExcel } = useAsync(async () => {
        //     const hideImportFromExcelParam =
        //         metaStore.meta.get('all')?.params?.HIDE_IMPORT_FROM_EXCEL;

        //     if (hideImportFromExcelParam) return hideImportFromExcelParam;

        //     return metaStore.getParam({
        //         param_name: 'HIDE_IMPORT_FROM_EXCEL',
        //         default_value: false
        //     });
        // }, []);

        // const hideSetScope = !isPowerUser;

        // const massUpdateHandler = useAsync(async () => {
        //     const select = await metaStore.makeSelect({
        //         meta: 'InfoHandlers',
        //         filters: 'Code=eq.MassUpdate',
        //         page_size: 1,
        //         page: 1
        //     });

        //     const {
        //         objects: [{ Id }]
        //     } = select;

        //     const get = await metaStore.makeGet({
        //         meta: 'InfoHandlers',
        //         id: Id
        //     });

        //     return get.object;
        // }, []);

        // const setScopeHandler = useAsync(async () => {
        //     const select = await metaStore.makeSelect({
        //         meta: 'InfoHandlers',
        //         filters: 'Code=eq.SetScope',
        //         page_size: 1,
        //         page: 1
        //     });

        //     const {
        //         objects: [{ Id }]
        //     } = select;

        //     const get = await metaStore.makeGet({
        //         meta: 'InfoHandlers',
        //         id: Id
        //     });

        //     return get.object;
        // }, []);

        // console.log(massChildUpsertHandler);

        const uiExcelTableTitle = useMemo(() => {
            const title = renderExcelToPrintCells({
                value: selectedRows.at(-1),
                language,
                dataSource: selectedRows.at(-1) ?? { Id: '' }
            });

            return `${metaStore.meta.get(meta)?.info?.SingularName?.[language]}${
                title ? ` "${title}"` : ''
            }`;
        }, [language, meta, selectedRows]);

        const { notification } = useNotifications();

        const toPrintRef = useRef<HTMLDivElement | null>(null);
        const handlePrint = useReactToPrint({
            content: () => toPrintRef.current
        });

        const [printMap, printMapActions] = useMap<{ [k: string]: PrintDataArray }>();

        useEffect(() => {
            const lastSelectedItem = selectedRows.at(-1);
            if (lastSelectedItem) {
                const printData: PrintDataArray = [];
                const layoutGroups: { [k: string]: MetaField[] } = {};

                // Группируем поля по LayoutArea
                (toJS(metaStore.meta.get(meta)?.info?.Fields) ?? [])?.forEach((field) => {
                    const area = field.LayoutArea ?? '';
                    if (!layoutGroups[area]) {
                        layoutGroups[area] = [];
                    }
                    layoutGroups[area].push(field);
                });

                // Проходим по каждой LayoutArea
                for (const area of Object.keys(layoutGroups)) {
                    const isChildArea = area.includes('/') && area !== 'Page/Header';
                    const areaKey = isChildArea ? area.split('/')[1] : area;
                    // Добавляем [{ value: LayoutArea }]

                    const title =
                        areaKey === 'Main' || areaKey === 'Page/Header'
                            ? t('common_info')
                            : areaKey === 'Header'
                            ? `${t('common_info')} / ${t('header')}`
                            : areaKey === 'Footer'
                            ? `${t('common_info')} / ${t('footer')}`
                            : t(areaKey.toLowerCase());

                    printMapActions.set(
                        title,
                        layoutGroups[area].map((field) => {
                            const row = [
                                { value: field.Name?.[language] ?? field.FieldName },
                                {
                                    value: renderExcelToPrintCells({
                                        value:
                                            lastSelectedItem[field.FieldName] ??
                                            lastSelectedItem.VirtualFields?.[field.FieldName],
                                        language,
                                        field,
                                        dataSource: lastSelectedItem
                                    })
                                }
                            ];
                            printData.push(row);

                            return row;
                        })
                    );
                }
            }
        }, [metaStore.meta.get(meta)?.info?.Fields, selectedRows.at(-1)]);

        const handleExportToExcel = async () => {
            const error: null | Error = null;

            if (error) {
                notification.error({
                    key: 'handle_export_to_excel_error',
                    message: t('error'),
                    description: makeErrorReadable((error as Error).message)
                });
            }

            exportDataToExcel({
                metaName: meta,
                tableData: selectedRows,
                columns: fieldsSource
            });
        };

        return (
            <>
                <Flex justify="space-between" className="smart_table__footer">
                    <div>
                        {/* ### импорт/экспорт */}
                        {!isBigMobile && (
                            <Space.Compact className="smart_table__footer_left">
                                <ButtonWithTooltips
                                    type="default"
                                    id="massPrintBtn"
                                    disabled={!hasSelected}
                                    tooltipTitle={t('massPrintBtn')}
                                    tooltipPlacement="top"
                                    onClick={handlePrint}
                                    icon={<PrinterOutlined />}
                                />
                                {/* {!hideImportFromExcel && (
                                    <ButtonWithTooltips
                                        type="default"
                                        id="download_outlined"
                                        tooltipTitle={t('download_outlined')}
                                        tooltipPlacement="top"
                                        icon={<CloudUploadOutlined />}
                                        onClick={() => {
                                            const action = getMockActionDataByGetPartially({
                                                Handler_Code: 'ImportFromExcel',
                                                Meta_Code: meta,
                                                Name: { ru: 'Импорт из Excel' },
                                                Handler: {
                                                    ChildParams: [
                                                        {
                                                            ParentHandler_Code: '',
                                                            ParamName: 'document_type_code',
                                                            Name: {
                                                                en: 'Document type',
                                                                ru: 'Тип документа'
                                                            },
                                                            ValueType:
                                                                'code;ref:DctDocumentTypes.Code',
                                                            IsRequested: false,
                                                            IsMandatory: false,
                                                            ChildIndex: 0
                                                        },
                                                        {
                                                            ParentHandler_Code: '',
                                                            ParamName: 'file_path',
                                                            Name: { en: 'File', ru: 'Файл' },
                                                            ValueType: 'file;bucket:downloads',
                                                            IsRequested: true,
                                                            IsMandatory: false,
                                                            ChildIndex: 1
                                                        }
                                                    ],
                                                    Code: 'ImportFromExcel',
                                                    Name: { ru: 'Импорт из Excel' },
                                                    Meta_Code: meta
                                                }
                                            });

                                            onClickAction(action, [v4()]);
                                        }}
                                    />
                                )} */}

                                <ButtonWithTooltips
                                    type="default"
                                    id="download_up_outlined"
                                    tooltipTitle={t('download_up_outlined')}
                                    tooltipPlacement="top"
                                    icon={<CloudDownloadOutlined />}
                                    onClick={() => {
                                        if (actions) {
                                            const exportAction = actions.find(
                                                // (action) => action.Handler_Code === 'ExportToExcel'
                                                (action) =>
                                                    action.Handler?.MethodName === 'ExportToExcel'
                                            );

                                            if (exportAction) onClickAction(exportAction);
                                        }

                                        handleExportToExcel();
                                    }}
                                    disabled={selectedRowKeys.length === 0}
                                />

                                {/* {!hideMassUpdate && (
                                    <ButtonWithTooltips
                                        type="default"
                                        id="mass_update"
                                        tooltipTitle={t('mass_update')}
                                        tooltipPlacement="top"
                                        icon={<DeliveredProcedureOutlined />}
                                        loading={massUpdateHandler.loading}
                                        onClick={() => {
                                            const handler = massUpdateHandler.value;
                                            if (handler) {
                                                const action = getMockActionDataByGetPartially({
                                                    Handler_Code: handler.Code,
                                                    Meta_Code: meta,
                                                    Name: {
                                                        ru: 'Массовое редактирование',
                                                        en: 'Mass update'
                                                    },
                                                    Handler: handler
                                                });

                                                onClickAction(action);
                                            } else if (actions) {
                                                const massUpdateAction = actions.find(
                                                    (action) =>
                                                        action.Handler?.MethodName === 'MassUpdate'
                                                );

                                                if (massUpdateAction)
                                                    onClickAction(massUpdateAction);
                                            } else {
                                                const action = getMockActionDataByGetPartially({
                                                    Handler_Code: 'MassUpdate',
                                                    Meta_Code: meta,
                                                    Name: {
                                                        ru: 'Массовое редактирование',
                                                        en: 'Mass update'
                                                    },
                                                    Handler: {
                                                        ChildParams: [],
                                                        Code: 'MassUpdate',
                                                        Name: {
                                                            ru: 'Массовое редактирование',
                                                            en: 'Mass update'
                                                        },
                                                        Meta_Code: meta
                                                    }
                                                });

                                                onClickAction(action);
                                            }
                                        }}
                                        disabled={selectedRowKeys.length === 0}
                                    />
                                )}
                                {!hideSetScope && (
                                    <ButtonWithTooltips
                                        type="default"
                                        id="set_scope"
                                        tooltipTitle={t('set_scope')}
                                        tooltipPlacement="top"
                                        icon={<TagsOutlined />}
                                        loading={setScopeHandler.loading}
                                        onClick={() => {
                                            const handler = setScopeHandler.value;
                                            if (handler) {
                                                const action = getMockActionDataByGetPartially({
                                                    Handler_Code: handler.Code,
                                                    Meta_Code: meta,
                                                    Name: {
                                                        ru: 'Установить подсистему',
                                                        en: 'Set scopes'
                                                    },
                                                    Handler: handler
                                                });

                                                onClickAction(action);
                                            } else if (actions) {
                                                const massUpdateAction = actions.find(
                                                    (action) =>
                                                        action.Handler?.MethodName === 'SetScope'
                                                );

                                                if (massUpdateAction)
                                                    onClickAction(massUpdateAction);
                                            } else {
                                                const action = getMockActionDataByGetPartially({
                                                    Handler_Code: 'SetScope',
                                                    Meta_Code: meta,
                                                    Name: {
                                                        ru: 'Установить подсистему',
                                                        en: 'Set scopes'
                                                    },
                                                    Handler: {
                                                        ChildParams: [],
                                                        Code: 'SetScope',
                                                        Name: {
                                                            ru: 'Установить подсистему',
                                                            en: 'Set scopes'
                                                        },
                                                        Meta_Code: meta
                                                    }
                                                });

                                                onClickAction(action);
                                            }
                                        }}
                                        disabled={selectedRowKeys.length === 0}
                                    />
                                )} */}

                                {actions
                                    ?.filter((action) => action.IsFooterButton)
                                    .reverse()
                                    .map((action) => (
                                        <ButtonWithTooltips
                                            key={action.Id}
                                            type="default"
                                            tooltipTitle={action.Name?.[language]}
                                            tooltipPlacement="top"
                                            icon={<LazyIcon icon={action.Icon} />}
                                            onClick={() => onClickAction(action)}
                                            disabled={selectedRowKeys.length === 0}
                                            children={
                                                !action.Icon ? action.Name?.[language] : undefined
                                            }
                                        />
                                    ))}
                            </Space.Compact>
                        )}
                    </div>

                    <Flex className="smart_table__footer_center">
                        {/* TODO: center part of toolbar */}
                        <></>
                    </Flex>

                    <Flex className="smart_table__footer_right">
                        {/* TODO: right part of toolbar */}
                        <Typography.Text strong>
                            {selectedRowKeys.length
                                ? `${t('selected')} ${selectedRowKeys.length} ${t('out_of')} `
                                : null}
                            {totalDataCount} {t('rows')}
                        </Typography.Text>
                    </Flex>
                </Flex>

                <div style={{ display: 'none' }}>
                    <div style={{ display: 'block', width: '1000px' }} ref={toPrintRef}>
                        <Typography.Title level={4} style={{ padding: 0, textAlign: 'center' }}>
                            {uiExcelTableTitle}
                        </Typography.Title>
                        {Object.entries(printMap).map(([key, value]) => {
                            return (
                                <ExcelField
                                    className={'to_print_excel'}
                                    file={value}
                                    uiTableTitle={key}
                                    hideIndicators={true}
                                />
                                // <ExcelEditor
                                //     className={'to_print_excel'}
                                //     // file={value}
                                //     // uiTableTitle={key}
                                //     data={value}
                                //     disableHeader={true}
                                // />
                            );
                        })}
                    </div>
                </div>

                {/* <SelectPrintingFormModal */}
                {/*    open={showPrinttingFormsModal} */}
                {/*    setOpen={setPrinttingFormsModal} */}
                {/*    documentIds={selectedRowKeys.map((id) => Number(id))} */}
                {/*    tableName={toSnakeCase(meta) as TableName} */}
                {/*    viewName={`v${toSnakeCase(meta)}` as ViewName} */}
                {/* /> */}
            </>
        );
    }
);
