export const reduceArrayToMap = (
    array: any[],
    elementKeyForMapKey: string,
    excludeFlagFieldName?: string
) => {
    return array.reduce((map, item) => {
        if (excludeFlagFieldName && item[excludeFlagFieldName]) return map;

        const mapKey = item?.[elementKeyForMapKey] || elementKeyForMapKey;

        if (!map.has(mapKey)) {
            map.set(mapKey, []);
        }

        map.get(mapKey)?.push(item);

        return map;
    }, new Map<string, any[]>());
};
