import { useCallback, useEffect, useState } from 'react';
import { useEffectOnce, useMap, useUpdateEffect } from 'react-use';
import { IObjectWithId } from 'utils/store/MetaStore';
import { isDefined } from 'is-lite/exports';

interface DataType {
    Id: string;
    [key: string]: any;
}

interface UseDataProps {
    id: string;
    mode: 'edit' | 'view';
    data?: DataType;
    markAllFieldsAlreadyChanged?: boolean;
    parentFieldName?: string;
}

export const useData = ({
    data,
    id,
    mode,
    parentFieldName,
    markAllFieldsAlreadyChanged = false
}: UseDataProps) => {
    const initialChangesValue = {
        Id: id,
        CreatedAt: data?.CreatedAt,
        ...(parentFieldName ? { [parentFieldName]: data?.[parentFieldName] } : undefined)
    };

    const [dataSource, setDataSource] = useState<IObjectWithId>(initialChangesValue);
    const [changes, changesMethods] = useMap<IObjectWithId>(initialChangesValue);
    const CreatedAt = dataSource.CreatedAt;

    useUpdateEffect(() => {
        changesMethods.reset();
    }, [mode]);

    useEffect(() => {
        if (!changesMethods.get('CreatedAt')) {
            changesMethods.set('CreatedAt', CreatedAt);
        }
    }, [CreatedAt]);

    useEffectOnce(() => {
        if (data) setDataSource((prevData) => ({ ...prevData, ...data }));

        if (markAllFieldsAlreadyChanged && data) {
            changesMethods.setAll({ ...initialChangesValue, ...data });
            // for (let i = 0; i < Object.keys(data).length; i++) {
            //     const key = Object.keys(data)[i];
            //     const value = data[key];
            //     changesMethods.set(key, value);
            // }
        }
    });

    // useEffect(() => {
    //     if (data) setDataSource(data);
    // }, [data]);

    // ### Функция для изменения ДАННЫХ ПО КЛЮЧУ ('Id' или 'TO_Status.Status')
    const handleDataSourceChange = useCallback((key: string, value: any) => {
        // const keys = key.split('.');
        // if (keys.length > 1) {
        //     setDataSource((prevData: any) => ({
        //         ...prevData,
        //         [keys[0]]: { ...prevData[keys[0]], [keys[1]]: value }
        //     }));
        //     changesMethods.set(keys[0], { ...changes[keys[0]], [keys[1]]: value });
        // } else {
        setDataSource((prevData: any) => ({ ...prevData, [key]: value }));
        changesMethods.set(key, isDefined(value) ? value : null);
        // }
    }, []);

    // console.log('Changes!', changes);

    return { dataSource, setData: setDataSource, changeData: handleDataSourceChange, changes };
};
