import { PlainObject } from '@gilbarbara/types';

export const getPageTitle = (
    {
        title,
        extra
    }: {
        title: string | null | undefined | PlainObject<any>;
        extra?: string | null | undefined | PlainObject<any>;
    },
    language: string,
    baseLanguage: string,
    t: (a?: string | null) => string
) => {
    const lang = language === 'tech' ? baseLanguage : language;

    return `${title && typeof title === 'object' ? title[lang] || title[baseLanguage] : t(title)} ${
        extra
            ? `(${
                  extra && typeof extra === 'object' ? extra[lang] || extra[baseLanguage] : t(extra)
              })`
            : ''
    }`;
};
