import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import { Badge, Button, DatePicker, Flex, Segmented, Tooltip, Typography } from 'antd';
import { Popup } from 'antd-mobile';
import dayjs from 'dayjs';
import { capitalize, range } from 'lodash';
import { useAsync, useAsyncRetry, useBoolean, useMap, useMedia, useUpdateEffect } from 'react-use';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'is-lite/exports';

import { UserSpecificFormat } from 'utils/helpers/dates';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { ReactComponent as TransposeIcon } from 'assets/icons/transpose.svg';

import { PlainObject } from '@gilbarbara/types';
import { Legend } from './components';
import { DATE_FORMAT, DAY_RANGE_ENDPOINTS } from './contants';
import {
    RegBookedFleetInterval,
    RegDriverSlot
} from '../../../modules/services/backend-api/generated_models';
// import { BookedSlotsDataType } from './types';
import './SmartTimelineTablePage.scss';
import { SmartMultiSelectField, SmartTimeline } from '../../components';
import { LazyIcon } from '../../ui';

const { Text } = Typography;

interface SmartTimelinePageProps {
    fromMeta: string;
    filters?: Filter[];
}

type ViewMetaType = 'drivers' | 'VH' | 'resources';

const viewSlotsMetaMap = {
    drivers: 'RegDriverSlots',
    VH: 'RegVehicleSlots',
    resources: 'RegLocationResourceSlots'
};

const viewMetaPropertyMap = {
    drivers: 'Driver',
    VH: 'Vehicle',
    resources: 'LocationResource'
};

const viewMetaMap = {
    drivers: 'CatPartners',
    VH: 'CatVehicles',
    resources: 'CatLocationResources'
};

export const ModifiedSmartTimelineTablePage = memo<SmartTimelinePageProps>(
    ({ fromMeta, filters }) => {
        // const isTripDisabled = fromMeta === 'DocTrips';
        const isResourcesDisabled = fromMeta === 'CatLocationResources';
        const isLocationsDisabled = fromMeta === 'CatLocations';

        const initialLocations =
            // stateData?.SourceLocation && fromMeta === 'DocTrips'
            //     ? [stateData.SourceLocation]
            //     : stateData && fromMeta === 'CatLocations'
            //     ? [stateData] :
            [];

        const {
            t,
            i18n: { language }
        } = useTranslation();
        const isBigMobile = useMedia('(max-width: 480px)');

        const [datesRange, setDatesRange] = useState(
            capitalize((isBigMobile ? t('day') : t('4_days')) as string)
        );
        const [viewMeta, setViewMeta] = useState<ViewMetaType>('drivers');
        const [displayMode, setDisplayMode] = useState<'horizontal' | 'vertical'>(
            isBigMobile ? 'vertical' : 'horizontal'
        );
        const [date, setDate] = useState(dayjs());
        // const [resources, setResources] = useState<IObjectWithId[]>([]);
        // const [locations, setLocations] = useState<IObjectWithId[]>([]);
        // const [trip, setTrip] = useState<IObjectWithId | null>(null);
        // const [selectedRows, setSelectedRows] = useState<IObjectWithId[]>([]);
        const [legend, legendMake] = useMap<PlainObject>({});
        const [isOpenMobilePopup, setIsOpenMobilePopup] = useBoolean(false);
        const [locations, setLocations] = useState<IObjectWithId[]>(initialLocations);

        const locationsIdsIn = locations.map(({ Id }) => Id).join(',');

        const daysCountFromDateRangesEndpoint = useMemo(() => {
            switch (datesRange) {
                case capitalize(t('day') as string):
                    return 1;
                case capitalize(t('4_days') as string):
                    return 4;
                case capitalize(t('week') as string):
                    return 7;
                default:
                    return 1;
            }
        }, [datesRange, t]);

        const dateRangeToFetch = useMemo(
            () =>
                range(0, daysCountFromDateRangesEndpoint).map((index) => {
                    return date.add(index, 'days').format(DATE_FORMAT);
                }),
            [date, daysCountFromDateRangesEndpoint]
        );
        const dateIn = dateRangeToFetch.join(',');
        // const resourcesIdsIn = resources.map(({ Id }) => Id).join(',');
        // const minDate = useMemo(
        //     () => date.set('hours', 0).set('minutes', 0).set('seconds', 0).format(DATE_TIME_FORMAT),
        //     [date]
        // );
        // const maxDate = useMemo(
        //     () =>
        //         date
        //             .add(daysCountFromDateRangesEndpoint - 1, 'days')
        //             .set('hours', 23)
        //             .set('minutes', 59)
        //             .set('seconds', 59)
        //             .format(DATE_TIME_FORMAT),
        //     [date, daysCountFromDateRangesEndpoint]
        // );

        // // Запрашиваем рейсы для отбора
        // useAsync(async () => {
        //     if (filters?.[0].value[0] && fromMeta === 'DocTrips') {
        //         const response = await metaStore.makeSelect({
        //             meta: 'DocTrips',
        //             filters: `Id=eq.${filters?.[0].value[0]}`
        //         });
        //
        //         if (response) setTrip(response.objects[0]);
        //     }
        // }, [filters, fromMeta]);
        //

        // Запрашиваем МПЛ для отбора
        const { loading: locationsLoading } = useAsync(async () => {
            if (filters?.length && fromMeta === 'CatLocations' && !locations.length) {
                // console.log('LOCATIONS RESPONSE');
                const response = await metaStore.makeSelect({
                    meta: 'CatLocations',
                    // filters: `Id=in.[${location.state.filters[0].value.join(',')}]`
                    filters: `Id=in.[${filters?.[0].value.join(',')}]`
                });

                if (response) setLocations(response.objects);
            }
        }, [filters, fromMeta]);
        //
        // // Запрашиваем Ресурсы для отбора
        // useAsync(async () => {
        //     if (filters?.length && fromMeta === 'CatLocationResources') {
        //         const response = await metaStore.makeSelect({
        //             meta: 'CatLocationResources',
        //             filters: `Id=in.[${filters?.[0].value.join(',')}]`
        //         });
        //
        //         if (response) setResources(response.objects);
        //     }
        // }, [filters, fromMeta]);

        // Запрашиваем Свободные слоты
        const freeSlots = useAsyncRetry(async () => {
            let filters = `SlotDate=in.[${dateIn}]`;

            if (!isEmpty(locationsIdsIn)) filters += `&DepotLocation=in.[${locationsIdsIn}]`;

            const res = await metaStore.makeSelect({ meta: viewSlotsMetaMap[viewMeta], filters });

            // console.log(viewSlotsMetaMap[viewMeta], res);
            return res?.objects ?? ([] as RegDriverSlot[]);
        }, [dateIn, viewMeta, locationsIdsIn]);

        // Запрашиваем Занятые слоты
        const bookedSlots = useAsyncRetry(async () => {
            let filters = `SlotDate=in.[${dateIn}]`;

            if (!isEmpty(locationsIdsIn)) filters += `&DepotLocation=in.[${locationsIdsIn}]`;

            const res = await metaStore.makeSelect({ meta: 'RegBookedFleetIntervalsV', filters });

            // console.log('RegBookedFleetIntervalsV', res);
            return res?.objects ?? ([] as RegBookedFleetInterval[]);
        }, [dateIn, viewMeta, locationsIdsIn]);

        useUpdateEffect(() => {
            if (bookedSlots.value) {
                legendMake.setAll(
                    bookedSlots.value.reduce((acc, bs) => {
                        const res = acc;
                        if (bs.Status?.Color) res[bs.Status?.Color] = bs.Status?.Name?.[language];
                        return res;
                    }, {} as PlainObject)
                );
            }
        }, [bookedSlots.value]);

        const fetchBookedSlots = useCallback(
            () => Promise.all([freeSlots.retry(), bookedSlots.retry()]),
            [bookedSlots, freeSlots]
        );

        const loading = freeSlots.loading || bookedSlots.loading;

        const reload = async () => {
            await fetchBookedSlots();
        };

        return (
            <div id="smart_timeline_table_page_container">
                <Flex align="flex-start" gap={5} wrap="wrap" style={{ marginBottom: 5 }}>
                    <Flex
                        align="center"
                        justify="flex-start"
                        style={{ width: isBigMobile ? '49%' : '180px' }}
                    >
                        <Segmented
                            size={isBigMobile ? 'large' : 'middle'}
                            style={{ width: '100%' }}
                            block
                            id="timeline_table_view_meta"
                            options={[
                                { value: 'drivers', label: t('drivers') },
                                { value: 'VH', label: t('VH') }
                            ]}
                            value={viewMeta}
                            onChange={setViewMeta}
                        />
                    </Flex>
                    <Flex
                        align="center"
                        justify="flex-start"
                        style={{ width: isBigMobile ? '49%' : '250px' }}
                    >
                        <Segmented
                            size={isBigMobile ? 'large' : 'middle'}
                            style={{ width: '100%' }}
                            block
                            id="timeline_table_date_interval"
                            options={DAY_RANGE_ENDPOINTS.map((key) => capitalize(t(key) as string))}
                            value={datesRange}
                            onChange={setDatesRange}
                        />
                    </Flex>
                    <Flex gap={5}>
                        <Flex align="center" justify="center" gap={5}>
                            {!isBigMobile && <Text>{t('date')}:</Text>}
                            <DatePicker
                                size={isBigMobile ? 'large' : 'middle'}
                                style={{ width: '150px' }}
                                id="timeline_table_date"
                                value={date}
                                onChange={setDate}
                                format={UserSpecificFormat.getDateFormat()}
                                allowClear={false}
                                getPopupContainer={() =>
                                    document.getElementById(
                                        'smart_timeline_table_page_container'
                                    ) as HTMLElement
                                }
                            />
                        </Flex>
                        <Button
                            size={isBigMobile ? 'large' : 'middle'}
                            icon={<ReloadOutlined />}
                            onClick={reload}
                        />
                        <Legend size={isBigMobile ? 'large' : 'middle'} legend={legend} />
                        {!isBigMobile && (
                            <Tooltip
                                title={t('transpose')}
                                placement={'bottom'}
                                destroyTooltipOnHide={true}
                            >
                                <Button
                                    icon={<LazyIcon component={TransposeIcon} />}
                                    onClick={() => {
                                        setDisplayMode((prev) =>
                                            prev === 'horizontal' ? 'vertical' : 'horizontal'
                                        );
                                    }}
                                />
                            </Tooltip>
                        )}
                    </Flex>

                    {!isBigMobile ? (
                        <Flex align="center" gap={5}>
                            <Text>{t('locations')}:</Text>
                            <div style={{ width: '250px' }}>
                                <SmartMultiSelectField
                                    metaName="CatLocations"
                                    onChange={setLocations}
                                    loading={locationsLoading}
                                    value={locations}
                                    isDisabled={isLocationsDisabled || isResourcesDisabled}
                                />
                            </div>
                        </Flex>
                    ) : (
                        <>
                            <Badge size={'large'} dot={!!locations?.length}>
                                <Button
                                    icon={<FilterOutlined />}
                                    children={t('filters')}
                                    onClick={() => setIsOpenMobilePopup(true)}
                                    size={'large'}
                                />
                            </Badge>
                            <Popup
                                bodyStyle={{
                                    display: 'flex',
                                    gap: 20,
                                    flexDirection: 'column',
                                    padding: 10
                                }}
                                onClose={() => setIsOpenMobilePopup(false)}
                                onMaskClick={() => setIsOpenMobilePopup(false)}
                                visible={isOpenMobilePopup}
                            >
                                <SmartMultiSelectField
                                    placeholder={t('locations') as string}
                                    style={{ width: 'calc(100% - 20px)' }}
                                    size={'large'}
                                    metaName="CatLocations"
                                    onChange={setLocations}
                                    value={locations}
                                    isDisabled={isLocationsDisabled || isResourcesDisabled}
                                />
                            </Popup>
                        </>
                    )}

                    {/* <Flex> */}
                    {/*    <Text>{t('trip')}:</Text> */}
                    {/*    <div style={{ width: '250px' }}> */}
                    {/*        <SmartSelectField */}
                    {/*            meta="DocTrips" */}
                    {/*            onChange={setTrip} */}
                    {/*            value={trip} */}
                    {/*            loading={loading} */}
                    {/*            disabled={isTripDisabled} */}
                    {/*            filters={`StartDateAt=gt.${minDate}&StartDateAt=lt.${maxDate}`} // TODO: дописать. ждем фильтр по OR на бэкенде */}
                    {/*        /> */}
                    {/*    </div> */}
                    {/* </Flex> */}

                    {/* <Flex align="center"> */}
                    {/*    <Text>{t('locations')}:</Text> */}
                    {/*    <div style={{ width: '250px' }}> */}
                    {/*        <SmartMultiSelectField */}
                    {/*            metaName="CatLocations" */}
                    {/*            onChange={setLocations} */}
                    {/*            value={locations} */}
                    {/*            isDisabled={isLocationsDisabled || isResourcesDisabled} */}
                    {/*        /> */}
                    {/*    </div> */}
                    {/* </Flex> */}
                    {/* <Flex align="center"> */}
                    {/*    <Text>{t('resources')}:</Text> */}
                    {/*    <div style={{ width: '250px' }}> */}
                    {/*        <SmartMultiSelectField */}
                    {/*            metaName="CatLocationResources" */}
                    {/*            onChange={setResources} */}
                    {/*            value={resources} */}
                    {/*            isDisabled={isResourcesDisabled} */}
                    {/*            filters={ */}
                    {/*                locations.length === 0 */}
                    {/*                    ? '' */}
                    {/*                    : `Location=in.[${locations.map(({ Id }) => Id).join(',')}]` */}
                    {/*            } */}
                    {/*        /> */}
                    {/*    </div> */}
                    {/* </Flex> */}
                </Flex>
                {/* <Tabs defaultActiveKey="1" type="line" items={[tab1, tab2]} /> */}
                <SmartTimeline
                    daysCount={daysCountFromDateRangesEndpoint}
                    selectedDate={date}
                    selectedResources={[]}
                    selectedLocations={locations}
                    // selectedTrips={trip ? [trip] : selectedRows}
                    selectedTrips={[]}
                    freeSlots={freeSlots.value ?? []}
                    bookedSlots={bookedSlots.value ?? []}
                    fetchBookedSlots={fetchBookedSlots}
                    loading={loading}
                    firstColumnFieldName={viewMetaPropertyMap[viewMeta]}
                    displayMode={displayMode}
                    meta={viewMetaMap[viewMeta]}
                />
            </div>
        );
    }
);
