import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Flex, List, Select, Skeleton, Typography } from 'antd';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { IObjectWithId } from 'utils/store/MetaStore';
import { FilterField, FilterFieldValue, LocalFilterValueType } from '../../types';
import { getAvailableFilterOperators, getDefaultFilterOperator, isRangeType } from '../../utils';
import { renderValue } from './utils';
import { mapShortFilterOperation } from '../../utils/convert_complex_filter_to_supabase_filter/helpers/mapMUITableFilterOperationAndSupabaseFilterOperation';

interface IFilterItem {
    item: FilterField;
    isNotRemovable?: boolean;
    items: FilterField[];
    changeItem: (item: FilterField) => void;
    removeAppliedField: (fieldName: string) => void;
}

export const FilterItem: FC<IFilterItem> = ({
    item,
    items,
    changeItem,
    removeAppliedField,
    isNotRemovable
}) => {
    const {
        t,
        i18n: { language }
    } = useTranslation();
    // const { options } = parseValueType(item.field.ValueType || '', language);

    // console.log(item);

    const addFilterValue = useCallback(() => {
        const copy = { ...item };
        copy.values = [
            ...copy.values,
            {
                operator: getDefaultFilterOperator(item)
            }
        ];

        changeItem(copy);
    }, [item, changeItem]);

    const deleteFilterValue = useCallback(
        (index: number) => {
            const copy = { ...item, values: [...item.values] };
            copy.values.splice(index, 1);
            changeItem(copy);
        },
        [item, changeItem]
    );

    const changeFilterValue = useCallback(
        (filterValue: LocalFilterValueType, changedFilterValue: FilterFieldValue) => {
            const copy = { ...item, values: [...item.values] };
            copy.values[filterValue.index] = {
                ...copy.values[filterValue.index],
                ...changedFilterValue
            };

            changeItem(copy);
        },
        [item, changeItem]
    );

    const makelistData = useCallback(
        (item: FilterField) =>
            item.values.map((value, index) => {
                return {
                    index,
                    lastValue: index === item.values.length - 1,
                    operator: value.operator,
                    value: value.value,
                    field: item.field
                };
            }),
        []
    );

    const listData: LocalFilterValueType[] = makelistData(item);

    // console.log(listData);

    const listDataListMap = items.reduce((acc, item) => {
        const res = acc;

        res[item.field.FieldName] = makelistData(item).at(-1)?.value; // последний элемент из массива фильтров по поплю

        return res;
    }, {} as IObjectWithId);

    // console.log(listDataListMap);

    if (listData.length < 0) return null;

    return (
        <div className="div-item">
            <List
                header={
                    <Flex align="center" justify="space-between">
                        <Typography.Text>
                            {item.field.DisplayTitle ||
                                item.field.Name[language] ||
                                t(item.field.ColumnName)}
                            <PlusOutlined
                                onClick={addFilterValue}
                                style={{
                                    cursor: 'pointer',
                                    color: 'green',
                                    marginLeft: 10
                                }}
                            />
                        </Typography.Text>
                        {!isNotRemovable && (
                            <CloseOutlined
                                onClick={() => {
                                    removeAppliedField(item.field.ColumnName);
                                }}
                                style={{
                                    cursor: 'pointer',
                                    color: 'red',
                                    padding: '0 8px'
                                }}
                            />
                        )}
                    </Flex>
                }
                dataSource={listData || []}
                itemLayout="horizontal"
                renderItem={(valueItem) => (
                    <List.Item
                        style={{ padding: '8px 0' }}
                        actions={
                            valueItem.index > 0
                                ? [
                                      <CloseOutlined
                                          onClick={() => {
                                              deleteFilterValue(valueItem.index);
                                          }}
                                          style={{
                                              cursor: 'pointer'
                                          }}
                                      />
                                  ]
                                : undefined
                        }
                    >
                        <Skeleton active loading={false}>
                            <List.Item.Meta />

                            <div
                                className="item_meta"
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: 'center'
                                }}
                            >
                                <Select
                                    getPopupContainer={() =>
                                        document.getElementById('table_filter_menu') as HTMLElement
                                    }
                                    className="select_item_meta"
                                    style={{ width: '15rem' }}
                                    value={mapShortFilterOperation(valueItem.operator)}
                                    onChange={(operator) => {
                                        changeFilterValue(valueItem, {
                                            operator,
                                            value: undefined
                                        });
                                    }}
                                    options={getAvailableFilterOperators(item).map(
                                        (filterFNSKey) => {
                                            const isRange = isRangeType(item.field.ValueType);

                                            let label = filterFNSKey;

                                            if (
                                                isRange &&
                                                (filterFNSKey === 'lessThan' ||
                                                    filterFNSKey === 'lessThanOrEqualTo')
                                            ) {
                                                label = `${label}Start`;
                                            }

                                            if (
                                                isRange &&
                                                (filterFNSKey === 'greaterThan' ||
                                                    filterFNSKey === 'greaterThanOrEqualTo')
                                            ) {
                                                label = `${label}End`;
                                            }

                                            return {
                                                label: t(label),
                                                value: filterFNSKey
                                            };
                                        }
                                    )}
                                />

                                <div
                                    className="item_meta_value"
                                    style={{ width: '100%', marginLeft: '1rem' }}
                                >
                                    {renderValue({
                                        value: valueItem,
                                        dataSource: listDataListMap,
                                        onChange: changeFilterValue,
                                        // data: item,
                                        // options,
                                        language
                                        // baseLanguage
                                    })}
                                </div>
                            </div>
                        </Skeleton>
                    </List.Item>
                )}
            ></List>
        </div>
    );
};
